import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Signal,
  computed,
  inject,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { VpnProfilesListComponent } from '@tmc/mco-user-management-ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  BreadcrumbComponent,
  ProgressSpinnerComponent,
  TableSearchBoxComponent,
} from '@tmc/fleet-core-ui';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DialogService } from '@tmc/core-ui';
import { VpnProfileService, VpnProfile } from '@tmc/mco-user-management-api';
import { Subject, filter, from, switchMap } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { LoadingState, RxjsUtils } from '@tmc/core-utils';
import { AdminAppUserDetailsService } from '@tmc/fleet-core-feature';

@Component({
  selector: 'tmc-fleet-user-management-vpn-profiles',
  standalone: true,
  imports: [
    CommonModule,
    VpnProfilesListComponent,
    TableSearchBoxComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonToggleModule,
    MatTabsModule,
    BreadcrumbComponent,
    MatTooltipModule,
    ProgressSpinnerComponent,
    TranslateModule,
  ],
  providers: [VpnProfileService, DialogService],
  templateUrl: './fleet-user-management-vpn-profiles.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetUserManagementVpnProfilesComponent implements OnInit {
  private readonly profileService = inject(VpnProfileService);
  private readonly translate = inject(TranslateService);
  private readonly router = inject(Router);
  private readonly dialogService = inject(DialogService);
  private readonly adminAppUserDetail = inject(AdminAppUserDetailsService);
  reload = new Subject<void>();
  allProfiles: Signal<LoadingState<VpnProfile[]> | undefined> = toSignal(
    this.reload.pipe(RxjsUtils.switchMapWithLoading(() => from(this.profileService.getAll()))),
  );
  profiles = computed(() => {
    if (this.allProfiles()?.loading === false && this.allProfiles()?.data) {
      return this.allProfiles()?.data?.toSorted((a, b) => a.name.localeCompare(b.name));
    }
    return undefined;
  });
  filteredProfiles = computed(() =>
    this.profiles()?.filter((profile: VpnProfile) =>
      profile.name.toLowerCase().includes(this.listFilter().toLowerCase()),
    ),
  );
  maxProfilesReached = computed(() => this.profiles()?.findLastIndex((profile) => profile) === 9);
  listFilter = signal<string>('');
  view = this.adminAppUserDetail.view();

  ngOnInit(): void {
    this.reload.next();
  }

  addNewProfile() {
    this.router.navigate(['/fleet-user-management/user-management/vpn-profiles/new']).catch(() => {
      this.dialogService.openSnackBar({ message: 'COMMON.ERROR' });
    });
  }

  deleteProfile(profile: VpnProfile) {
    this.translate
      .get('PATH.USER_MGMT.ARE_YOU_SURE_TO_REMOVE_PROFILE', { element: profile.name })
      .pipe(
        switchMap((message: string) =>
          this.dialogService
            .openConfirmationDialog({
              title: profile.name,
              confirmationQuestion: message,
            })
            .afterClosed(),
        ),
        filter((accept) => accept ?? false),
        switchMap(() => this.profileService.deleteVpnProfile(profile.id)),
      )
      .subscribe({
        next: () =>
          this.dialogService.openSnackBar({
            message: 'USER_MANAGEMENT.VPN_PROFILES.VPN_PROFILE_FORM.PROFILE_REMOVED',
          }),
        error: () => this.dialogService.openSnackBar({ message: 'ERROR.ERROR_OCCURRED' }),
      });
  }

  tooltipMessage() {
    return this.maxProfilesReached()
      ? 'USER_MANAGEMENT.VPN_PROFILES.MAX_REACHED'
      : 'USER_MANAGEMENT.VPN_PROFILES.ADD_ONE';
  }
}
