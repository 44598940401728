import {
  AfterViewInit,
  Component,
  viewChild,
  ChangeDetectionStrategy,
  computed,
  model,
  signal,
  OnInit,
} from '@angular/core';
import { FieldType, FormlyMaterialModule } from '@ngx-formly/material';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInput, MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { Observable, from, startWith, switchMap } from 'rxjs';
import { SelectOption } from '../select-option.model';

// Autocomple component can slow down the app if there are duplicated options available
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-formly-autocomplete-type',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
    MatInputModule,
    MatAutocompleteModule,
  ],
  templateUrl: './formly-autocomplete-type.component.html',
})
export class FormlyAutocompleteTypeComponent
  extends FieldType<FieldTypeConfig>
  implements AfterViewInit, OnInit
{
  readonly currentValue = model<string>('');
  readonly selectable = true;
  readonly removable = true;
  readonly labels = signal<SelectOption[]>([]); // To store extracted labels
  readonly filteredOptions = computed(() => {
    const tippedValue = typeof this.currentValue() === 'string' ? this.currentValue() : '';
    if (this.labels() !== undefined) {
      return this.labels()?.filter((label) =>
        label?.label.toLowerCase().includes(tippedValue?.toLowerCase()),
      );
    }
    return [];
  });
  formFieldControl = viewChild.required(MatInput);
  autocomplete = viewChild.required(MatAutocompleteTrigger);
  filter: Observable<any> = from('');

  ngOnInit() {
    this.filter = this.formControl.valueChanges.pipe(
      startWith(''),
      switchMap((term: string) => this.props['filter'](term)),
    );
    this.currentValue.set(this.formControl.value);
  }

  ngAfterViewInit() {
    if (this.props?.options instanceof Observable)
      (this.props?.options as Observable<SelectOption[]>)?.subscribe((options) => {
        this.labels.set(options);
      });

    // temporary fix for https://github.com/angular/material2/issues/6728
    (this.autocomplete as any)._formField = this.formField;
  }

  displayFn(option: SelectOption | string): string {
    if (option !== undefined && typeof option === 'object') {
      return option?.label ?? '';
    }
    return option ?? '';
  }
}
