import { Component, ChangeDetectionStrategy, inject, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'tmc-header-language',
  templateUrl: './header-language.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslateModule, MatButtonModule, MatMenuModule, MatIconModule],
})
export class HeaderLanguageComponent {
  readonly supportedLanguages = ['de', 'en', 'fr', 'it', 'ja', 'nl', 'pl'];
  readonly locale = inject(LOCALE_ID);

  updateLanguage(language: string) {
    localStorage.setItem('LANG_CODE', language);
    window.location.reload();
  }
}
