import {
  Component,
  QueryList,
  ViewChildren,
  inject,
  ChangeDetectionStrategy,
  computed,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogModule,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { TextSelectComponent, TextSelectOption } from '@tmc/core-ui';
import { map } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { ProgressSpinnerComponent } from '@tmc/fleet-core-ui';
import { Company, Machine, Module, Role, SimpleCompany } from '@tmc/fleet-core-api';
import {
  CreateFleetPermissionDialogConfig,
  FleetPermission,
  PERMISSIONS_FORM_SERVICE,
  ScopeType,
  Application,
  FleetPermissionInput,
  ScopeEntity,
} from '@tmc/mco-user-management-api';
import { providePermissionsFormOptions } from './fleet-management-permissions-form.provider';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-create-permission',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatCardModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogModule,
    MatDialogTitle,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    TextSelectComponent,
    ProgressSpinnerComponent,
    TranslateModule,
  ],
  providers: [providePermissionsFormOptions()],
  templateUrl: './create-permission.component.html',
})
export class CreatePermissionComponent {
  dialogRef = inject(MatDialogRef<CreatePermissionComponent>);
  // get data from parent component
  public data: CreateFleetPermissionDialogConfig = inject(
    MAT_DIALOG_DATA,
  ) as CreateFleetPermissionDialogConfig;
  isRemoteFeaturesGroup = this.data.isRemoteFeaturesGroup;
  currentPermissions: FleetPermission[] = this.data.currentPermissions;
  form!: FormGroup;
  selectOne: TextSelectOption<string> = { value: '-1', label: 'Select One' };
  ALL_MACHINES_UNDER_CURRENT_SCOPE_KEY =
    'USER_MANAGEMENT.PERMISSIONS_MANAGEMENT.CREATE_NEW_PERMISSION.ALL_MACHINES';
  // get data from service
  formService = inject(PERMISSIONS_FORM_SERVICE);
  customers: Signal<[] | SimpleCompany[]> = toSignal(this.formService.getCustomers(), {
    rejectErrors: false,
    initialValue: [],
  });
  distributors: Signal<[] | SimpleCompany[]> = toSignal(this.formService.getDistributors(), {
    rejectErrors: false,
    initialValue: [],
  });
  manufacturers: Signal<[] | SimpleCompany[]> = toSignal(this.formService.getManufacturers(), {
    rejectErrors: false,
    initialValue: [],
  });
  machines: Signal<Machine[] | []> = toSignal(this.formService.getMachines(), {
    rejectErrors: true,
    initialValue: [],
  });
  scopes = toSignal(
    this.formService
      .getScopes()
      .pipe(map((scopes: ScopeType[]) => scopes.filter((item) => item.type !== 'fleet'))),
    { initialValue: [] },
  );
  applications = toSignal(this.formService.getApplications(this.isRemoteFeaturesGroup), {
    initialValue: [],
  });
  view = toSignal(this.formService.getView());
  // check if all entities are available
  isAvailable: Signal<boolean> = computed(
    () =>
      this.customers().length !== 0 &&
      this.distributors().length !== 0 &&
      this.machines().length !== 0 &&
      this.manufacturers().length !== 0,
  );

  // init form, options and signals for form
  choosenApplication: WritableSignal<number | null> = signal(null);
  choosenModule: WritableSignal<number | null> = signal(null);
  choosenScope: WritableSignal<string | null> = signal(null);
  choosenScopeEntity: WritableSignal<number | null> = signal(null);
  choosenRole: WritableSignal<number | null> = signal(null);
  applicationOptions = computed(() => {
    if (this.applications() !== undefined) {
      return this.applications()?.map((application: Application) => ({
        value: application.id.toString(),
        label: application.name,
      }));
    }
    return [];
  });
  preSelectedRole = computed(() => {
    if (this.roleOptions().length > 0) {
      return this.roleOptions()[0].label;
    }
    return '';
  });
  moduleOptions: Signal<TextSelectOption<string>[]> = computed(() => {
    if (this.applications().length > 0) {
      const app =
        this.applications().find(
          (application: Application) => application.id === Number(this.choosenApplication()),
        ) ?? this.applications()[0];
      return this.getModuleOptions(app);
    }
    return [];
  });
  scopeOptions = computed(() => {
    if (this.scopes().length > 0) {
      return this.scopes()
        .map((scope: ScopeType) => ({
          value: scope.type,
          label: scope.type
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' '),
        }))
        .sort((a: TextSelectOption<string>, b: TextSelectOption<string>) =>
          a.label.localeCompare(b.label),
        );
    }
    return [];
  });
  permissionDefined = computed(
    () =>
      this.choosenApplication() !== null &&
      this.choosenModule() !== null &&
      this.choosenScope() !== null &&
      this.choosenScopeEntity() !== null &&
      this.choosenRole() !== null,
  );
  scopeEntities: WritableSignal<Machine[] | SimpleCompany[]> = signal([]);
  scopeEntityOptions = computed(() => {
    if (this.scopeEntities().length > 0) {
      if (this.choosenScope() === 'machine') {
        return this.getFormattedMachine(this.scopeEntities() as Machine[]);
      }
      return this.getFormattedCompany(this.scopeEntities() as Company[]);
    }
    return [];
  });

  getChoosenApp = computed(() =>
    this.applications().find(
      (application: Application) => application.id === Number(this.choosenApplication()),
    ),
  );

  getChoosenModule = computed(
    () =>
      this.getChoosenApp()?.modules.find((module: Module) => module.id === this.choosenModule()) ??
      undefined,
  );
  getChoosenScope = computed(
    () => this.scopes().find((scope: ScopeType) => scope.type === this.choosenScope()) ?? undefined,
  );
  roleOptions = computed(() => {
    const app = this.getChoosenApp() ?? this.applications()[0];
    const module = this.getChoosenModule();
    const scope = this.getChoosenScope();
    const entities = this.scopeEntities();
    return this.getRoleOptions(app, module, scope, entities);
  });

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChildren(TextSelectComponent) textSelectComponents!: QueryList<TextSelectComponent<string>>;

  onApplicationSelected(applicationId: string | undefined) {
    const appId = applicationId !== null ? Number(applicationId) : null;
    this.choosenApplication.set(appId);
  }

  onModuleSelected(moduleId: string | undefined) {
    const moduleIdNumber = moduleId !== null ? Number(moduleId) : null;
    this.choosenModule.set(moduleIdNumber);
  }

  onScopeSelected(scope: string | undefined) {
    this.choosenScope.set(scope ?? null);
    this.getScopeEntityOptions(this.choosenScope() ?? '');
    this.textSelectComponents.toArray()[3].clearSelectionClicked$.next(true);
  }

  onScopeEntitySelected(scopeEntityId: string | undefined) {
    const scopeEntityIdNumber = scopeEntityId !== null ? Number(scopeEntityId) : null;
    this.choosenScopeEntity.set(scopeEntityIdNumber);
  }
  onRoleSelected(roleId: string | undefined) {
    const roleIdNumber = roleId !== null ? Number(roleId) : null;
    this.choosenRole.set(roleIdNumber);
  }

  onSavePermission() {
    const newFleetPermission: FleetPermissionInput = {
      group_id: this.data.groupId !== undefined ? +this.data.groupId : null,
      application_id: this.choosenApplication() !== null ? Number(this.choosenApplication()) : null,
      module_id: this.choosenModule() !== null ? Number(this.choosenModule()) : null,
      scope_type: this.choosenScope() ?? '',
      scope_entity_id:
        this.choosenScopeEntity() !== null ? Number(this.choosenScopeEntity()) : null,
      role_id: this.choosenRole() !== null ? Number(this.choosenRole()) : null,
    };
    this.dialogRef.close(newFleetPermission);
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  private getModuleOptions(selectedApplication: Application): TextSelectOption<string>[] {
    const options =
      selectedApplication.modules
        .map((module: Module) => ({
          value: module.id.toString(),
          label: module.name,
        }))
        .sort((a: TextSelectOption<string>, b: TextSelectOption<string>) =>
          a.label.localeCompare(b.label),
        ) ?? [];
    if (!this.isRemoteFeaturesGroup) {
      const allModules: TextSelectOption<string> = { value: '0', label: 'All Modules' };
      options.unshift(allModules); // Add 'All Modules'
    }
    return options;
  }

  private getFormattedCompany(result: Company[]): TextSelectOption<string>[] {
    const formattedOptions: TextSelectOption<string>[] = [];
    result?.forEach((company: Company) => {
      if (
        this.currentPermissions.filter((permission) => permission.scopeEntityId === company.id)
          .length === 0
      )
        formattedOptions.push({
          value: company.id.toString(),
          label: company.name,
        });
    });
    return formattedOptions;
  }

  private getFormattedMachine(result: Machine[]): TextSelectOption<string>[] {
    const formattedOptions: TextSelectOption<string>[] = [];
    if (Array.isArray(result))
      result?.forEach((machine: Machine) => {
        if (
          this.currentPermissions.filter((permission) => permission.scopeEntityId === machine.id)
            .length === 0
        )
          formattedOptions.push({
            value: machine.id.toString(),
            label: machine.externalId,
          });
      });
    return formattedOptions;
  }

  private getScopeEntityOptions(scopeType: string): void {
    this.choosenScopeEntity.set(null);
    switch (scopeType) {
      case 'customer':
        this.scopeEntities.set(this.customers());
        break;
      case 'distributor':
        this.scopeEntities.set(this.distributors());
        break;
      case 'machine':
        this.scopeEntities.set(this.machines());
        break;
      case 'manufacturer':
        this.scopeEntities.set(this.manufacturers());
        break;
      default:
        this.scopeEntities.set([]);
    }
  }

  private getRoleOptions(
    selectedApplication: Application,
    choosenModule?: Module,
    scopeType?: ScopeType | undefined,
    scopeEntities?: ScopeEntity[] | undefined,
  ): TextSelectOption<string>[] {
    let options: Role[] = [];
    const filteredRoles: TextSelectOption<string>[] = [];
    // 1. Get all possible modules
    const modules: Module[] =
      choosenModule !== undefined ? [choosenModule] : selectedApplication.modules;
    // 2. Filter roles for view
    modules.forEach((module: Module) => {
      options = options.concat(this.getAllRolesInView(module.roles));
    });
    // 3. Check role with existing permissions
    options = this.filterRolesByExistingPermissions(
      selectedApplication,
      options,
      scopeType,
      scopeEntities,
    );
    // 4. Add unique roles from modules
    options.map((role: Role) => {
      if (
        filteredRoles.find(
          (dict: TextSelectOption<string>) => dict.value === role.id?.toString(),
        ) === undefined
      ) {
        return filteredRoles.push({
          value: role.id?.toString(),
          label: role.name,
        } as TextSelectOption<string>);
      }
      return undefined;
    });
    return filteredRoles;
  }

  private isRoleInView(role: Role): boolean {
    return (
      (this.view() === 'vendor' && Boolean(role.isVendor)) ||
      (this.view() === 'manufacturer' && Boolean(role.isManufacturer)) ||
      (this.view() === 'distributor' && Boolean(role.isDistributor)) ||
      (this.view() === 'customer' && role.isCustomer)
    );
  }

  private filterRolesByExistingPermissions(
    application: Application,
    roles: Role[],
    scopeType?: ScopeType | undefined,
    scopeEntities?: ScopeEntity[] | undefined,
  ) {
    let filteredRoles: Role[] = [];
    if (!this.isRemoteFeaturesGroup) {
      filteredRoles = this.filterRolesWithoutRemotePermissions(application, roles);
    }
    if (scopeType !== undefined && scopeEntities !== undefined) {
      filteredRoles = this.filterRolesWithRemotePermissions(
        application,
        filteredRoles,
        scopeType,
        scopeEntities,
      );
    }
    if (!filteredRoles.length) {
      filteredRoles = roles;
    }
    return filteredRoles;
  }

  private filterRolesWithRemotePermissions(
    application: Application,
    roles: Role[],
    scopeType: ScopeType,
    scopeEntities: ScopeEntity[],
  ) {
    const scopeEntitiesIds: number[] = scopeEntities.map((e) => e.id);
    // VPN permissions of selected application
    const permissions = this.currentPermissions.filter(
      (permission: FleetPermission) =>
        permission.application === application.name &&
        permission.isRemoteFeaturesPermission === true &&
        permission.scopeType ===
          (scopeType.type === this.ALL_MACHINES_UNDER_CURRENT_SCOPE_KEY
            ? this.view()
            : scopeType.type) &&
        scopeEntitiesIds.includes(permission.scopeEntityId ?? 0),
    );
    const disallowedPermissionRoles: string[] = [];
    permissions.forEach((permission) => {
      if (disallowedPermissionRoles.every((roleName) => roleName !== permission.role)) {
        disallowedPermissionRoles.push(permission.role ?? '');
      }
    });
    if (disallowedPermissionRoles.length) {
      return roles.filter((role: Role) => !disallowedPermissionRoles.includes(role.name));
    }
    return roles;
  }

  private filterRolesWithoutRemotePermissions(application: Application, roles: Role[]) {
    // Non vpn permissions of selected application
    const permissions = this.currentPermissions.filter(
      (permission) =>
        permission.application === application.name &&
        permission.isRemoteFeaturesPermission === false,
    );
    const allowedPermissionRoles: string[] = [];
    permissions.forEach((permission) => {
      if (allowedPermissionRoles.every((roleName) => roleName !== permission.role)) {
        allowedPermissionRoles.push(permission.role ?? '');
      }
    });
    if (allowedPermissionRoles.length) {
      return roles.filter((role) => allowedPermissionRoles.includes(role.name));
    }
    return roles;
  }

  private getAllRolesInView(allRoles: Role[]): Role[] {
    const options: Role[] = [];
    allRoles?.forEach((role: Role) => {
      // 2. Filter roles for view
      if (this.isRoleInView(role)) {
        options.push(role);
      }
    });
    return options;
  }
}
