import { Component, ChangeDetectionStrategy, inject, computed } from '@angular/core';
import { CommonModule } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { ThemeService } from '../../theme/theme.service';

@Component({
  selector: 'tmc-header-logo',
  template: `<img class="cursor-pointer" routerLink="/" [src]="logoSrc()" [alt]="theme()" />`,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterLink],
})
export class HeaderLogoComponent {
  private readonly themeService = inject(ThemeService);
  readonly theme = toSignal(this.themeService.theme$, { requireSync: true });
  readonly logoSrc = computed(() => `/assets/logo/${this.theme()}-logo.png`);
}
