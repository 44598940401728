import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from '@tmc/fleet-core-ui';
import { Tile } from '@tmc/fleet-core-api';
import { AdminAppUserDetailsService } from '@tmc/fleet-core-feature';
import { toSignal } from '@angular/core/rxjs-interop';
import { hasValue } from '@tmc/core-utils';
import { filter, map } from 'rxjs';

@Component({
  selector: 'tmc-fleet-company-management-landing',
  standalone: true,
  imports: [CommonModule, LandingPageComponent],
  templateUrl: './fleet-company-management-landing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetCompanyManagementLandingComponent {
  private readonly adminAppUserDetail = inject(AdminAppUserDetailsService);
  private readonly manufacturersTile: Tile = {
    heading: 'COMPANY_MANAGEMENT.LANDING.TILE.HEADING.MANUFACTURERS',
    description: 'COMPANY_MANAGEMENT.LANDING.TILE.DESCRIPTION',
    actionText: 'COMPANY_MANAGEMENT.LANDING.TILE.ACTION_TEXT.CREATE_EDIT_MANUFACTURERS',
    alternativeActionText:
      'COMPANY_MANAGEMENT.LANDING.TILE.ALTERNATIVE_ACTION_TEXT.SEE_MANUFACTURERS',
    route: 'manufacturers',
    logo: 'factory',
    views: [{ name: 'vendor', canCreate: true }],
  };
  private readonly distributorsTile: Tile = {
    heading: 'COMPANY_MANAGEMENT.LANDING.TILE.HEADING.DISTRIBUTORS',
    description: 'COMPANY_MANAGEMENT.LANDING.TILE.DESCRIPTION',
    actionText: 'COMPANY_MANAGEMENT.LANDING.TILE.ACTION_TEXT.CREATE_EDIT_DISTRIBUTORS',
    alternativeActionText: 'COMPANY_MANAGEMENT.LANDING.TILE.ACTION_TEXT.SEE_DISTRIBUTORS',
    route: 'distributors',
    logo: 'factory',
    views: [
      { name: 'vendor', canCreate: true },
      { name: 'manufacturer', canCreate: true },
    ],
  };

  private readonly customersTile: Tile = {
    heading: 'COMPANY_MANAGEMENT.LANDING.TILE.HEADING.CUSTOMERS',
    description: 'COMPANY_MANAGEMENT.LANDING.TILE.DESCRIPTION',
    actionText: 'COMPANY_MANAGEMENT.LANDING.TILE.ACTION_TEXT.CREATE_EDIT_CUSTOMERS',
    alternativeActionText: 'COMPANY_MANAGEMENT.LANDING.TILE.ACTION_TEXT.SEE_CUSTOMERS',
    route: 'customers',
    logo: 'factory',
    views: [
      { name: 'vendor', canCreate: true },
      { name: 'manufacturer', canCreate: true },
      { name: 'distributor', canCreate: false },
      { name: 'customer', canCreate: false },
    ],
  };
  tilesData: Tile[] = [this.manufacturersTile, this.distributorsTile, this.customersTile];
  view = toSignal(
    this.adminAppUserDetail.details$.pipe(
      filter(hasValue),
      map((details) => AdminAppUserDetailsService.toView(details.company)),
    ),
  );
}
