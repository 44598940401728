<mat-form-field appearance="outline" class="text-select !w-full">
  <mat-label>{{ this.label }}</mat-label>
  <input
    #autocompleteInputField
    type="text"
    matInput
    [matAutocomplete]="autoCompletePanel"
    (input)="handleInputChange(autocompleteInputField.value)"
    (focus)="handleInputChange(autocompleteInputField.value)"
  />
  <button
    mat-icon-button
    matSuffix
    [ngClass]="{ '!invisible': !autocompleteInputField.value }"
    (click)="$event.stopPropagation(); clearSelectionClicked$.next(true)"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete
    #autoCompletePanel="matAutocomplete"
    [displayWith]="inputFieldFormatter"
    [requireSelection]="true"
    (optionSelected)="userSelectionEvents$.next($event.option.value)"
  >
    @for (option of filteredOptions$ | async; track option) {
      <mat-option [value]="option">{{ option.label }}</mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
