<mat-card *ngIf="comment$ | async as comment" class="!mb-sm">
  <mat-card-header>
    <div class="flex w-full justify-between">
      <div class="flex-1">
        <mat-card-title>{{ comment.owner }}</mat-card-title>
      </div>
      <div class="flex-1 text-right">
        <mat-card-subtitle>
          {{ comment.lastUpdatedAt | date: 'short' }}
          @if (comment.createdAt !== comment.lastUpdatedAt) {
            <mat-icon inline="true"> edit </mat-icon>
          }
        </mat-card-subtitle>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    @if (inEditState) {
      <mat-form-field class="!w-full" appearance="outline">
        <textarea matInput cdkTextareaAutosize [(ngModel)]="comment.text"></textarea>
      </mat-form-field>
    } @else {
      <p class="whitespace-pre-wrap break-words">{{ comment.text }}</p>
    }
  </mat-card-content>

  <div class="flex justify-end">
    <mat-card-actions class="flex items-center">
      @if (!inEditState && comment.permissions.includes('WRITE')) {
        <button
          mat-icon-button
          color="primary"
          matTooltip="{{ 'EDIT' | translate }}"
          (click)="handleEditClick(comment)"
        >
          <mat-icon>edit</mat-icon>
        </button>
      }
      @if (inEditState) {
        <button
          mat-icon-button
          color="primary"
          matTooltip="{{ 'SAVE' | translate }}"
          [disabled]="!comment.text.length"
          (click)="handleSaveClick(comment)"
        >
          <mat-icon>save</mat-icon>
        </button>

        <button
          mat-icon-button
          color="primary"
          matTooltip="{{ 'CANCEL_EDIT' | translate }}"
          (click)="cancelEditting(comment)"
        >
          <mat-icon>close</mat-icon>
        </button>
      }
      @if (comment.permissions.includes('DELETE')) {
        <button
          mat-icon-button
          color="primary"
          matTooltip="{{ 'DELETE' | translate }}"
          (click)="handleDeleteClick(comment)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      }
    </mat-card-actions>
  </div>
</mat-card>
