@if (allMachines()?.loading) {
  <tmc-progress-spinner></tmc-progress-spinner>
} @else {
  <mat-sidenav-container>
    <mat-sidenav
      #sidenav
      position="end"
      class="!w-[23rem]"
      [mode]="this.sideBarMode()"
      [(opened)]="sideBarOpened"
    >
      <button
        class="sticky top-1/2 z-10 h-14 w-7 -translate-y-1/2 rounded-e-[56px] border border-gray-100"
        mat-icon-button
        (click)="sidenav.toggle()"
      >
        <mat-icon class="align-middle !text-primary">chevron_right</mat-icon>
      </button>
      <tmc-fleet-machine-sidebar
        class="h-full justify-start"
        [allModules]="modules()"
        (filterChanged)="onFilterChanged($event)"
        (categoryChanged)="onOptionChange($event)"
      >
      </tmc-fleet-machine-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="flex min-h-[80vh] items-start justify-center overflow-hidden">
        <div class="mx-2 flex size-full h-full flex-col overflow-hidden">
          <div class="box-border h-auto">
            <div class="flex justify-end">
              <div class="my-sm flex">
                @if (this.adminAppUserDetail.view() === 'vendor') {
                  <button
                    class="flex items-center text-base font-semibold text-primary"
                    (click)="openCreateNewMachine()"
                  >
                    <mat-icon class="mr-1" matPrefix>add</mat-icon>
                    {{ 'COMMON.ACTIONS.ADD' | translate }}
                  </button>
                }
                <tmc-table-search-box [(filterCriteria)]="listFilter"></tmc-table-search-box>
              </div>
            </div>
          </div>
          @if (allMachines()?.loading === true) {
            <div class="m-xl flex">
              <span>{{ 'MACHINE_MANAGEMENT.LIST.NO_MACHINES' | translate }}</span>
            </div>
          } @else {
            <tmc-list-machines
              [machines]="filteredMachines()"
              (removeSingleMachine)="removeMachine($event)"
            ></tmc-list-machines>
            <div class="flex justify-end">
              <button mat-raised-button class="mt-2" color="primary" (click)="exportToCsv()">
                {{ 'COMMON.EXPORT_TO_CSV' | translate }}
              </button>
            </div>
          }
        </div>
        @if (!this.sideBarOpened()) {
          <button
            mat-icon-button
            class="right-0 top-1/2 h-14 w-7 rounded-s-[56px] border border-gray-100 bg-white"
            (click)="sidenav.toggle()"
          >
            <mat-icon class="align-middle !text-primary">chevron_left</mat-icon>
          </button>
        }
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
}
