/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Provider } from '@angular/core';
import { MACHINE_FORM_SERVICE } from '@tmc/fleet-machine-management-ui';
import { FleetMachineFormService } from './fleet-machine-form.service';

export function provideMachineFormOptions(): Provider {
  return [
    {
      provide: MACHINE_FORM_SERVICE,
      useClass: FleetMachineFormService,
    },
  ];
}
