import { Component, ChangeDetectionStrategy, input, output, effect, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, switchMap } from 'rxjs';
import { DialogService } from '@tmc/core-ui';
import { PHOTOGRAPH_MAX_SIZE } from '../shared/app.constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-picture-upload',
  standalone: true,
  imports: [CommonModule, MatIcon, MatButtonModule, TranslateModule],
  templateUrl: './picture-upload.component.html',
})
export class PictureUploadComponent {
  private readonly translate = inject(TranslateService);
  private readonly dialog = inject(DialogService);
  formPhoto = input<string | undefined>();
  modifiedFormPhoto = output<string | undefined>();
  photographFile = output<File>();
  photoSignedUrl$ = new Subject<string>();
  photographData = '';

  constructor() {
    effect(() => {
      if (this.formPhoto() !== 'undefined') {
        this.photoSignedUrl$.next(this.formPhoto() ?? '');
      }
    });
  }

  async setFileData(event: Event): Promise<void> {
    const eventTarget: HTMLInputElement | null = event.target as HTMLInputElement | null;
    if (eventTarget?.files?.[0]) {
      const file: File = eventTarget.files[0];
      this.photographFile.emit(file);
      if (file.size > PHOTOGRAPH_MAX_SIZE) {
        this.translate
          .get('PATH.MACHINES.MACHINE_PHOTO_TO_BIG', {
            size: PHOTOGRAPH_MAX_SIZE / 1024 / 1024,
          })
          .pipe(switchMap((message: string) => this.dialog.openSnackBar({ message })))
          .subscribe();
        return;
      }
      await this.readFileAsDataURL(file).then((data) => {
        this.photographData = data;
        this.photoSignedUrl$.next(data);
        this.modifiedFormPhoto.emit(data);
        return undefined;
      });
    }
  }

  private async readFileAsDataURL(file: File): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result as string);
      };
    });
  }
}
