import { ApplicationConfig } from '@angular/core';
import {
  getOAuthInterceptors,
  provideTmosCore,
  provideOAuthWithRoutes,
  provideErrorHandler,
} from '@tmc/core-base';
import { camelCaseKeysInterceptor } from '@tmc/core-utils';
import { adminAppHttpInterceptor } from '@tmc/fleet-core-feature';
import { ADMIN_APP_ROUTES } from './admin-app.routes';

export const ADMIN_APP_CONFIG: ApplicationConfig = {
  providers: [
    provideOAuthWithRoutes(ADMIN_APP_ROUTES),
    provideTmosCore(
      {
        locale: localStorage.getItem('LANG_CODE') ?? undefined,
        translationPath: './assets/i18n/',
      },
      [
        ...getOAuthInterceptors(),
        adminAppHttpInterceptor(),
        camelCaseKeysInterceptor('/fleet-admin/'),
      ],
    ),
    provideErrorHandler(),
  ],
};
