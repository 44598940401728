<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content style="min-width: 300px">
  <tmc-text-select
    [label]="data.title | translate"
    [options]="data.options"
    [preSelection]="data.preSelection"
    (selected)="handleSelected($event)"
  ></tmc-text-select>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">
    {{ 'PROTOCOL_LIST.CONFIRMATION_ABORT_LABEL' | translate }}
  </button>
  <button
    mat-button
    cdkFocusInitial
    [disabled]="acceptBtnDisabled$ | async"
    (click)="closeDialog()"
  >
    {{ 'PROTOCOL_LIST.CONFIRMATION_ACCEPT_LABEL' | translate }}
  </button>
</div>
