@if (!groups()) {
  <tmc-progress-spinner></tmc-progress-spinner>
} @else {
  <div class="box-border h-full">
    <div class="flex justify-end">
      <div class="my-sm flex">
        <button
          mat-button
          class="flex items-center text-base font-semibold text-primary"
          (click)="openCreateGroupDialog()"
        >
          <mat-icon class="mr-1" matPrefix>add</mat-icon>
          {{ 'USER_MANAGEMENT.GROUPS.CREATE_NEW_GROUP' | translate }}
        </button>
        <tmc-table-search-box [(filterCriteria)]="listFilter"></tmc-table-search-box>
      </div>
    </div>
  </div>

  @if (groups()?.length === 0) {
    <div class="m-xl flex">
      <span>{{ 'USER_MANAGEMENT.GROUPS.NO_GROUPS' | translate }}</span>
    </div>
  } @else {
    <tmc-groups-list
      [groups]="filteredGroups()"
      (deleteGroupEvent)="deleteGroup($event)"
      (openAddUserToGroup)="openAddUserToGroup($event)"
      (openRemoveUserFromGroup)="openRemoveUserFromGroup($event.group, $event.user)"
      (removeUserEmailFromGroup)="removeUserEmailFromGroup($event.group, $event.userEmail)"
    ></tmc-groups-list>
  }
}
