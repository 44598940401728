<div class="max-h-table flex flex-col">
  <div class="relative my-5 grow overflow-y-auto">
    <mat-table matSort multiTemplateDataRows aria-label="List of groups" [dataSource]="dataSource">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse p-2 px-6 py-3 text-left leading-5"
          mat-sort-header="name"
          >{{ 'COMMON.TABLE_HEADERS.NAME' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let element" class="truncate p-2 px-6 text-left"
          ><span tmcTruncateTooltip>{{ element.name }}</span></mat-cell
        >
      </ng-container>

      <!-- Users Column -->
      <ng-container matColumnDef="users">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse p-2 px-6 py-3 text-left leading-5"
          fxLayoutAlign="start center"
        >
          <span>{{ 'COMMON.USERS' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let group" class="truncate p-2 px-6 text-left">
          <mat-chip-listbox>
            @if (group.users.length > 2) {
              <mat-chip
                [removable]="true"
                (removed)="removeUserFromGroup(group.users[0].user, group)"
              >
                <span tmcTruncateTooltip> {{ group.users[0].email }} </span>
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <mat-chip
                class="relative grid items-center truncate"
                [matTooltip]="'COMMON.DIALOGS.OPEN_DETAIL' | translate"
                (click)="openAllAdminsDialog(group)"
              >
                <span> ... </span>
              </mat-chip>
            } @else {
              @for (user of group.users; track user) {
                <mat-chip [removable]="true" (removed)="removeUserFromGroup(user, group)">
                  <span> {{ user.email }} </span>
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              } @empty {
                <span>-</span>
              }
            }
          </mat-chip-listbox>
        </mat-cell>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="ACTIONS">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse justify-center whitespace-nowrap p-2 px-6 py-3 text-center leading-5"
          >{{ 'COMMON.TABLE_HEADERS.ACTIONS' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let group" class="justify-center whitespace-nowrap text-center">
          <button class="pr-2" mat-icon-button (click)="addUserToGroup(group)">
            <mat-icon
              [matTooltip]="'USER_MANAGEMENT.ADD_USER_TO_GROUP' | translate"
              [matTooltipShowDelay]="delayValues.SHORT_DELAY"
              [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
              >group_add</mat-icon
            >
          </button>

          <button
            class="pr-2"
            mat-icon-button
            [routerLink]="['./' + group.id]"
            id="perm_{{ group.id }}"
          >
            <mat-icon
              [matTooltip]="'COMMON.ACTIONS.PERMISSIONS' | translate"
              [matTooltipShowDelay]="delayValues.SHORT_DELAY"
              [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
              >manage_accounts</mat-icon
            >
          </button>

          <button class="pr-2" mat-icon-button (click)="deleteGroup(group)">
            <mat-icon
              class="text-red-700"
              [matTooltip]="'COMMON.ACTIONS.DELETE' | translate"
              [matTooltipShowDelay]="delayValues.SHORT_DELAY"
              [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
              >delete</mat-icon
            >
          </button>
        </mat-cell>
      </ng-container>

      <!-- Header Row -->
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

      <!-- Data Rows -->
      <mat-row *matRowDef="let element; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <mat-paginator aria-label="Select page" [pageSize]="25" [pageSizeOptions]="pageSizeOptions">
  </mat-paginator>
</div>
