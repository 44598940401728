// Extract all field types from Type
// e.g. type ExampleType = { a: string; b: number; c: number}; FieldTypes<ExampleType> --> string | number
export type FieldTypes<Type> = Type[keyof Type];

// Extract all field types from Type that match ExtractedType
// e.g. type ExampleType = { a: ComplexType1; b: ComplexType2; c: number}; FieldTypesOfType<ExampleType, object> --> ComplexType1 | ComplexType2
export type FieldTypesOfType<Type, ExtractedType> = Extract<FieldTypes<Type>, ExtractedType>;

// Extract all field types from Type that do not match ExcludedType
// e.g. type ExampleType = { a: ComplexType1; b: ComplexType2; c: number}; FieldTypesExcludingType<ExampleType, object> --> number
export type FieldTypesExcludingType<Type, ExcludedType> = Exclude<FieldTypes<Type>, ExcludedType>;

// https://dev.to/kamil7x/how-to-get-class-fields-of-given-type-12pf
// Extract keys from Type that match ValueType type as union
// e.g. type ExampleType = { a: string; b: number; c: number}; KeysOfType<ExampleType, number> --> 'b' | 'c'
export type KeysOfType<Type, ValueType> = keyof {
  [Key in keyof Type as Type[Key] extends ValueType ? Key : never]: unknown;
};

export function getTypedObjectValue<T extends object, K extends keyof T, V = unknown>(
  obj: T,
  key: K,
): V {
  return obj[key] as V;
}

export function getTypedObjectKeys<T extends object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}

export function getObjectValue(obj: object, key: string) {
  return (obj as Record<string, unknown>)[key];
}

export function isPrimitive(value: unknown): value is string | number | boolean {
  return typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';
}

export function isDictionary<T>(obj: unknown): obj is Record<string, T> {
  return (
    typeof obj === 'object' && obj !== null && !(obj instanceof Array) && !(obj instanceof Date)
  );
}

export function hasValue<T>(value: T | undefined | null): value is T {
  return value !== null && value !== undefined;
}
