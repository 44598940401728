import { PageEvent } from '@angular/material/paginator';

export class Pagination {
  public static readonly PAGE_SIZE_OPTIONS: number[] = [10, 25, 50, 100];
  constructor(
    public page: number,
    public pageSize: number,
    public total: number,
  ) {}

  public static pageSettings(): PageEvent {
    return {
      length: 10,
      pageIndex: 0,
      pageSize: 10,
    };
  }
}
