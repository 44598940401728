import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslateObjectService {
  private readonly translate = inject(TranslateService);

  /**
   * Translates string values in a object at provided paths.
   * The current value of the path in the object will be used as a translation key.
   * If the object contains a array at any point in the path, all array elements will be checked for translations with the remaining subpath.
   * If the last element in the path is a array all values in the array will be translated.
   * @param object that should be translated
   * @param paths paths that should be translated, if a path doesn't exist or isn't a string it will be left as before
   * @returns a Promise with the same object, but updated translations
   */
  translateObjectPaths<T>(object: T, paths: string[]): Promise<T> {
    const pathArrays = paths.map((path) => path.split('.'));
    return Promise.all(
      pathArrays.flatMap((path) => this.translateObjectPath(object, path), []),
    ).then(() => object);
  }

  private translateObjectPath(object: unknown, path: string[]): Promise<unknown>[] {
    const treeNodes = path.slice(0, -1);
    let leafs = [object];
    treeNodes.forEach((node) => {
      leafs = leafs.flatMap((o) => this.openNode(o, node));
    });

    const leafNode = path[path.length - 1];
    return leafs.flatMap((o) => this.translateNode(o, leafNode));
  }

  private openNode(object: unknown, node: string): unknown[] {
    if (Array.isArray(object)) return object.map((child: unknown) => this.openNode(child, node));
    if (this.isStringRecord(object)) return [object[node]];
    return [];
  }

  private translateNode(object: unknown, node: string): Promise<unknown>[] {
    if (Array.isArray(object)) return object.flatMap((o) => this.translateNode(o, node));
    if (!this.isStringRecord(object) || !Object.hasOwn(object, node)) return [];
    const value = object[node];
    if (Array.isArray(value)) return this.translateArray(value);
    if (typeof value === 'string')
      return [
        this.getTranslation(value).then((translation) => {
          object[node] = translation;
        }),
      ];
    return [];
  }

  private translateArray(array: unknown[]): Promise<unknown>[] {
    return array.flatMap((value, index) => {
      if (typeof value !== 'string') return [];
      return [
        this.getTranslation(value).then((translation) => {
          array[index] = translation;
        }),
      ];
    });
  }

  private getTranslation(value: string): Promise<string> {
    return firstValueFrom(this.translate.get(value));
  }

  private isStringRecord(obj: unknown): obj is Record<string, unknown> {
    return obj !== undefined && obj !== null && !Array.isArray(obj);
  }
}
