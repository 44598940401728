import { inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigOptions, download, generateCsv, mkConfig } from 'export-to-csv';
import { of, switchMap } from 'rxjs';
import { formatDate } from '@angular/common';
import { CSVHeader } from './csv-header.model';

@Injectable()
export class CSVExportService<T extends NonNullable<unknown>> {
  private readonly csvExportDateFormat = 'yyyy_MM_dd_HH_mm';
  private readonly locale = inject(LOCALE_ID);
  private readonly translationService = inject(TranslateService);

  downloadCSV(
    data: T[],
    dataDescription: string,
    isFiltered: boolean,
    checkedHeaderKeys: string[],
    allCSVHeaders: CSVHeader[],
    customCSVMapFunction?: (obj: T) => Record<string, unknown>,
  ) {
    const dateString = formatDate(new Date(), this.csvExportDateFormat, this.locale);
    const filteredLabel = isFiltered ? 'filtered' : 'all';
    const fileName = `${filteredLabel}_${dataDescription}_${dateString}`;
    const filteredHeaders = allCSVHeaders.filter((header) =>
      checkedHeaderKeys.includes(header.key),
    );
    const headerTranslationKeys = filteredHeaders.map((header) => header.label);

    return this.translationService.get(headerTranslationKeys).pipe(
      switchMap((translatedResult: Record<string, string>) => {
        const translatedLabels = Object.keys(translatedResult).map((key) => translatedResult[key]);
        const config = mkConfig({
          filename: fileName,
          fieldSeparator: ',',
          quoteStrings: true,
          decimalSeparator: '.',
          showColumnHeaders: true,
          useTextFile: false,
          useBom: true,
          columnHeaders: translatedLabels,
        });
        const headersTranslated = filteredHeaders.reduce(
          (acc: Record<string, string>, header: CSVHeader) => ({
            ...acc,
            [header.key]: translatedResult[header.label],
          }),
          {},
        );
        download(config)(this.createOutput(data, headersTranslated, config, customCSVMapFunction));
        return of();
      }),
    );
  }

  private createOutput(
    data: T[],
    headersTranslated: Record<string, string>,
    config: ConfigOptions,
    customCSVMapFunction?: (obj: T) => Record<string, unknown>,
  ) {
    const csvData = data.map((obj: T) => {
      const exportableData: Record<string, unknown> = customCSVMapFunction
        ? { ...obj, ...customCSVMapFunction(obj) }
        : { ...obj };

      return Object.keys(headersTranslated).reduce(
        (acc, key) => ({ ...acc, [headersTranslated[key]]: exportableData[key] ?? '--' }),
        {},
      );
    });

    return generateCsv(config)(csvData);
  }
}
