import { Component, inject, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationDialogConfig } from './confirmation-dialog.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-confirmation-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogClose,
    MatButtonModule,
    MatDialogContent,
    MatDialogTitle,
    MatDialogActions,
    TranslateModule,
  ],
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {
  private readonly dialogRef = inject(MatDialogRef<ConfirmationDialogComponent>);
  public data: ConfirmationDialogConfig = inject(MAT_DIALOG_DATA);

  onNoClick(): void {
    this.dialogRef.close();
  }
}
