import { Component, OnInit, inject, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { filter } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-breadcrumb',
  standalone: true,
  imports: [CommonModule, RouterModule, MatIconModule, TranslateModule],
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly translateService = inject(TranslateService);

  breadcrumbs: IBreadcrumb[] = [];

  ngOnInit() {
    // Subscribe to URL changes
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // Access URL
        this.breadcrumbs = this.getBreadcrumbs(event.url.split('/').splice(1));
      });
  }

  getBreadcrumbs(routes: string[]) {
    const dynamicRoutes: IBreadcrumb[] = [];

    routes.forEach((route, index) => {
      if (route !== 'home' && !route.startsWith('?') && route !== '') {
        const url = routes.slice(0, index + 1).join('/');
        let label =
          route.includes('@') || route.includes('_') || this.containsOnlyDigits(route)
            ? route
            : `BREADCRUMB.${route.replaceAll('-', '_').toUpperCase()}`;

        const translatedLabel = this.translateService.instant(label) as string;
        if (translatedLabel === label) {
          label = route;
        }

        const breadcrumb: IBreadcrumb = {
          label,
          url,
        };
        dynamicRoutes.push(breadcrumb);
      }
    });
    return dynamicRoutes;
  }

  private containsOnlyDigits(item: string) {
    return /^\d+$/.test(item);
  }
}

export interface IBreadcrumb {
  label: string;
  url: string;
}
