<h2 mat-dialog-title>{{ title | translate }}</h2>
<mat-dialog-content class="h-[100px] w-[500px]" class="overflow-hidden">
  <div>
    <mat-form-field appearance="outline" class="my-sm w-full">
      <mat-label>{{ formLabel | translate }}</mat-label>
      <input matInput [formControl]="formControl" />
      @if (formControl.invalid) {
        <mat-error>
          @for (validator of formValidators; track validator) {
            <ng-container>
              @if (formControl.hasError(validator.validatorErrorKey)) {
                <span>{{ validator.validatorErrorMsg | translate }}</span>
              }
            </ng-container>
          }
        </mat-error>
      }
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" class="uppercase" (click)="onCancel()">
    {{ 'COMMON.ACTIONS.CANCEL' | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    class="uppercase"
    [disabled]="formControl.invalid"
    (click)="onSubmit()"
  >
    {{ 'COMMON.ACTIONS.CONFIRM' | translate }}
  </button>
</mat-dialog-actions>
