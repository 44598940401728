import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import { TmosComment } from '@tmc/core-api';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'tmc-comment',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    MatCardModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
  ],
  templateUrl: './comment.component.html',
})
export class CommentComponent {
  @Input()
  set comment(comment: TmosComment | null) {
    if (comment === null) {
      return;
    }
    this.comment$.next(comment);
  }
  comment$ = new ReplaySubject<TmosComment>(1);

  @Output() commentDeleted = new EventEmitter<string>();
  @Output() commentEdited = new EventEmitter<TmosComment>();
  inEditState = false;
  unEditedText = '';

  handleDeleteClick(comment: TmosComment) {
    this.commentDeleted.emit(comment.id);
  }

  handleSaveClick(comment: TmosComment) {
    this.inEditState = false;
    this.commentEdited.emit(comment);
  }

  cancelEditting(comment: TmosComment) {
    comment.text = this.unEditedText;
    this.comment$.next(comment);
    this.inEditState = false;
  }

  handleEditClick(comment: TmosComment) {
    this.unEditedText = comment.text;
    this.inEditState = true;
  }
}
