export type Comperator<T> = (a: T, b: T) => number;

export class ArrayUtils {
  /**
   * Convert undefined or a single value or array of values to array containing the values.
   *
   * @param values single value, array of values or undefined.
   * @returns array of values or empty array.
   */
  static toArray<T>(values: T | T[] | undefined): T[] {
    if (values == null) return [];
    if (!Array.isArray(values)) return [values];
    return values;
  }

  /**
   * @param arr to be checked for unique elements
   * @returns a array containing each element only once
   */
  static unique<T>(arr: T[]): T[] {
    return [...new Set(arr)];
  }

  /**
   * Function that returns unique array based on a property K of element T
   * @param arr Array of Objects of type T to be converted to unique array
   * @param keyExtractor Function parameter to specify the unique property K
   * @returns
   */
  static uniqueBy<T, K>(arr: T[], keyExtractor: (elem: T) => K) {
    const uniques = new Map<K, T>();
    arr.forEach((element) => {
      const key = keyExtractor(element);
      if (!uniques.has(key)) uniques.set(key, element);
    });
    return Array.from(uniques.values());
  }

  /**
   *
   * @param array to be searched trough
   * @param value to be searched for
   * @param compare function to compare the values
   * @returns index of the found element, or negative index+1 of the last value before
   */
  static binarySearch<T>(array: T[], value: T, compare: Comperator<T> = (a, b) => +a - +b) {
    let first = 0;
    let last = array.length - 1;
    while (first <= last) {
      const middle = (first + last) >> 1; // eslint-disable-line no-bitwise
      const comp = compare(value, array[middle]);
      if (comp > 0) {
        first = middle + 1;
      } else if (comp < 0) {
        last = middle - 1;
      } else {
        return middle;
      }
    }
    return -(first + 1);
  }
}
