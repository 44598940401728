import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResult } from '../models/paginated-result.model';
import { Widget } from './widget.model';

@Injectable({ providedIn: 'root' })
export class WidgetService {
  private readonly http = inject(HttpClient);

  getAll(): Promise<Widget[]> {
    return lastValueFrom(
      this.http
        .get<PaginatedResult<Widget>>(`/fleet-admin/widgets`)
        .pipe(map((result: PaginatedResult<Widget>) => result.items)),
    );
  }
}
