<div class="box-border h-full">
  <div class="flex justify-end">
    <div class="my-sm flex">
      <button
        mat-button
        class="flex items-center text-base font-semibold text-primary"
        (click)="openCreateNewPermissionDialog()"
      >
        <mat-icon class="mr-1" matPrefix>add</mat-icon>
        {{ 'USER_MANAGEMENT.PERMISSIONS_MANAGEMENT.CREATE_NEW_PERMISSION' | translate }}
      </button>
      <tmc-table-search-box [(filterCriteria)]="listFilter"></tmc-table-search-box>
    </div>
  </div>
</div>

<tmc-list-permissions
  [permissions]="filteredPermissions()"
  [isUser]="isUser()"
  (deletePermissionEvent)="deletePermission($event)"
></tmc-list-permissions>
