import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // Log the error to the console (or send it to a logging infrastructure)
      console.error(`${operation} failed: ${error.message}`);
      // Return an empty result so the application continues to run
      return of(result as T);
    };
  }

  getErrorMessage(error: unknown): string {
    if (error instanceof Error) {
      return error.toString();
    }
    if (typeof error === 'object') {
      return JSON.stringify(error);
    }
    return 'ERROR';
  }

  toQueryString(params: Record<string, string | number>): string {
    return Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
  }
}
