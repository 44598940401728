import { makeEnvironmentProviders, isDevMode } from '@angular/core';
import {
  AutoLoginPartialRoutesGuard,
  LogLevel,
  authInterceptor,
  provideAuth,
} from 'angular-auth-oidc-client';
import { removeSubdomain } from '@tmc/core-utils';
import { Route, Routes, provideRouter } from '@angular/router';
import { CallbackComponent } from './callback/callback.component';
import { SECURE_URLS } from './secure-urls';

/**
 * Provides Authentication trough keycloak and private routes
 * @param privateRoutes that should be secured trough keycloak
 * @returns environment providers for keycloak and router
 */
export function provideOAuthWithRoutes(privateRoutes: Route[]) {
  return makeEnvironmentProviders([provideOAuth(), provideRouter(getOAuthRoutes(privateRoutes))]);
}

/**
 * Provides Authentication trough keycloak
 * @returns environment providers for keycloak
 */
export function provideOAuth() {
  const { hostname, origin } = window.location;
  const identityProviderUrl = `https://auth.${removeSubdomain(hostname)}/realms/tmOS`;
  return makeEnvironmentProviders([
    provideAuth({
      config: {
        authority: identityProviderUrl, // url to keycloak API (will be translated by proxy to different port)
        redirectUrl: `${origin}/callback`, // redirect after successful login
        postLogoutRedirectUri: origin, // redirect after logout
        clientId: 'angular', // this must match the client name in the keycloak client configuration
        scope: 'openid offline_access', // default scopes
        responseType: 'code', // defines that the oauth uses authorization + PKCE code flow
        silentRenew: true, // library renews access token automatically before expiry
        useRefreshToken: false,
        silentRenewUrl: `${origin}/assets/silent-renew.html`,
        renewTimeBeforeTokenExpiresInSeconds: 10,
        logLevel: isDevMode() ? LogLevel.Warn : LogLevel.Error,
        secureRoutes: [...SECURE_URLS],
        autoUserInfo: true,
      },
    }),
  ]);
}

/**
 * Routs for keycloak with private routs
 * @param privateRoutes that should be secured trough keycloak
 * @returns
 */
export function getOAuthRoutes(privateRoutes: Route[]): Routes {
  return [
    {
      path: 'callback',
      component: CallbackComponent,
    },
    {
      path: '',
      canActivateChild: [AutoLoginPartialRoutesGuard],
      children: privateRoutes,
    },
  ];
}

export function getOAuthInterceptors() {
  return [authInterceptor()];
}
