import { ModuleRole } from '@tmc/fleet-core-api';
import { Application } from '../../application/application.model';
import { ScopeItem } from '../../scope-type/scope-type.model';
import { FleetPermission } from '../fleet-permission.model';

export class GroupStandardPermissionSync implements FleetPermission {
  module: string;
  role: string;
  scopeType: string;
  scopeEntityId: number;
  isUserPermission: boolean;
  isRemoteFeaturesPermission: null;
  scopeName: string;
  applicationName: string;
  constructor(
    public id: number,
    public application: Application,
    public moduleRole: ModuleRole | null,
    public scope: ScopeItem,
  ) {
    this.applicationName = application?.name ?? '';
    this.module = moduleRole?.module.name ?? '';
    this.role = moduleRole?.role.name ?? '';
    this.scopeType = scope?.type.type ?? '';
    this.scopeEntityId = scope?.id ?? -1;
    this.isUserPermission = false;
    this.isRemoteFeaturesPermission = null;
    this.scopeName = scope.type.type ?? '';
  }
}
