import { Component, ChangeDetectionStrategy, computed, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'tmc-header-profile',
  templateUrl: './header-profile.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslateModule, MatButtonModule, MatMenuModule, MatIconModule],
})
export class HeaderProfileComponent {
  private readonly authService = inject(OidcSecurityService);

  username = computed(() => {
    const { userData } = this.authService.userData();
    if (
      userData !== null &&
      typeof userData === 'object' &&
      typeof userData.preferred_username === 'string'
    ) {
      return userData.preferred_username;
    }
    return undefined;
  });

  login() {
    this.authService.authorize();
  }

  logout() {
    this.authService.logoffAndRevokeTokens().subscribe();
  }
}
