import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'fallback',
  standalone: true,
})
export class FallbackPipe implements PipeTransform {
  private readonly translateService = inject(TranslateService);

  transform(value: string | null | undefined, fallbackValue = 'NOT_AVAILABLE') {
    return value ?? this.translateService.instant(fallbackValue);
  }
}
