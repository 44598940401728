import { Injectable } from '@angular/core';
import { hasValue } from '@tmc/core-utils';
import { BehaviorSubject, filter, first } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  readonly accessToken = new BehaviorSubject<string | undefined>(undefined);

  get currentAccessToken$() {
    return this.accessToken.pipe(filter(hasValue), first());
  }
}
