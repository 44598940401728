import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import { UtilsService } from '@tmc/fleet-core-utils';
import { PaginatedResult } from '@tmc/fleet-core-api';
import { User } from './user.model';
import { FleetPermissionService } from '../../fleet-permissions-management-api/fleet-permission/fleet-permission.service';
import { Group } from '../group/group.model';
import { UserPermission } from '../../fleet-permissions-management-api/fleet-permission/user/user-fleet-permission.model';

@Injectable()
export class UserService {
  private readonly http = inject(HttpClient);
  private readonly utilsService = inject(UtilsService);
  private readonly fleetPermissionService = inject(FleetPermissionService);

  transform = (user: User): User => ({
    ...user,
    permissions: this.fleetPermissionService.transformToUserFleetPermissions(
      user.permissions as UserPermission[],
    ),
    groups:
      user.groups?.filter(
        (group) => group.users?.map((mapUser) => mapUser.email).includes(user.email) || [],
      ) ?? [],
  });

  getAll(): Promise<User[]> {
    return lastValueFrom(
      this.http
        .get<PaginatedResult<User>>(`/fleet-admin/users?`)
        .pipe(
          map((users: PaginatedResult<User>) =>
            users.items.map((user: User) => this.transform(user)),
          ),
        ),
    );
  }

  getOne(email: string): Promise<User> {
    return firstValueFrom(
      this.http
        .get<User>(`/fleet-admin/users/${encodeURIComponent(email)}?`)
        .pipe(map((user: User) => this.transform(user))),
    );
  }

  async create(email: string, options?: Record<string, string | number>): Promise<User> {
    const queryString = options ? `?${this.utilsService.toQueryString(options)}` : '';

    return lastValueFrom(
      this.http
        .post<User>(`/fleet-admin/users?${queryString}`, { email })
        .pipe(map((user: User) => this.transform(user))),
    );
  }

  delete(email: string, options?: Record<string, string | number>): Promise<void> {
    const queryString = options ? `?${this.utilsService.toQueryString(options)}` : '';
    return firstValueFrom(
      this.http
        .delete(`/fleet-admin/users/${encodeURIComponent(email)}?${queryString}`)
        .pipe(map(() => undefined)),
    );
  }

  update(email: string, body: object): Promise<User> {
    return lastValueFrom(
      this.http
        .put<User>(`/fleet-admin/users/${encodeURIComponent(email)}`, body)
        .pipe(map((user: User) => this.transform(user))),
    );
  }

  updateGroupToUser(userEmail: string, group: Group, add: boolean): Promise<User> {
    const dictionary = {
      group_add_remove: true,
      group_id: group.id,
      add,
    };
    return this.update(userEmail, dictionary);
  }

  updateGroupIdToUser(userEmail: string, groupId: number, add: boolean): Promise<User> {
    const dictionary = {
      group_add_remove: true,
      group_id: groupId,
      add,
    };
    return this.update(userEmail, dictionary);
  }
}
