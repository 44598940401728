import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatLabel } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TruncateTooltipDirective } from '@tmc/core-ui';
import { MatRippleModule } from '@angular/material/core';
import { CSVHeader } from './csv-header.model';
import { CSVExportDialogData } from './csv-export-dialog-data.model';
import { CSVFormType, CheckboxesFormGroup } from './csv-export-dialog.model';
import { CSVExportService } from './csv-export.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-csv-export-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatLabel,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    TruncateTooltipDirective,
    MatRippleModule,
  ],
  providers: [DatePipe, CSVExportService],
  templateUrl: './csv-export-dialog.component.html',
})
export class CsvExportDialogComponent<T extends NonNullable<unknown>> {
  private readonly config = inject<MatDialogConfig<CSVExportDialogData<T>>>(MAT_DIALOG_DATA);
  private readonly formBuilder: FormBuilder = inject(FormBuilder);
  private readonly csvExportService = inject(CSVExportService);
  readonly dialogRef = inject(MatDialogRef);

  form: FormGroup<CSVFormType>;
  checkboxesForm: CheckboxesFormGroup;
  headers: CSVHeader[];
  toggleAllLabel: string;
  toggleFilteredLabel: string;
  dataDescription: string;
  filteredData: T[];
  allData: T[];
  customCSVMapFunction: ((obj: T) => Record<string, unknown>) | undefined;

  constructor() {
    const { data } = this.config;

    if (data !== undefined && data !== null) {
      this.headers = data.headers;
      this.toggleAllLabel = data.toggleAllLabel;
      this.toggleFilteredLabel = data.toggleFilteredLabel;
      this.allData = data.allData;
      this.filteredData =
        (data.filteredData?.length === data.allData.length ? [] : data.filteredData) ?? [];
      this.customCSVMapFunction = data.customCSVMapFunction;
      this.dataDescription = data.fileName;
      this.checkboxesForm = this.createCheckboxes();
      this.form = this.createMainForm();
    } else {
      throw Error('No dialog data provided');
    }
  }

  protected exportToCSV() {
    const checkedCheckboxes = this.checkboxesForm.getRawValue();
    const checkedHeaderKeys = Object.keys(checkedCheckboxes).filter(
      (key) => checkedCheckboxes[key],
    );
    const exportFiltered = this.form.get('filtered')?.value ?? false;
    const dataToExport = exportFiltered ? this.filteredData : this.allData;

    this.csvExportService
      .downloadCSV(
        dataToExport,
        this.dataDescription,
        exportFiltered,
        checkedHeaderKeys,
        this.headers,
        this.customCSVMapFunction,
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private createCheckboxes(): CheckboxesFormGroup {
    const checkboxes: CheckboxesFormGroup = this.formBuilder.nonNullable.group({});
    this.headers.forEach((header) => {
      checkboxes.addControl(
        header.key,
        new FormControl(
          { value: header.checked ?? true, disabled: header.disabled },
          { nonNullable: true },
        ),
      );
    });

    return checkboxes;
  }

  private createMainForm(): FormGroup<CSVFormType> {
    const form = this.formBuilder.group({
      filtered: this.formBuilder.control({
        value: false,
        disabled: this.filteredData.length === 0,
      }),
      checkboxes: this.checkboxesForm,
    });

    return form;
  }
}
