<mat-form-field appearance="outline" class="!w-full pt-4">
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    @for (value of filteredOptions(); track value) {
      <mat-option [value]="value">
        {{ value }}
      </mat-option>
    }
  </mat-autocomplete>
  <mat-chip-grid #myChipGrid aria-label="Option selection">
    @for (option of selectedOptions(); track option) {
      <mat-chip [removable]="removable" (removed)="remove(option)">
        <span tmcTruncateTooltip> {{ option }} </span>
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    }
    <input
      #autocompleteInputField
      matInput
      [matAutocomplete]="auto"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder || ''"
      [matAutocomplete]="auto"
      [matChipInputFor]="myChipGrid"
      [(ngModel)]="currentValue"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>
</mat-form-field>
