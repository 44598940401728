import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'tmc-sidenav-layout',
  templateUrl: './sidenav-layout.component.html',
  styleUrls: ['./sidenav-layout.component.scss'],
  imports: [CommonModule, MatSidenavModule, MatDividerModule, MatIconModule, MatButtonModule],
  standalone: true,
})
export class SidenavLayoutComponent {
  private static readonly OVERLAY_CONTAINER_ELEMENT = 'tmos-overlay-container-element';
  private static readonly OVERLAY_CONTAINER_WRAPPER = 'tmos-el-element-wrapper';
  private static readonly OVERLAY_CONTAINER = '.cdk-overlay-container';

  @Output()
  layoutChanged = new EventEmitter<boolean>();

  isSidenavOpened = false;
  isSidenavFixed = false;
  isSidenavSticky = false;

  @HostListener('click', ['$event.target'])
  handleMatFieldClick(element: HTMLElement) {
    if (this.isSidenavFixed) {
      return;
    }

    const matFormElement = element.parentElement?.closest('mat-form-field');
    if (matFormElement) {
      const shadowDomSelector = `${SidenavLayoutComponent.OVERLAY_CONTAINER_ELEMENT} ${SidenavLayoutComponent.OVERLAY_CONTAINER_WRAPPER}`;
      const root = document.querySelector(shadowDomSelector)?.shadowRoot ?? document;
      const matOverlayContainer = root.querySelector(SidenavLayoutComponent.OVERLAY_CONTAINER);
      this.isSidenavSticky = !!matOverlayContainer?.children.length;
    }
  }

  @HostListener('document:click', ['$event.target'])
  handleOverlayBackClick(element: HTMLElement) {
    if (this.isSidenavFixed) {
      return;
    }

    const isMatOverlayContainer =
      (element.localName === SidenavLayoutComponent.OVERLAY_CONTAINER_WRAPPER &&
        element.parentElement?.localName === SidenavLayoutComponent.OVERLAY_CONTAINER_ELEMENT) ||
      element.parentElement?.closest(SidenavLayoutComponent.OVERLAY_CONTAINER);

    if (isMatOverlayContainer) {
      this.isSidenavSticky = false;
    }
  }

  mouseEnter(): void {
    this.isSidenavOpened = true;
  }

  mouseLeave(): void {
    if (this.isSidenavFixed) {
      return;
    }

    this.isSidenavOpened = false;
  }

  toggleLayout(): void {
    this.isSidenavFixed = !this.isSidenavFixed;

    this.layoutChanged.emit(true);
  }
}
