import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResult } from '@tmc/fleet-core-api';
import { MachineModule } from './machine-module.model';

@Injectable({ providedIn: 'root' })
export class MachineModuleService {
  private readonly http = inject(HttpClient);
  getAll(): Promise<MachineModule[]> {
    const params = new HttpParams({
      fromObject: {
        _fields: 'id,name,children',
        type: 'machine',
      },
    });
    return lastValueFrom(
      this.http
        .get<PaginatedResult<MachineModule>>(`/fleet-admin/modules?${params.toString()}`)
        .pipe(map((result: PaginatedResult<MachineModule>) => result.items)),
    );
  }
}
