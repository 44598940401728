import { ErrorHandler, Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, switchMap } from 'rxjs';

@Injectable()
export class TmosErrorHandler implements ErrorHandler {
  private readonly translateService = inject(TranslateService);
  private readonly snackBarService = inject(MatSnackBar);

  handleError(error: unknown) {
    combineLatest([
      this.translateService.get('CORE.ERROR.MSG') as Observable<string>,
      this.translateService.get('CORE.ACTION.OK') as Observable<string>,
    ])
      .pipe(switchMap(([msg, ok]) => this.snackBarService.open(msg, ok, {}).onAction()))
      .subscribe();

    // Throw error to globally log error
    throw error;
  }
}
