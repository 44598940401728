<div class="flex w-full flex-col px-9 pb-9">
  <div class="flex flex-col pl-4">
    @for (option of filterOptions; track $index) {
      <div class="flex flex-col">
        <mat-checkbox
          #filterCategory
          color="primary"
          class="text-wrap"
          (change)="onCategoryChecked(option.key, $event.checked)"
        >
          {{ option.key }}
        </mat-checkbox>
        @if (filterCategory.checked) {
          <div class="flex flex-col pl-4">
            @for (subOption of option.filter; track $index) {
              <ng-container>
                <mat-checkbox
                  #checkbox
                  color="primary"
                  class="text-wrap"
                  [checked]="onInitChecked"
                  (change)="this.onFilterChange(option.key, subOption, $event.checked)"
                >
                  {{ formatTranslationText(subOption, option.key) | translate }}
                </mat-checkbox>
              </ng-container>
            }
          </div>
        }
      </div>
    }
  </div>
</div>
