import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to add prefix to strings inside array.
 */
@Pipe({
  name: 'prefix',
  standalone: true,
})
export class PrefixPipe implements PipeTransform {
  /**
   * Adds provided prefix to strings inside array.
   *
   * @param stringArray string array.
   * @param prefix string prefix that should be added to strings of given array.
   * @returns array of strings with added prefix.
   */
  transform(stringArray: string[], prefix: string): string[] {
    return stringArray.map((str) => prefix + str);
  }
}
