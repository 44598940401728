export const CUSTOM_ICONS: Record<string, string[]> = {
  machine_types: [
    'ballast_distribution',
    'catenary_building',
    'cleaning',
    'material_logistics',
    'measurement',
    'mobile_railwork',
    'stabilisation_compaction',
    'superstructure_track_maintenance',
    'tamping',
    'track_building',
  ],
};
