import { Injectable, inject } from '@angular/core';
import { Observable, Subscriber, map, retry, switchMap, timer } from 'rxjs';
import { CoreConfiguration } from '../configuration/core-configuration.model';
import { TokenService } from '../auth/token.service';
import { SECURE_URLS } from '../auth/secure-urls';

/**
 * Service for creating Server Set Events
 * For details on configuration see {@link CoreConfiguration}
 */
@Injectable({
  providedIn: 'root',
})
export class SseService {
  private static readonly AUTH_QUERY_PARAM_KEY = 'jwt';

  private readonly config = inject(CoreConfiguration);
  private readonly tokenService = inject(TokenService);

  /**
   * Create a new Server Set Event Stream of json objects
   * Current access token from is automatically attached.
   * Automatically retries if failed.
   * @returns stream of sse events
   */
  createSse<T>(url: string): Observable<T> {
    return this.getAuthenticatedURL(url).pipe(
      switchMap((eventSource) => this.mapEventSourceToObservable(eventSource)),
      retry({
        count: this.config.httpRetryCount,
        delay: () => timer(this.config.httpRetryTimeout),
      }),
      map((message) => JSON.parse(message) as T),
    );
  }

  private getAuthenticatedURL(url: string): Observable<string> {
    const urlBuilder = new URL(url, window.location.origin);

    return this.tokenService.currentAccessToken$.pipe(
      map((token) => {
        // check if the outgoing request matches one of the secureUrls
        if (SECURE_URLS.some((secureUrl) => url.startsWith(secureUrl))) {
          // Adding token as query parameter which should be resolved by toms gateway
          urlBuilder.searchParams.append(SseService.AUTH_QUERY_PARAM_KEY, `Bearer ${token}`);
        }
        return urlBuilder.toString();
      }),
    );
  }

  private mapEventSourceToObservable(url: string) {
    const eventSource = new EventSource(url);
    return new Observable((observer: Subscriber<string>) => {
      eventSource.onmessage = (event: MessageEvent<string>) => {
        observer.next(event.data);
      };
      eventSource.onerror = (event: Event) => {
        observer.error(event);
      };
      eventSource.addEventListener('disconnected', () => {
        eventSource.close();
        observer.complete();
      });
      return () => {
        eventSource.close();
      };
    });
  }
}
