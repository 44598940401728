<h2 mat-dialog-title>{{ 'COMPANY_MANAGEMENT.MANAGE_CHILDREN' | translate }}</h2>
<mat-dialog-content class="w-full overflow-hidden">
  @if (this.config.data?.url === 'manufacturers') {
    <div class="py-3">
      <mat-button-toggle-group
        appearance="legacy"
        color="primary"
        [(value)]="selectedScope"
        (change)="scopeChanged($event.value)"
      >
        <mat-button-toggle value="customer">
          {{ 'COMPANY_MANAGEMENT.CUSTOMERS' | translate }}
        </mat-button-toggle>
        <mat-button-toggle value="distributor">
          {{ 'COMPANY_MANAGEMENT.DISTRIBUTORS' | translate }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  }
  @if (this.selectedScope() === 'customer') {
    <tmc-text-select
      [options]="customers"
      [label]="'COMPANY_MANAGEMENT.CUSTOMERS' | translate"
      [preSelection]="null"
      (selected)="onSelect($event)"
    >
    </tmc-text-select>
  }
  @if (!(this.selectedScope() === 'customer')) {
    <tmc-text-select
      [options]="distributors"
      [label]="'COMPANY_MANAGEMENT.DISTRIBUTORS' | translate"
      [preSelection]="null"
      (selected)="onSelect($event)"
    >
    </tmc-text-select>
  }
  @if (!(this.selectedScope() === 'customer') && selection()) {
    <div class="box-border flex items-center justify-start">
      <mat-checkbox
        color="primary"
        [ngModel]="isPartner()"
        (change)="onPartnerChange($event.checked)"
      >
        {{ 'COMPANY_MANAGEMENT.PARTNER' | translate }}
      </mat-checkbox>

      @if (this.changePartner() && !this.isPartner()) {
        <mat-icon
          matTooltipPosition="after"
          aria-hidden="true"
          color="warn"
          [matTooltip]="
            'COMPANY_MANAGEMENT.PARTNER_WARNING'
              | translate
                : {
                    distributor: this.selection()?.label?.toUpperCase(),
                    manufacturer: this.company.name.toUpperCase(),
                  }
          "
          [matTooltipShowDelay]="delayValues.SHORT_DELAY"
          [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
        >
          report
        </mat-icon>
      }
    </div>
  }
</mat-dialog-content>

<mat-dialog-actions [align]="'end'">
  <button mat-raised-button color="primary" class="uppercase" (click)="this.dialogRef.close()">
    {{ 'COMMON.ACTIONS.CANCEL' | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    class="uppercase"
    [disabled]="this.selectionNotAllowed()"
    (click)="onSubmit()"
  >
    {{ 'COMMON.ACTIONS.CONFIRM' | translate }}
  </button>
</mat-dialog-actions>
