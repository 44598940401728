import { Component, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  filter,
  map,
  merge,
  shareReplay,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { TextSelectOption } from './text-select.model';

@Component({
  selector: 'tmc-text-select',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './text-select.component.html',
})
export class TextSelectComponent<T> {
  @ViewChild('autocompleteInputField')
  autocompleteInputField!: { nativeElement: HTMLInputElement };

  @ViewChild(MatAutocompleteTrigger)
  set trigger(val: MatAutocompleteTrigger) {
    this._trigger = val;
    this.trigger$.next(val);
  }
  get trigger() {
    return this._trigger;
  }
  private _trigger!: MatAutocompleteTrigger;
  private readonly trigger$ = new ReplaySubject<MatAutocompleteTrigger>(1);

  @Input()
  label: string | undefined;

  @Input({ required: true })
  set options(val: TextSelectOption<T>[] | null) {
    if (val === null) {
      return;
    }
    this.options$.next(val);
  }
  private readonly options$ = new BehaviorSubject<TextSelectOption<T>[]>([]);

  @Input()
  set preSelection(preSelectionLabel: string | null) {
    if (preSelectionLabel === null) {
      return;
    }
    this.preSelectionLabel$.next(preSelectionLabel);
  }
  private readonly preSelectionLabel$ = new Subject<string>();

  private readonly preSelectionEvent$ = combineLatest([
    this.options$.pipe(
      shareReplay(1),
      filter((options) => options?.length > 0),
    ),
    this.trigger$,
    this.preSelectionLabel$,
  ]).pipe(
    map(([options, trigger, preSelectionLabel]) => {
      const preselectedOption = options.find((option) => option.label === preSelectionLabel);
      if (!preselectedOption) {
        throw new Error('preSelection is not available in the options');
      }
      trigger.writeValue(preselectedOption);
      return preselectedOption;
    }),
  );

  private readonly inputValue$ = new BehaviorSubject<string | null>(null);
  handleInputChange(selectedLabel: string | null) {
    this.inputValue$.next(selectedLabel);
  }

  protected userSelectionEvents$ = new Subject<TextSelectOption<T>>();
  private readonly selectionEvents$ = merge(this.preSelectionEvent$, this.userSelectionEvents$);

  clearSelectionClicked$ = new Subject<boolean>();

  private readonly removeSelectionEvents$ = combineLatest([
    this.clearSelectionClicked$.pipe(startWith(true)),
    this.inputValue$.pipe(
      filter((val) => val === ''),
      startWith(''),
    ),
  ]).pipe(
    withLatestFrom(this.selectionEvents$),
    filter(([, currentSelection]) => currentSelection !== null),
    tap(() => {
      this.trigger.writeValue(null);
    }),
    map(() => null),
  );

  @Output()
  private readonly selected = merge(this.selectionEvents$, this.removeSelectionEvents$);

  protected filteredOptions$ = this.options$.pipe(
    switchMap((options) =>
      this.inputValue$.pipe(
        map((val) =>
          options.filter((option) => option.label.toLowerCase().includes(val?.toLowerCase() ?? '')),
        ),
      ),
    ),
  );

  protected inputFieldFormatter(val: TextSelectOption<T> | null): string {
    return val?.label ?? '';
  }
}
