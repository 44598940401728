import { Directive, ElementRef, HostListener, Renderer2, inject } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

/**
 * Directive to display a tooltip once text overflows in container and user hovers over it.
 * In case of overflow the tailwind "truncate" class gets applied which adds ellipsis.
 * For the tooltip text the content of the html element gets fetched
 */
@Directive({
  selector: '[tmcTruncateTooltip]',
  standalone: true,
  hostDirectives: [{ directive: MatTooltip }],
})
export class TruncateTooltipDirective {
  private readonly element: ElementRef<HTMLElement> = inject<ElementRef<HTMLElement>>(
    ElementRef<HTMLElement>,
  );
  private readonly renderer = inject(Renderer2);

  constructor(private readonly matTooltip: MatTooltip) {
    this.renderer.addClass(this.element.nativeElement, 'truncate');
  }

  @HostListener('mouseenter') onMouseEnter() {
    const { nativeElement } = this.element;
    if (nativeElement.offsetWidth < nativeElement.scrollWidth) {
      this.matTooltip.message = nativeElement.textContent ?? '';
      this.matTooltip.show();
    }
  }

  @HostListener('mouseleave') hideTooltip() {
    this.matTooltip.hide();
  }
}
