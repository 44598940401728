import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  computed,
  inject,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  Group,
  GroupService,
  User,
  UserService,
  FleetPermissionService,
} from '@tmc/mco-user-management-api';
import { ISelectBoxData, UpdateService } from '@tmc/fleet-core-api';
import { McoUserManagementListComponent } from '@tmc/mco-user-management-ui';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DialogService, SelectDialogComponent } from '@tmc/core-ui';

import {
  BreadcrumbComponent,
  InputDialogComponent,
  ProgressSpinnerComponent,
  TableSearchBoxComponent,
} from '@tmc/fleet-core-ui';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { EMPTY, Subject, combineLatest, filter, from, of, switchMap } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { RxjsUtils } from '@tmc/core-utils';
import { AdminAppUserDetailsService } from '@tmc/fleet-core-feature';
import { PermissionCalculationUtil } from '../utils/permission-calculation.util';

@Component({
  selector: 'tmc-fleet-user-management-users',
  standalone: true,
  imports: [
    CommonModule,
    McoUserManagementListComponent,
    TableSearchBoxComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonToggleModule,
    MatTabsModule,
    ProgressSpinnerComponent,
    BreadcrumbComponent,
    TranslateModule,
  ],
  providers: [UserService, GroupService, FleetPermissionService, UpdateService],
  templateUrl: './fleet-user-management-users.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetUserManagementUsersComponent implements OnInit {
  protected readonly adminAppUserDetail = inject(AdminAppUserDetailsService);
  private readonly userService = inject(UserService);
  private readonly groupService = inject(GroupService);
  private readonly dialog = inject(MatDialog);
  private readonly dialogService = inject(DialogService);
  private readonly translate = inject(TranslateService);

  reload = new Subject<void>();
  allUsers = toSignal(
    this.reload.pipe(
      RxjsUtils.switchMapWithLoading(() =>
        from(
          this.userService
            .getAll()
            .then((users) => users.toSorted((a, b) => a.email.localeCompare(b.email)))
            .then((users) => users.map((user) => this.getGroupNames(user))),
        ),
      ),
    ),
  );
  allGroups = toSignal(
    this.reload.pipe(RxjsUtils.switchMapWithLoading(() => from(this.groupService.getAll()))),
    {
      initialValue: undefined,
    },
  );

  listFilter = signal<string>('');
  groups = computed(() => {
    if (this.allUsers()?.loading === false && this.allGroups()?.loading === false) {
      return this.allGroups()?.data;
    }
    return undefined;
  });

  filteredUsers = computed(() => {
    if (this.allUsers()?.loading === false) {
      return this.allUsers()?.data?.filter((user: User) =>
        user.email.toLowerCase().includes(this.listFilter().toLowerCase()),
      );
    }
    return undefined;
  });

  ngOnInit() {
    this.reload.next();
  }

  openCreateUserDialog(): void {
    const dialogRef = this.dialog.open(InputDialogComponent, {
      data: {
        title: 'COMMON.USER',
        inputField: {
          label: 'COMMON.EMAIL',
          validators: [
            {
              validatorFunction: Validators.required,
              validatorErrorMsg: 'USER_MANAGEMENT.INPUT_DIALOG.EMAIL_REQUIRED',
              validatorErrorKey: 'required',
            },
            {
              validatorFunction: Validators.email,
              validatorErrorMsg: 'USER_MANAGEMENT.INPUT_DIALOG.INVALID_EMAIL',
              validatorErrorKey: 'email',
            },
          ],
        },
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        switchMap((result: string) => {
          if (result !== undefined) {
            return this.userService
              .create(result)
              .then(() =>
                this.dialogService.openSnackBar({ message: 'USER_MANAGEMENT.USERS.USER_CREATED' }),
              );
          }
          return of(null);
        }),
      )
      .subscribe((response) => {
        if (response !== null) this.reload.next();
      });
  }

  deleteUser(user: User) {
    this.translate
      .get('PATH.USER_MGMT.ARE_YOU_SURE_TO_REMOVE_PROFILE', { element: user.email })
      .pipe(
        switchMap((msg: string) => this.openConfirmDialog(user.email, msg).afterClosed()),
        filter((accept) => accept ?? false),
        switchMap(() => this.userService.delete(user.email)),
      )
      .subscribe({
        next: () => {
          this.dialogService.openSnackBar({ message: 'USER_MANAGEMENT.USERS.USER_REMOVED' });
        },
        error: () => {
          this.dialogService.openSnackBar({ message: 'ERROR.ERROR_OCCURRED' });
        },
      });
  }
  openConfirmDialog(title: string, message: string) {
    const data = {
      title,
      confirmationQuestion: message,
      translate: true,
    };
    return this.dialogService.openConfirmationDialog(data);
  }

  openAddGroupToUser(user: User) {
    const groupOptions = PermissionCalculationUtil.getValidGroupsForUser(
      user,
      this.allGroups()?.data ?? [],
    );
    combineLatest([
      this.translate.get('USER_MANAGEMENT.GROUPS.GROUP'),
      this.translate.get('USER_MANAGEMENT.GROUPS.SELECT_GROUP'),
    ])
      .pipe(
        switchMap(([label, title]) =>
          this.dialog
            .open(SelectDialogComponent, {
              data: {
                title,
                label,
                preSelection: null,
                options: groupOptions.map((group) => ({ label: group.name, value: group })),
              },
            })
            .afterClosed(),
        ),
        switchMap((group: ISelectBoxData<Group> | undefined) => {
          if (group) {
            return this.updateUsersAndGroups(user, group.value);
          }
          return EMPTY;
        }),
      )
      .subscribe({
        next: () =>
          this.dialogService.openSnackBar({
            message: 'USER_MANAGEMENT.USERS.USER_ADDED_SUCCESSFULLY',
          }),
        error: () => this.dialogService.openSnackBar({ message: 'ERROR.ERROR_OCCURRED' }),
      });
  }

  async removeGroupFromUser(user: User, groupId: number) {
    await this.userService.updateGroupIdToUser(user.email, groupId, false).then(() => {
      this.reload.next();
      return true;
    });
  }

  openRemoveGroupFromUser(user: User, group: Group) {
    this.translate
      .get('COMMON.DIALOGS.ARE_YOU_SURE_TO_REMOVE', { element: user.originalEmail })
      .pipe(
        switchMap((message: string) => this.openConfirmDialog(group.name, message).afterClosed()),
        filter((accept) => accept ?? false),
        switchMap(() => this.removeGroupFromUser(user, group.id)),
      )
      .subscribe({
        next: () =>
          this.dialogService.openSnackBar({
            message: 'USER_MANAGEMENT.GROUPS.GROUP_REMOVED_FROM_USER',
          }),
        error: () => this.dialogService.openSnackBar({ message: 'ERROR.ERROR_OCCURRED' }),
      });
  }

  openUpdateUserAdmin(user: User, checked: boolean) {
    if (checked) {
      this.translate
        .get('USER_MANAGEMENT.USERS.ARE_YOU_SURE_TO_SET_AS_ADMIN', { element: user.email })
        .pipe(
          switchMap((message: string) => this.openConfirmDialog(user.email, message).afterClosed()),
          filter((accept) => accept ?? false),
          switchMap(() => this.setUserAsAdmin(user, checked)),
        )
        .subscribe({
          next: () =>
            this.dialogService.openSnackBar({ message: 'USER_MANAGEMENT.USERS.USER_SET_AS_ADMIN' }),
          error: () => this.dialogService.openSnackBar({ message: 'ERROR.ERROR_OCCURRED' }),
        });
    } else {
      this.translate
        .get('USER_MANAGEMENT.USERS.ARE_YOU_SURE_TO_REMOVE_AS_ADMIN', { element: user.email })
        .pipe(
          switchMap((message: string) => this.openConfirmDialog(user.email, message).afterClosed()),
          filter((accept) => accept ?? false),
          switchMap(() => this.setUserAsAdmin(user, checked)),
        )
        .subscribe({
          next: () =>
            this.dialogService.openSnackBar({
              message: 'USER_MANAGEMENT.USERS.USER_REMOVED_AS_ADMIN',
            }),
          error: () => this.dialogService.openSnackBar({ message: 'ERROR.ERROR_OCCURRED' }),
        });
    }
  }

  private async setUserAsAdmin(user: User, isAdmin: boolean): Promise<void> {
    const dictionaryToUpdate: { email: string; registry_modify: boolean; activate: boolean } = {
      email: user.email,
      registry_modify: true,
      activate: isAdmin,
    };
    await this.userService.update(user.email, dictionaryToUpdate).then(() => {
      this.reload.next();
      return true;
    });
  }

  private async updateUsersAndGroups(user: User, group: Group): Promise<void> {
    await this.userService.updateGroupToUser(user.email, group, true).then(() => {
      this.reload.next();
      return true;
    });
  }

  private getGroupNames(user: User): User {
    return {
      ...user,
      groups:
        user.groups
          .map((group) => this.getGroup(group.id))
          .filter((group) => group?.id !== undefined) ?? [],
    };
  }
  private getGroup(groupId: number): Group {
    return this.allGroups()?.data?.find((g: Group) => g.id === groupId) ?? ({} as Group);
  }
}
