import {
  HttpClient,
  HttpInterceptorFn,
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  EnvironmentProviders,
  LOCALE_ID,
  importProvidersFrom,
  makeEnvironmentProviders,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeNl from '@angular/common/locales/nl';
import localeEn from '@angular/common/locales/en';
import localePl from '@angular/common/locales/pl';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CoreConfiguration } from './configuration/core-configuration.model';
import { DEFAULT_CONFIGURATION } from './configuration/core-configuration.default';
import { retryInterceptor } from './http/retry.interceptor';

/**
 * Creates environment providers of tmos core library:
 *
 * - Angular BrowserAnimationModule
 * - {@link HttpClient}, with provided access token and automatic retries
 * - {@link CoreConfiguration}, from config with default values for undefined values
 * - {@link LOCALE_ID}
 * - {@link TranslateModule}
 * - {@link AuthModule}
 *
 * @param config: partial library config
 * @param additionalInterceptors additional functional http interceptors to provide
 * @returns environment providers of tmos core library
 */
export function provideTmosCore(
  config: Partial<CoreConfiguration>,
  additionalInterceptors: HttpInterceptorFn[],
): EnvironmentProviders {
  // Merging themes separately, because of shallow merge of configurations
  const themes = config.themes
    ? { ...config.themes, ...DEFAULT_CONFIGURATION.themes }
    : DEFAULT_CONFIGURATION.themes;
  const mergedConfig = { ...DEFAULT_CONFIGURATION, ...config, ...{ themes } };

  const withTranslate = config.translationPath !== undefined;

  return makeEnvironmentProviders([
    importProvidersFrom(...(withTranslate ? [getTranslateModule(mergedConfig)] : [])),
    provideAnimations(),
    provideHttpClient(withInterceptors([retryInterceptor, ...additionalInterceptors]), withFetch()),
    {
      provide: LOCALE_ID,
      useValue: mergedConfig.locale ?? mergedConfig.defaultLocale,
    },
    {
      provide: CoreConfiguration,
      useValue: mergedConfig,
    },
    ...(withTranslate ? [provideTranslateInitializer(mergedConfig)] : []),
  ]);
}

function getTranslateModule(config: CoreConfiguration) {
  return TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: (http: HttpClient) =>
        new TranslateHttpLoader(http, config.translationPath, '.json'),
      deps: [HttpClient],
    },
  });
}

function provideTranslateInitializer(config: CoreConfiguration) {
  return {
    provide: APP_INITIALIZER,
    useFactory: (translate: TranslateService) => () => {
      const locales: Record<string, unknown> = {
        de: localeDe,
        en: localeEn,
        fr: localeFr,
        it: localeIt,
        ja: localeJa,
        nl: localeNl,
        pl: localePl,
      };
      const locale = config.locale ?? config.defaultLocale;
      if (config.defaultLocale in locales) {
        registerLocaleData(locales[config.defaultLocale], config.defaultLocale);
      }
      if (locale !== config.defaultLocale && locale in locales) {
        registerLocaleData(locales[locale], locale);
      }
      translate.setDefaultLang(config.defaultLocale);
      translate.use(locale);
    },
    deps: [TranslateService],
    multi: true,
  };
}
