import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

/**
 * Directive to properly handle dragging over event on an element.
 *
 * Normally, dragenter and dragleave events are fired not only when the cursor is moved inside/outside the element
 * but also when it is moved to one of the child element, therefore the event is fired wrongly.
 *
 * To avoid this, the counter solution is used:
 * - {@link counter} increments/decrements when the cursor enters/leaves the element and its child elements.
 * - {@link dragOver} event is fired with value true when {@link counter} equals 1 (the cursor enters the element).
 * - {@link dragOver} event is fired with value false when {@link counter} equals 0 (the cursor leaves the element).
 * - {@link dragOver} event is fired with value false when onDrop event happens ({@link counter} is set to 0).
 *
 * Use {@link dragOver} event to listen if the cursor enters/leaves the element.
 */
@Directive({
  selector: '[tmcDragOver]',
  standalone: true,
})
export class DragOverDirective {
  @Output() dragOver = new EventEmitter<boolean>();

  counter = 0;

  @HostListener('dragenter', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.counter += 1;
    if (this.counter === 1) {
      this.dragOver.emit(true);
    }
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.counter -= 1;
    if (this.counter === 0) {
      this.dragOver.emit(false);
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.counter = 0;
    this.dragOver.emit(false);
  }
}
