import { Component, inject, input, computed, ChangeDetectionStrategy, signal } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  IAccessLevel,
  ILimitation,
  VpnFormData,
  VpnProfile,
  VpnProfileInput,
  VpnProfileService,
} from '@tmc/mco-user-management-api';
import { DialogService } from '@tmc/core-ui';
import { Company } from '@tmc/fleet-core-api';
import { HttpErrorResponse } from '@angular/common/http';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'tmc-vpn-profile-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './vpn-profile-form.component.html',
  imports: [
    MatCheckboxModule,
    MatFormFieldModule,
    TranslateModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    MatCardModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
  ],
})
export class VpnProfileFormComponent {
  private readonly dialogService = inject(DialogService);
  private readonly vpnProfileService = inject(VpnProfileService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly router = inject(Router);

  manufacturers = input<Company[] | null>();
  distributors = input<Company[] | null>();

  profile = input<VpnProfile | null>();
  form!: FormGroup<{
    name: FormControl<string | null>;
    accessLevels: FormArray<FormControl<boolean | null>>;
    limitations: FormArray<FormControl<unknown>>;
  }>;

  accessLevels: IAccessLevel[] = [
    { name: 'COMMON.VENDOR', selected: false, id: 1 },
    { name: 'COMMON.MANUFACTURER', selected: false, id: 2 },
    { name: 'COMMON.DISTRIBUTOR', selected: false, id: 3 },
  ];
  accessLevelsSignal = signal<boolean[]>([]);
  get getAccessLevels() {
    const accessLevelsControl = this.form.get('accessLevels') as FormArray | null;
    if (accessLevelsControl) {
      return accessLevelsControl.controls as FormControl[];
    }
    return [];
  }

  companies = computed(() => {
    const comp: Company[] = [];
    const manufacturers = this.manufacturers();
    const distributors = this.distributors();
    const accessLevels = this.accessLevelsSignal();
    if (accessLevels[1] && manufacturers) {
      comp.push(...manufacturers);
    }
    if (accessLevels[2] && distributors) {
      comp.push(...distributors);
    }
    return comp.sort((a, b) => (a.name < b.name ? -1 : 1));
  });

  get accessLevelsValid() {
    return ((this.form.get('accessLevels') as FormArray).controls as FormControl[]).some(
      (control) => control.value,
    );
  }

  get limitations(): FormArray {
    return this.form.get('limitations') as FormArray;
  }
  currentPath = '';
  constructor() {
    if (this.router.url.includes('new')) {
      this.currentPath = 'new';
    }
    this.initForm();
    toObservable(this.profile).subscribe(() => {
      this.setProfile();
      if (this.currentPath === 'new') {
        this.addLimitation();
      }
    });
  }

  addLimitation() {
    this.getLimitations().push(this.newLimitation()); // create empty limitation
    this.form.get('accessLevels')!.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  deleteLimitation(index: number) {
    this.getLimitations().removeAt(index);
  }

  private initForm() {
    const pattern = /^\s*\S+(?:\s+\S+)*\s*$/;
    this.form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(pattern)]],
      accessLevels: this.buildAccessLevels(),
      limitations: this.formBuilder.array([]),
    });
    this.initFormSubscribers();
  }

  private initFormSubscribers() {
    this.form.get('accessLevels')!.valueChanges.subscribe((accessLevels: (boolean | null)[]) => {
      this.accessLevelsSignal.set(accessLevels as boolean[]);
      this.getLimitations().enable();
      if (!accessLevels[0] && !accessLevels[1] && !accessLevels[2]) {
        this.getLimitations().disable();
      } else if (accessLevels[0] && !accessLevels[1] && !accessLevels[2]) {
        this.getLimitations().controls.forEach((limitation: AbstractControl) => {
          limitation.get('company')!.setValue('TMC');
          limitation.get('company')!.disable();
        });
      } else if (!accessLevels[0] && accessLevels[1] && !accessLevels[2]) {
        this.getLimitations().controls.forEach((limitation: AbstractControl) => {
          limitation.get('company')!.setValue(limitation.get('manufacturer')?.value);
          limitation.get('company')!.disable();
        });
      } else {
        this.getLimitations().enable();
      }
    });

    this.form.get('limitations')!.valueChanges.subscribe(() => {
      const accessLevels = this.form?.get('accessLevels')?.value;
      if (accessLevels && !accessLevels[0] && accessLevels[1] && !accessLevels[2]) {
        this.getLimitations().controls.forEach((limitation: AbstractControl) => {
          limitation
            .get('company')!
            .patchValue(limitation.get('manufacturer')!.value, { emitEvent: false });
        });
      }
    });

    this.form.get('accessLevels')!.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  private setProfile() {
    this.form.patchValue({
      name: this.profile()?.name ?? '',
      accessLevels:
        this.profile()?.accessLevels?.map((level: IAccessLevel) => level.selected) ?? [],
    });

    const limitationsForm = this.getLimitations();
    limitationsForm.clear();
    this.profile()?.limitations?.forEach((limitation: ILimitation) => {
      limitationsForm?.push(
        this.newLimitation(limitation.company, limitation.manufacturer, limitation.ips),
      );
    });
    this.form?.get('accessLevels')?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  private buildAccessLevels() {
    let arr;
    if (this.profile()?.accessLevels) {
      arr = this.profile()?.accessLevels.map((level: IAccessLevel) =>
        this.formBuilder.control(level.selected),
      );
    } else {
      arr = this.accessLevels.map((level: IAccessLevel) =>
        this.formBuilder.control(level.selected),
      );
    }

    return this.formBuilder.array(arr ?? []);
  }

  private parseLimitations(limitations: any): ILimitation[] {
    limitations.forEach((limitation: any) => {
      limitation.ips = limitation.ips.split(',');
    });
    return limitations;
  }

  private newLimitation(company = '', manufacturer = '', ips: string[] = []) {
    return this.formBuilder.group({
      company,
      manufacturer,
      ips: [ips, [this.cidrOrIpListValidator()]],
    });
  }

  private cidrOrIpListValidator(): ValidatorFn {
    return (control: AbstractControl): Record<string, any> | null => {
      const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
      const cidrRegex = /^((\d{1,3}\.){3}\d{1,3})\/(\d|[12]\d|3[0-2])$/;
      if (!control.value || control.value.length === 0) return null;
      const values = control.value?.split(',').map((value: string) => value.trim());

      const isValid = values.every((value: string) => ipRegex.test(value) || cidrRegex.test(value));

      if (!isValid) {
        return { invalidCidrOrIpList: { value: control.value } };
      }

      return null;
    };
  }

  protected getLimitations(): FormArray {
    return this.form?.get('limitations') as FormArray;
  }

  saveConfiguration(obj: VpnFormData) {
    const accessLevelsString: string[] = [];
    if (obj.accessLevels[0]) {
      accessLevelsString.push('vendor');
    }
    if (obj.accessLevels[1]) {
      accessLevelsString.push('manufacturer');
    }
    if (obj.accessLevels[2]) {
      accessLevelsString.push('distributor');
    }

    const vpnProfile: VpnProfileInput = {
      name: obj.name?.trim(),
      access_levels: accessLevelsString,
      limitations: this.parseLimitations(obj.limitations),
    };

    if (obj.id !== undefined) vpnProfile.id = obj.id;

    this.vpnProfileService
      .update(vpnProfile, this.profile() ? this.profile()?.id : -1)
      .then(() =>
        this.router.navigate(['/fleet-user-management/user-management/vpn-profiles'], {
          state: { vpnProfilesSelected: true },
        }),
      )
      .then(() =>
        this.dialogService.openSnackBar({
          message: 'USER_MANAGEMENT.VPN_PROFILES.VPN_PROFILE_FORM.PROFILE_CREATED',
        }),
      )
      .catch((error: HttpErrorResponse) => {
        const errorData: { msg: string } = error.error as { msg: string };
        this.dialogService.openSnackBar({
          message: `USER_MANAGEMENT.ERROR_DIALOGS.${errorData.msg}`,
        });
      });
  }
}
