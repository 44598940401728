import { User, Group } from '@tmc/mco-user-management-api';

export class PermissionCalculationUtil {
  static getValidGroupsForUser(user: User, groups: Group[]): Group[] {
    const userGroups = user.groups?.map((ug) => ug.id);
    return groups
      .filter((group) => this.checkUserAndGroupCompatible(user, group))
      .filter((g) => !userGroups.includes(g.id));
  }

  static getValidUsersForGroup(group: Group, users: User[]): User[] {
    const groupAdminEmails = group.users?.map((u) => u.email);
    return users
      .filter((user) => this.checkUserAndGroupCompatible(user, group))
      .filter((u) => !groupAdminEmails.includes(u.email));
  }

  private static checkUserAndGroupCompatible(user: User, group: Group): boolean {
    return (
      user.company.externalId === group.company.externalId && // User and Group Company of same company
      !(user.userGroups?.map((g) => g.id).includes(group.id) ?? false) // User is not allready added to Group
    ); // Check user and group permissions are compatible
  }
}
