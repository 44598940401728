<div>
  <div class="grid min-h-72 grid-cols-2 gap-4">
    @if (filteredTilesData()?.length === 0 && view() === undefined) {
      <tmc-progress-spinner></tmc-progress-spinner>
    }
    @for (tile of filteredTilesData(); track tile) {
      <tmc-tile
        class="col-span-1 h-72 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1"
        [view]="view()"
        [tileData]="tile"
      ></tmc-tile>
    }
  </div>
</div>
