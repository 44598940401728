import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CompanyConfigurationFormComponent } from '@tmc/fleet-company-management-ui';
import { FleetCompanyManagementLandingComponent } from './fleet-company-management-landing/fleet-company-management-landing.component';
import { FleetCompanyManagementListComponent } from './fleet-company-management-list/fleet-company-management-list.component';

const fleetCompanyManagementRoutes: Routes = [
  {
    path: '',
    component: FleetCompanyManagementLandingComponent,
    data: { title: 'Company Landing', subtitle: 'Company Landing' },
  },
  {
    path: 'manufacturers',
    component: FleetCompanyManagementListComponent,
    data: { title: 'Manufacturers Listing Title', subtitle: 'Manufacturers Listing Subtitle' },
  },
  {
    path: 'distributors',
    component: FleetCompanyManagementListComponent,
    data: { title: 'Distributors Listing Title', subtitle: 'Distributors Listing Subtitle' },
  },
  {
    path: 'customers',
    component: FleetCompanyManagementListComponent,
    data: { title: 'Customers Listing Title', subtitle: 'Customers Listing Subtitle' },
  },
  {
    path: 'manufacturers/new',
    component: CompanyConfigurationFormComponent,
  },
  {
    path: 'manufacturers/:external_id',
    component: CompanyConfigurationFormComponent,
  },
  {
    path: 'customers/new',
    component: CompanyConfigurationFormComponent,
  },
  {
    path: 'customers/:external_id',
    component: CompanyConfigurationFormComponent,
  },
  {
    path: 'distributors/new',
    component: CompanyConfigurationFormComponent,
  },
  {
    path: 'distributors/:external_id',
    component: CompanyConfigurationFormComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(fleetCompanyManagementRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class FleetCompanyManagementModule {}
