import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  inject,
  signal,
  computed,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogService, SelectDialogComponent } from '@tmc/core-ui';
import { UpdateService, ISelectBoxData } from '@tmc/fleet-core-api';
import {
  BreadcrumbComponent,
  TableSearchBoxComponent,
  ProgressSpinnerComponent,
  InputDialogComponent,
} from '@tmc/fleet-core-ui';
import {
  GroupService,
  FleetPermissionService,
  User,
  Group,
  UserService,
} from '@tmc/mco-user-management-api';
import { GroupsListComponent } from '@tmc/mco-user-management-ui';
import { EMPTY, Subject, combineLatest, filter, from, of, switchMap } from 'rxjs';
import { RxjsUtils } from '@tmc/core-utils';
import { PermissionCalculationUtil } from '../utils/permission-calculation.util';

@Component({
  selector: 'tmc-fleet-user-management-groups',
  standalone: true,
  providers: [UserService, GroupService, UpdateService, FleetPermissionService],
  templateUrl: './fleet-user-management-groups.component.html',
  imports: [
    CommonModule,
    GroupsListComponent,
    BreadcrumbComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonToggleModule,
    MatTabsModule,
    TableSearchBoxComponent,
    TranslateModule,
    ProgressSpinnerComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetUserManagementGroupsComponent implements OnInit {
  private readonly groupService = inject(GroupService);
  private readonly userService = inject(UserService);
  private readonly dialog = inject(MatDialog);
  private readonly route = inject(ActivatedRoute);
  private readonly dialogService = inject(DialogService);
  private readonly translate = inject(TranslateService);
  private readonly layerPath = this.route.routeConfig?.path ?? '';
  reload = new Subject<void>();
  allUsers = toSignal(
    this.reload.pipe(
      RxjsUtils.switchMapWithLoading(() =>
        from(
          this.userService
            .getAll()
            .then((users: User[]) =>
              users.toSorted((a: User, b: User) => a.email.localeCompare(b.email)),
            ),
        ),
      ),
    ),
  );
  allGroups = toSignal(
    this.reload.pipe(RxjsUtils.switchMapWithLoading(() => from(this.groupService.getAll()))),
    {
      initialValue: undefined,
    },
  );

  groups = computed(() => {
    if (this.allGroups() === undefined) {
      return undefined;
    }
    if (this.layerPath === 'remote-features-groups') {
      return this.allGroups()?.data?.filter((group: Group) => group.isRemoteFeatureGroup);
    }
    return this.allGroups()?.data?.filter((group: Group) => !group.isRemoteFeatureGroup);
  });
  filteredGroups = computed(() => this.groups()?.filter((group: Group) => this.filterGroup(group)));
  listFilter = signal<string>('');

  ngOnInit() {
    this.reload.next();
  }

  openCreateGroupDialog(): void {
    const dialogRef = this.dialog.open(InputDialogComponent, {
      data: {
        title: 'USER_MANAGEMENT.GROUPS.GROUP',
        inputField: {
          label: 'COMMON.NAME',
          validators: [
            {
              validatorFunction: Validators.required,
              validatorErrorMsg: 'USER_MANAGEMENT.INPUT_DIALOG.NAME_REQUIRED',
              validatorErrorKey: 'required',
            },
          ],
        },
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        switchMap((result: string) => {
          if (result !== undefined) {
            return this.createGroup(result)
              .then(() =>
                this.dialogService.openSnackBar({
                  message: 'USER_MANAGEMENT.GROUPS.GROUP_CREATED',
                }),
              )
              .catch(() => {
                this.dialogService.openSnackBar({ message: 'ERROR.ERROR_OCCURRED' });
              });
          }
          return of(null);
        }),
      )
      .subscribe((response) => {
        if (response !== null) {
          this.reload.next();
        }
      });
  }

  openConfirmDialog(title: string, message: string) {
    const data = {
      title,
      confirmationQuestion: message,
      translate: true,
    };
    return this.dialogService.openConfirmationDialog(data);
  }

  openRemoveUserFromGroup(group: Group, user: User) {
    this.translate
      .get('COMMON.DIALOGS.ARE_YOU_SURE_TO_REMOVE', { element: user.originalEmail })
      .pipe(
        switchMap((message: string) => this.openConfirmDialog(group.name, message).afterClosed()),
        filter((accept) => accept ?? false),
        switchMap(() => this.removeUserEmailFromGroup(group, user.email)),
      )
      .subscribe({
        error: () => this.dialogService.openSnackBar({ message: 'ERROR.ERROR_OCCURRED' }),
      });
  }

  async removeUserEmailFromGroup(group: Group, userEmail: string) {
    await this.updateUserEmailFromGroup(group, userEmail, false);
  }

  deleteGroup(group: Group) {
    this.translate
      .get('PATH.USER_MGMT.ARE_YOU_SURE_TO_REMOVE_PROFILE', { element: group.name })
      .pipe(
        switchMap((message: string) => this.openConfirmDialog(group.name, message).afterClosed()),
        filter((accept) => accept ?? false),
        switchMap(() => this.removeGroup(group.id)),
      )
      .subscribe({
        next: () =>
          this.dialogService.openSnackBar({ message: 'USER_MANAGEMENT.GROUPS.GROUP_REMOVED' }),
        error: () => this.dialogService.openSnackBar({ message: 'ERROR.ERROR_OCCURRED' }),
      });
  }

  openAddUserToGroup(group: Group) {
    const groupOptions = PermissionCalculationUtil.getValidUsersForGroup(
      group,
      this.allUsers()?.data ?? [],
    );

    combineLatest([
      this.translate.get('USER_MANAGEMENT.USERS.USER'),
      this.translate.get('USER_MANAGEMENT.GROUPS.SELECT_USER'),
    ])
      .pipe(
        switchMap(([label, title]) =>
          this.dialog
            .open(SelectDialogComponent, {
              data: {
                title,
                label,
                preSelection: null,
                options: groupOptions.map((user) => ({ label: user.email, value: user })),
              },
            })
            .afterClosed(),
        ),
        switchMap((user: ISelectBoxData<User> | undefined) => {
          if (user) {
            return this.updateUserEmailFromGroup(group, user.value.email, true);
          }
          return EMPTY;
        }),
      )
      .subscribe({
        error: () => this.dialogService.openSnackBar({ message: 'ERROR.ERROR_OCCURRED' }),
      });
  }

  private async removeGroup(groupId: number) {
    await this.groupService.delete(groupId).then(() => {
      this.reload.next();
      return undefined;
    });
    await this.groupService.delete(groupId).then(() => {
      this.reload.next();
      return undefined;
    });
  }

  private async updateUserEmailFromGroup(group: Group, userEmail: string, add: boolean) {
    await this.userService.updateGroupToUser(userEmail, group, add).then(() => {
      this.reload.next();
      return undefined;
    });
    await this.userService.updateGroupToUser(userEmail, group, add).then(() => {
      this.reload.next();
      return undefined;
    });
  }

  private filterGroup(group: Group) {
    const filterValue = this.listFilter().toLowerCase();
    return (
      (group.companyExternalId?.toLowerCase().includes(filterValue) ?? false) ||
      group.name?.toLowerCase().includes(filterValue) ||
      group.companyName?.toLowerCase().includes(filterValue)
    );
  }

  private async createGroup(result: string) {
    await this.groupService.create(result, this.layerPath === 'remote-features-groups').then(() => {
      this.reload.next();
      return undefined;
    });
  }
}
