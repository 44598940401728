import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilsService } from '@tmc/fleet-core-utils';
import { PaginatedResult } from '../../models/paginated-result.model';
import { FileType } from './file-type.model';

@Injectable({ providedIn: 'root' })
export class FileTypeService {
  private readonly http = inject(HttpClient);
  private readonly utilsService = inject(UtilsService);

  getAll(): Promise<FileType[]> {
    return lastValueFrom(
      this.http
        .get<PaginatedResult<FileType>>(`/fleet-admin/fileTypes`)
        .pipe(map((result: PaginatedResult<FileType>) => result.items)),
    );
  }
}
