import { hasValue } from '../type/type.utils';
import { Range } from './range.model';

export class RangeUtils {
  /**
   * returns the length of a range
   * @param range
   * @returns
   */
  static toLength(range: Range<number>): number {
    return Math.abs(range.end - range.start);
  }

  /**
   * @param range to be checked
   * @returns true if start of range is bigger then end
   */
  static isInverted(range: Range<number>): boolean {
    return range.start > range.end;
  }

  /**
   * @param range to be corrected
   * @returns range with smaller start then end
   */
  static correct(range: Range<number>): Range<number> {
    if (RangeUtils.isInverted(range)) return { start: range.end, end: range.start };
    return range;
  }

  /**
   * Checks if a point is in a given range.
   * @param range to be checked in
   * @param point to be checked
   * @returns return 0 if point is in, -1 if smaller and 1 if bigger
   */
  static encompasses(range: Range<number>, point: number): 1 | 0 | -1 {
    const cRange = RangeUtils.correct(range);
    if (point < cRange.start) return -1;
    if (point > cRange.end) return 1;
    return 0;
  }

  /**
   * Clamp a number within a range
   * @param number to be clamped
   * @param boundaries that constraints the number
   * @returns number which is constrained to the range
   */
  static clampNumber(number: number, boundaries: Range<number>) {
    const cBoundaries = RangeUtils.correct(boundaries);
    if (number < cBoundaries.start) return cBoundaries.start;
    if (number > cBoundaries.end) return cBoundaries.end;
    return number;
  }

  /**
   * Clamp a range within a range
   *
   * @param range range to constrain
   * @param boundaries that contraint the
   * @returns range where start and end is constrained to the range
   */
  static clampRange(range: Range<number>, boundaries: Range<number>) {
    const cRange = RangeUtils.correct(range);
    return {
      start: RangeUtils.clampNumber(cRange.start, boundaries),
      end: RangeUtils.clampNumber(cRange.end, boundaries),
    };
  }

  /**
   * Clamps a range if it exceeds a specific length
   * @param range to be clamped
   * @param length maximum length of the range
   * @param inverted if true the start will kept, else the end will be kept
   * @returns clamped range
   */
  static clampRangeByLength(range: Range<number>, length: number, inverted?: boolean) {
    const cRange = RangeUtils.correct(range);
    if (inverted === true) {
      return RangeUtils.clampRange(cRange, { start: cRange.start, end: cRange.start + length });
    }
    return RangeUtils.clampRange(cRange, { start: cRange.end - length, end: cRange.end });
  }

  /**
   * Get distance between two ranges
   *
   * @param range1
   * @param range2
   * @returns distance between range1 end and range2 start
   */
  static distance(range1: Range<number>, range2: Range<number>): number {
    const r1 = RangeUtils.correct(range1);
    const r2 = RangeUtils.correct(range2);

    if (r1.start > r2.end) {
      return r1.start - r2.end;
    }
    if (r1.end < r2.start) {
      return r2.start - r1.end;
    }
    return 0;
  }

  /**
   * Extends range in both directions
   * @param range to be extended
   * @param offset the range should be extended with
   * @param inverted if undefined half offset on both sides, if true on start, if false on the end
   * @returns extended range
   */
  static extend(range: Range<number>, offset: number, inverted?: boolean) {
    const cRange = RangeUtils.correct(range);
    if (inverted === false) {
      return { start: cRange.start, end: cRange.end + offset };
    }
    if (inverted === true) {
      return { start: cRange.start - offset, end: cRange.end };
    }
    return { start: cRange.start - offset / 2, end: cRange.end + offset / 2 };
  }

  /**
   * Calculate a range which is extended from a point
   *
   * @param range range to extend
   * @param point start point of the extensions
   * @param orientation to specify the orientation of the start point
   * @returns a range if the range extends the point otherwise null
   */
  static calculateExtension(
    range: Range<number>,
    point: number,
    orientation: keyof Range<number>,
  ): Range<number> | null {
    const isEncompassed = RangeUtils.encompasses(range, point);
    if (
      (orientation === 'start' && isEncompassed < 0) ||
      (orientation === 'end' && isEncompassed > 0)
    ) {
      return null;
    }
    const endPoint = range[orientation];
    if (point === endPoint) return null;
    return RangeUtils.correct({ start: point, end: endPoint });
  }

  static calculateExtensions(range: Range<number>, extendingRange: Range<number>): Range<number>[] {
    return [
      RangeUtils.calculateExtension(extendingRange, range.start, 'start'),
      RangeUtils.calculateExtension(extendingRange, range.end, 'end'),
    ].filter(hasValue);
  }

  /**
   * Calculates the min and max of ranges
   * @param ranges all ranges to be checked
   * @returns Min and max values in ranges, for a empty list null
   */
  static extrema(ranges: (Range<number | undefined> | undefined)[]): Range<number> | null {
    const starts = ranges.map((range) => range?.start);
    const ends = ranges.map((range) => range?.end);
    const values = [...starts, ...ends].filter(hasValue);
    if (values.length === 0) return null;
    return {
      start: Math.min(...values),
      end: Math.max(...values),
    };
  }

  static fromPercentage(boundaries: Range<number>, viewPercentage: Range<number>): Range<number> {
    const length = RangeUtils.toLength(boundaries);
    return {
      start: boundaries.start + (length * viewPercentage.start) / 100,
      end: boundaries.start + (length * viewPercentage.end) / 100,
    };
  }

  static eq<T>(a?: Range<T>, b?: Range<T>) {
    return a?.start === b?.start && a?.end === b?.end;
  }
}
