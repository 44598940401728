<div class="max-h-table flex flex-col">
  <div class="relative my-5 grow overflow-y-auto">
    <mat-table
      class="w-full table-fixed"
      matSort
      multiTemplateDataRows
      aria-label="List of machines"
      [dataSource]="dataSource"
    >
      <!-- Id Column -->
      <ng-container matColumnDef="displayId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
          'COMMON.TABLE_HEADERS.DISPLAY_ID' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let machine"
          ><span tmcTruncateTooltip>{{ machine.displayId }}</span></mat-cell
        >
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
          'COMMON.TABLE_HEADERS.NAME' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let machine"
          ><span tmcTruncateTooltip>{{ machine.name }}</span></mat-cell
        >
      </ng-container>
      <!--Region Column-->
      <ng-container matColumnDef="region.name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
          'COMMON.TABLE_HEADERS.REGION' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let machine">{{ machine.region.name }}</mat-cell>
      </ng-container>

      <!--Manufacturer Column-->
      <ng-container matColumnDef="manufacturer.name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
          'COMMON.TABLE_HEADERS.MANUFACTURER' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let machine">{{ machine.manufacturer?.name || '--' }}</mat-cell>
      </ng-container>

      <!--Distributor Column -->
      <ng-container matColumnDef="distributor">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
          'COMMON.TABLE_HEADERS.DISTRIBUTOR' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let machine">{{ machine.distributor?.name || '--' }}</mat-cell>
      </ng-container>

      <!--customers column-->
      <ng-container matColumnDef="customers">
        <mat-header-cell *matHeaderCellDef class="flex items-center justify-center">{{
          'COMMON.TABLE_HEADERS.CUSTOMERS' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let machine" class="flex items-center justify-center">
          @if (machine.customers.length === 0) {
            <mat-icon
              class="cursor-not-allowed"
              [matTooltip]="'MACHINE_MANAGEMENT.TOOLTIPS.NO_CUSTOMERS' | translate"
              [matTooltipShowDelay]="delayValues.SHORT_DELAY"
              [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
              >highlight_off</mat-icon
            >
          } @else {
            <button
              class="flex items-center justify-center"
              (click)="showTableDialog(machine.customers, 'customers')"
            >
              <mat-icon
                [matTooltip]="'MACHINE_MANAGEMENT.TOOLTIPS.CUSTOMERS' | translate"
                [matTooltipShowDelay]="delayValues.SHORT_DELAY"
                [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
                >dns</mat-icon
              >
            </button>
          }
        </mat-cell>
      </ng-container>

      <!--status column-->
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
          'COMMON.TABLE_HEADERS.STATUS' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let machine">{{
          'MACHINE_MANAGEMENT.MACHINE_STATUS.' + (machine.status | uppercase) | translate
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="license">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
          'COMMON.TABLE_HEADERS.LICENSE' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let machine">{{
          machine.license !== null && machine.license !== undefined
            ? ('MACHINE_MANAGEMENT.MACHINE_LICENSE.' + (machine.license | uppercase) | translate)
            : '--'
        }}</mat-cell>
      </ng-container>

      <!--version column-->
      <ng-container matColumnDef="version">
        <mat-header-cell *matHeaderCellDef class="flex items-center justify-center">{{
          'COMMON.TABLE_HEADERS.VERSION' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let machine" class="flex items-center justify-center"
          >{{
            machine.currentVersion
              ? ('COMMON.CURRENT' | translate) + machine.currentVersion.name + ' '
              : ''
          }}
          @if (machine.supportedVersions?.length > 0) {
            <button
              class="flex items-center justify-center"
              (click)="showTableDialog(machine.supportedVersions, 'supports')"
            >
              <mat-icon
                [matTooltip]="'MACHINE_MANAGEMENT.TOOLTIPS.SUPPORTS' | translate"
                [matTooltipShowDelay]="delayValues.SHORT_DELAY"
                [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
                >history</mat-icon
              >
            </button>
          }
        </mat-cell>
      </ng-container>

      <!--modules column-->
      <ng-container matColumnDef="modules">
        <mat-header-cell *matHeaderCellDef class="flex items-center justify-center">{{
          'COMMON.TABLE_HEADERS.MODULES' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let machine" class="flex items-center justify-center">
          @if (machine.modules.length > 0) {
            <button
              class="flex items-center justify-center"
              (click)="showTableDialog(machine.modules, 'modules')"
            >
              <mat-icon
                [matTooltip]="'MACHINE_MANAGEMENT.TOOLTIPS.MODULES' | translate"
                [matTooltipShowDelay]="delayValues.SHORT_DELAY"
                [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
                >dns</mat-icon
              >
            </button>
          } @else {
            <mat-icon
              class="cursor-not-allowed"
              [matTooltip]="'MACHINE_MANAGEMENT.TOOLTIPS.NO_MODULES' | translate"
              [matTooltipShowDelay]="delayValues.SHORT_DELAY"
              [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
              >highlight_off</mat-icon
            >
          }
        </mat-cell>
      </ng-container>

      <!--actions column-->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="flex items-center justify-center">{{
          'COMMON.TABLE_HEADERS.ACTIONS' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let machine" class="flex items-center justify-center">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu #menu="matMenu" yPosition="below" xPosition="before" matMenuOverlapTrigger>
            @if (machine.manufacturer?.external_id === 'PTE' && machine.fileType?.type === 'MDC') {
              <button mat-menu-item>
                <mat-icon>sim_card</mat-icon>
                {{ 'MACHINE_MANAGEMENT.ACTIONS.SIM_CARD' | translate }}
              </button>
            }
            <button mat-menu-item>
              <mat-icon>screen_lock_landscape</mat-icon>
              {{ 'MACHINE_MANAGEMENT.ACTIONS.PERMISSION' | translate }}
            </button>
            <button mat-menu-item>
              <mat-icon>poll</mat-icon>
              {{ 'MACHINE_MANAGEMENT.ACTIONS.KPI' | translate }}
            </button>
            <button mat-menu-item (click)="openMachineForm(machine)">
              <mat-icon>settings</mat-icon>
              {{ 'MACHINE_MANAGEMENT.ACTIONS.SETTINGS' | translate }}
            </button>
            <button mat-menu-item>
              <mat-icon>restart_alt</mat-icon>
              {{ 'MACHINE_MANAGEMENT.ACTIONS.RESET_MACHINE' | translate }}
            </button>
            <button mat-menu-item class="text-red-700" (click)="deleteMachine(machine)">
              <mat-icon class="text-red-700">delete</mat-icon>
              {{ 'COMMON.ACTIONS.DELETE' | translate }}
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <!-- Row Definition -->
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <mat-paginator aria-label="Select page" [pageSize]="25" [pageSizeOptions]="pageSizeOptions">
  </mat-paginator>
</div>
