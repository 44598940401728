import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TmosComment } from '@tmc/core-api';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, filter, map, tap, withLatestFrom } from 'rxjs';
import { CommentComponent } from '../../comment/comment.component';
import { CommentDialogConfig } from './comment-dialog.model';

@Component({
  selector: 'tmc-comment-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatFormFieldModule,
    MatDialogTitle,
    MatDialogContent,
    MatInputModule,
    ReactiveFormsModule,
    MatDialogActions,
    MatButtonModule,
    FormsModule,
    MatIconModule,
    MatCardModule,
    MatTooltipModule,
    CommentComponent,
    MatProgressSpinnerModule,
  ],
  templateUrl: './comment-dialog.component.html',
})
export class CommentDialogComponent {
  private readonly dialogRef = inject(MatDialogRef<CommentDialogComponent>);

  protected data: CommentDialogConfig = inject(MAT_DIALOG_DATA);

  commentInput = new FormControl('');

  addBtnClicked$ = new Subject<true>();
  deleteBtnClicked$ = new Subject<string>();
  editBtnClicked$ = new Subject<TmosComment>();

  commentAdded$ = this.addBtnClicked$.pipe(
    withLatestFrom(this.commentInput.valueChanges),
    map(([, comment]) => comment),
    filter((comment): comment is string => comment !== null),
    tap(() => {
      this.commentInput.setValue('', { emitEvent: false });
    }),
  );

  closeDialog(): void {
    this.dialogRef.close();
  }
}
