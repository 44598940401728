import { LOCALE_ID, Pipe, PipeTransform, inject } from '@angular/core';

@Pipe({
  name: 'numericalValue',
  standalone: true,
})
export class NumericalValuePipe implements PipeTransform {
  private readonly locale = inject(LOCALE_ID);

  transform(value: unknown, unit: NumberFormatUnit | null = null) {
    return isNumber(value)
      ? new Intl.NumberFormat(this.locale, {
          style: unit ? 'unit' : 'decimal',
          unit: unit ?? undefined,
          maximumFractionDigits: 3,
        }).format(value)
      : null;
  }
}

// If needed, can be extended with identifiers specified here:
// https://tc39.es/ecma402/#sec-issanctionedsingleunitidentifier
type NumberFormatUnit = 'meter' | 'second';

function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}
