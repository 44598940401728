import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom, map } from 'rxjs';
import { PaginatedResult } from '@tmc/fleet-core-api';
import { ScopeType } from './scope-type.model';

@Injectable()
export class ScopeTypeService {
  private readonly http = inject(HttpClient);
  getAll(): Promise<ScopeType[]> {
    const params = new HttpParams();
    params.set('_fields', 'type');
    return lastValueFrom(
      this.http
        .get<PaginatedResult<ScopeType>>(`/fleet-admin/scopeTypes`, { params })
        .pipe(map((data: PaginatedResult<ScopeType>) => data.items)),
    );
  }
}
