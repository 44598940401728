export type Coord = [x: number, y: number];

export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export enum Axis {
  X = 0,
  Y = 1,
}
