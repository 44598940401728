import { computed, untracked } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { assertInjector } from 'ngxtension/assert-injector';

/**
 * Function `toLazySignal()` is a proxy function that will call the original
 * `toSignal()` function when the returned signal is read for the first time.
 */
function toLazySignal(source, options) {
    const injector = assertInjector(toLazySignal, options?.injector);
    let s;
    return computed(() => {
        if (!s) {
            s = untracked(() => toSignal(source, { ...options, injector }));
        }
        return s();
    });
}

/**
 * Generated bundle index. Do not edit.
 */

export { toLazySignal };

