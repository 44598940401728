import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { TextSelectOption } from '../text-select/text-select.model';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogConfig } from './confirmation-dialog/confirmation-dialog.model';
import { SelectDialogComponent } from './select-dialog/select-dialog.component';
import { SelectDialogConfig } from './select-dialog/select-dialog.model';
import { CommentDialogComponent } from './comment-dialog/comment-dialog.component';
import { CommentDialogConfig } from './comment-dialog/comment-dialog.model';
import { ISnackBarDialogConfig } from './snackbar-dialog/snackbar.model';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  dialog = inject(MatDialog);
  snackBar = inject(MatSnackBar);
  translate = inject(TranslateService);

  openConfirmationDialog(config: ConfirmationDialogConfig) {
    return this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogConfig, boolean>(
      ConfirmationDialogComponent,
      {
        data: config,
      },
    );
  }

  openSelectDialog<T>(config: SelectDialogConfig<T>) {
    return this.dialog.open<
      SelectDialogComponent<T>,
      SelectDialogConfig<T>,
      TextSelectOption<T> | null
    >(SelectDialogComponent, {
      data: config,
    });
  }

  openCommentDialog(config: CommentDialogConfig) {
    return this.dialog.open<CommentDialogComponent>(CommentDialogComponent, {
      data: config,
      autoFocus: false,
      width: '500px',
    });
  }

  openSnackBar(data: ISnackBarDialogConfig): Observable<MatSnackBarDismiss> {
    const snackbar = this.snackBar.open(
      this.translate.instant(data.message) as string,
      this.translate.instant(data.acceptButton ?? 'COMMON.ACTIONS.OK') as string,
      {
        duration: data.duration ?? 2000,
      },
    );

    return snackbar.afterDismissed();
  }

  open<T>(
    component: ComponentType<T>,
    config?: MatDialogConfig,
    autoFocus?: boolean,
    width?: string,
  ): MatDialogRef<T> {
    return this.dialog.open(component, {
      data: config,
      autoFocus: autoFocus ?? false,
      width: width ?? '500px',
    });
  }
}
