import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import { PaginatedResult } from '@tmc/fleet-core-api';
import { VpnProfile, VpnProfileInput, VpnProfileSync } from './vpn-profile.model';
import { IAccessLevel } from './models/access-level.model';
import { ILimitation } from './models/limitation.model';

@Injectable()
export class VpnProfileService {
  private readonly http = inject(HttpClient);

  transform = (vpnProfile: VpnProfileSync): VpnProfile => ({
    id: vpnProfile.id,
    name: vpnProfile.name,
    limitations: this.getLimitations(vpnProfile),
    accessLevels: this.getAccessLevels(vpnProfile),
  });

  getAll(): Promise<VpnProfile[]> {
    return lastValueFrom(
      this.http
        .get<PaginatedResult<VpnProfile>>(`/fleet-admin/vpn-profiles`)
        .pipe(map((data: PaginatedResult<VpnProfile>) => data.items)),
    );
  }

  getOne(id: string): Promise<VpnProfile> {
    return firstValueFrom(this.http.get<VpnProfile>(`/fleet-admin/vpn-profiles/${id}`));
  }

  update(profile: VpnProfileInput, id = -1): Promise<VpnProfile> {
    const url = id > 0 ? `/fleet-admin/vpn-profiles/${id.toString()}` : `/fleet-admin/vpn-profiles`;
    const method = id > 0 ? 'put' : 'post';

    return firstValueFrom(this.http.request<VpnProfile>(method, url, { body: profile }));
  }

  deleteVpnProfile(id: number): Promise<void> {
    return firstValueFrom(
      this.http.delete(`/fleet-admin/vpn-profiles/${id.toString()}`).pipe(map(() => undefined)),
    );
  }

  getLimitations(profile: VpnProfileSync): ILimitation[] {
    const limitations: ILimitation[] = [];
    profile.limitations.forEach((limitation: ILimitation) => {
      limitations.push({
        company: limitation.company,
        manufacturer: limitation.manufacturer,
        ips: limitation.ips.join(',') as unknown as string[],
      } as ILimitation);
    });
    return limitations;
  }

  getAccessLevels(profile: VpnProfileSync): IAccessLevel[] {
    return [
      { name: 'COMMON.VENDOR', selected: profile.isVendor, id: 1 },
      { name: 'COMMON.MANUFACTURER', selected: profile.isManufacturer, id: 2 },
      { name: 'COMMON.DISTRIBUTOR', selected: profile.isDistributor, id: 3 },
    ];
  }
}
