import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from '@tmc/fleet-core-ui';
import { Tile } from '@tmc/fleet-core-api';
import { AdminAppUserDetailsService } from '@tmc/fleet-core-feature';
import { toSignal } from '@angular/core/rxjs-interop';
import { hasValue } from '@tmc/core-utils';
import { filter, map } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-fleet-machine-management-landing',
  standalone: true,
  imports: [CommonModule, LandingPageComponent],
  templateUrl: './fleet-machine-management-landing.component.html',
})
export class FleetMachineManagementLandingComponent {
  private readonly adminAppUserDetail = inject(AdminAppUserDetailsService);
  tilesData: Tile[] = [
    {
      heading: 'MACHINE_MANAGEMENT.LANDING.HEADING.MACHINES',
      description: 'MACHINE_MANAGEMENT.LANDING.DESCRIPTION',
      actionText: 'MACHINE_MANAGEMENT.LANDING.ACTION_TEXT.CREATE_EDIT_MACHINES',
      alternativeActionText: 'MACHINE_MANAGEMENT.LANDING.ACTION_TEXT.SEE_MACHINES',
      route: 'machines',
      logo: 'settings',
      views: [
        { name: 'vendor', canCreate: true },
        { name: 'manufacturer', canCreate: true },
        { name: 'distributor', canCreate: false },
        { name: 'customer', canCreate: false },
      ],
    },
  ];
  view = toSignal(
    this.adminAppUserDetail.details$.pipe(
      filter(hasValue),
      map((details) => AdminAppUserDetailsService.toView(details.company)),
    ),
  );
}
