<div class="flex items-center justify-between">
  <h1 mat-dialog-title>{{ 'COMMENTS' | translate }}</h1>
  <button
    mat-icon-button
    color="primary"
    matTooltip="{{ 'CLOSE' | translate }}"
    (click)="closeDialog()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

@if (data.comments$ | async; as comments) {
  <div mat-dialog-content class="!max-h-80 !max-w-full !overflow-y-auto !p-sm">
    @for (comment of comments; track comment.id) {
      <tmc-comment
        [comment]="comment"
        (commentDeleted)="deleteBtnClicked$.next($event)"
        (commentEdited)="editBtnClicked$.next($event)"
      ></tmc-comment>
    }
  </div>
}
@if ((data.comments$ | async) === null) {
  <div class="flex justify-center">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
}

<form class="flex items-center">
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="!flex-1 !p-sm">
    <input
      matInput
      placeholder="{{ 'ENTER_NEW_COMMENT' | translate }}"
      [formControl]="commentInput"
    />
  </mat-form-field>
  <button
    type="submit"
    class="!shrink-0"
    mat-icon-button
    color="primary"
    [disabled]="!commentInput.getRawValue()"
    matTooltip="{{ 'ADD_COMMENT' | translate }}"
    (click)="addBtnClicked$.next(true)"
  >
    <mat-icon matPrefix>send</mat-icon>
  </button>
</form>
