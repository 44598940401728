<h1 mat-dialog-title>
  {{ data.title | translate }} {{ 'PROTOCOL_LIST.CONFIRMATION_LABEL' | translate }}
</h1>
<div mat-dialog-content>
  <p>{{ data.confirmationQuestion | translate }}</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">
    {{ 'PROTOCOL_LIST.CONFIRMATION_ABORT_LABEL' | translate }}
  </button>
  <button mat-button cdkFocusInitial [mat-dialog-close]="true">
    {{ 'PROTOCOL_LIST.CONFIRMATION_ACCEPT_LABEL' | translate }}
  </button>
</div>
