// Deactivating any restricting rules as JSON.parse has only any types
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { parseJsonCamelCaseKeys } from './camel-case.util';

export function camelCaseKeysInterceptor(route: string): HttpInterceptorFn {
  return (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    if (request.responseType !== 'json' || !request.url.startsWith(route)) return next(request);
    // Request as text and convert to json manually
    const newRequest = request.clone({ responseType: 'text' });
    return next(newRequest).pipe(
      map((event) => {
        if (!(event instanceof HttpResponse) || typeof event.body !== 'string') return event;
        return event.clone({
          body: event.body !== '' ? parseJsonCamelCaseKeys(event.body) : {},
        });
      }),
    );
  };
}
