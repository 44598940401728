import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { Region } from './region.model';
import { PaginatedResult } from '../models/paginated-result.model';

@Injectable({ providedIn: 'root' })
export class RegionService {
  private readonly http = inject(HttpClient);

  getAll(): Promise<Region[]> {
    return lastValueFrom(
      this.http
        .get<PaginatedResult<Region>>(`/fleet-admin/regions`)
        .pipe(map((result: PaginatedResult<Region>) => result.items)),
    );
  }
}
