import { Component, input, ChangeDetectionStrategy, computed } from '@angular/core';
import { CommonModule, NgFor } from '@angular/common';
import { Tile } from '@tmc/fleet-core-api';
import { TileComponent } from '../tile/tile.component';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { TitleComponent } from '../title/title.component';
import { ProgressSpinnerComponent } from '../progress-spinner/progress-spinner.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-landing-page',
  standalone: true,
  imports: [
    CommonModule,
    TileComponent,
    BreadcrumbComponent,
    TitleComponent,
    NgFor,
    ProgressSpinnerComponent,
  ],
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss',
})
export class LandingPageComponent {
  view = input<string | undefined>();
  tilesData = input<Tile[]>();
  filteredTilesData = computed(() => {
    if (this.view() !== undefined && this.tilesData() !== undefined) {
      return this.tilesData()?.filter((tile) =>
        tile.views.map((v) => v.name).includes(this.view()?.toString() ?? ''),
      );
    }
    return [];
  });
}
