<div
  class="relative mb-5 flex size-48 flex-col items-center justify-center border border-dashed border-gray-300 p-4 text-center"
  [ngClass]="(photoSignedUrl$ | async) !== '' ? 'mt-10' : 'mt-5'"
>
  @if ((photoSignedUrl$ | async) !== '') {
    <ng-container class="relative">
      <button
        class="absolute -top-4 right-[-70px] z-20 flex size-8 items-center justify-center opacity-50 hover:rounded-full hover:bg-[#e5eff0] hover:text-primary"
        mat-icon-button
        (click)="
          fileInput.value = '';
          this.modifiedFormPhoto.emit(undefined);
          this.photoSignedUrl$.next('')
        "
      >
        <mat-icon class="absolute z-20">close</mat-icon>
      </button></ng-container
    >
    <img
      class="z-0 mx-auto block size-5/6 object-contain"
      alt=" 'COMMON.PHOTO' | translate "
      [src]="(photoSignedUrl$ | async) || photographData"
    />
  }
  <!-- no image -->
  @else {
    <div class="flex flex-col items-center gap-4">
      <mat-icon class="opacity-60">file_upload</mat-icon>
      <small class="mt-2">{{ 'FILE_UPLOAD.DROP_PICTURE' | translate }}</small>
      <button mat-raised-button color="primary" class="ml-2 w-full uppercase opacity-80">
        Browser
      </button>
    </div>
  }
  <input
    #fileInput
    class="absolute left-0 top-0 z-0 my-6 size-4/6 cursor-pointer opacity-0"
    type="file"
    accept="image/x-png,image/jpeg"
    (change)="setFileData($event)"
  />
</div>
<input class="absolute z-0" matInput formControlName="photo" [hidden]="true" />
