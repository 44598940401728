import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateObjectService } from '@tmc/core-utils';

@Pipe({
  name: 'formlyTranslate',
  standalone: true,
})
export class FormlyTranslatePipe implements PipeTransform {
  private readonly paths = [
    'props.label',
    'props.placeholder',
    'props.options.label',
    'props.options.placeholder',
    'props.options',
    'fieldGroup.props.label',
    'fieldGroup.props.placeholder',
    'fieldGroup.props.options.label',
  ];
  private readonly translateService = inject(TranslateObjectService);

  transform<T>(value: T) {
    return this.translateService.translateObjectPaths(value, this.paths) ?? value;
  }
}
