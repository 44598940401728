import { Injectable, inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { lastValueFrom, map } from 'rxjs';
import { Module, PaginatedResult } from '@tmc/fleet-core-api';
import { UtilsService } from '@tmc/fleet-core-utils';
import { Application } from './application.model';

@Injectable()
export class ApplicationService {
  private readonly http = inject(HttpClient);
  private readonly utilsService = inject(UtilsService);

  getAll(isRemoteFeatureGroup: boolean): Promise<Application[]> {
    return lastValueFrom(
      this.http
        .get<
          PaginatedResult<Application>
        >(`/fleet-admin/applications${isRemoteFeatureGroup ? '?is_remote_feature_group=1' : ''}`)
        .pipe(
          map((response: PaginatedResult<Application>) =>
            response.items.map(
              (application: Application) =>
                ({
                  ...application,
                  modules: this.getModulesFormatted(application.modules),
                }) as Application,
            ),
          ),
        ),
    );
  }

  private getModulesFormatted(modules: Module[]): Module[] {
    const formattedModules: Module[] = [];
    modules.forEach((module: Module) => {
      formattedModules.push({
        ...module,
        roles: module.roles,
      } as Module);
    });
    return formattedModules;
  }
}
