import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, delay, map } from 'rxjs';
import { TextSelectOption } from '../../text-select/text-select.model';
import { TextSelectComponent } from '../../text-select/text-select.component';
import { SelectDialogConfig } from './select-dialog.model';

@Component({
  selector: 'tmc-select-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TextSelectComponent,
    MatButtonModule,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    TranslateModule,
    MatDialogClose,
  ],
  templateUrl: './select-dialog.component.html',
})
export class SelectDialogComponent<T> {
  public data: SelectDialogConfig<T> = inject(MAT_DIALOG_DATA);
  private readonly dialogRef = inject(MatDialogRef<SelectDialogComponent<T>>);

  private readonly selection$ = new BehaviorSubject<TextSelectOption<T> | null>(null);

  protected acceptBtnDisabled$ = this.selection$.pipe(
    // add delay(0) to prevent angular "ExpressionChangedAfterItHasBeenCheckedError"
    // see https://blog.angular-university.io/angular-debugging/
    delay(0),
    // disable select button if either no selection or if no changes
    map((selection) => selection === null || selection.label === this.data.preSelection),
  );

  onNoClick(): void {
    this.dialogRef.close();
  }

  handleSelected(selection: TextSelectOption<T> | null) {
    this.selection$.next(selection);
  }

  closeDialog() {
    this.dialogRef.close(this.selection$.getValue());
  }
}
