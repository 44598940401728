import { Injectable } from '@angular/core';

@Injectable()
export abstract class CoreConfiguration {
  /**
   * Default local to use for translations if translation is not available in for local
   * @default: en
   */
  abstract defaultLocale: string;

  /**
   * Local to use in the application
   * @default: defaultLocale
   */
  abstract locale?: string;

  /**
   * Path to the translation json files (including the start of the file names until the language codes)
   */
  abstract translationPath?: string;

  /**
   * Number of retries for failed http requests
   * @default: 0
   */
  abstract httpRetryCount: number;

  /**
   * Time to wait for retrying failed requests in ms
   * @default: 0
   */
  abstract httpRetryTimeout: number;

  /**
   * Colors of the theme
   */
  abstract themes: Record<
    string,
    {
      primary: string;
      secondary: string;
    }
  >;

  /**
   * Typographies for different texts
   */
  abstract typographies: {
    header: Typography;
    subtitle: Typography;
    body: Typography;
    caption: Typography;
  };
}

export interface Typography {
  family: string;
  weight: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  size: number;
}

export type ThemeTypes = keyof CoreConfiguration['themes'];
export type ThemeColorTypes = keyof CoreConfiguration['themes'][string];
export type TypographyTypes = keyof CoreConfiguration['typographies'];
