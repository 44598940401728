import { Injectable, LOCALE_ID, inject } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { NaturalNumber } from '@tmc/core-utils';
import {
  CoreConfiguration,
  ThemeColorTypes,
  TypographyTypes,
} from '../configuration/core-configuration.model';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private static readonly BLACK = '#000000';
  private static readonly WHITE = '#ffffff';

  private readonly configuration = inject(CoreConfiguration);
  private readonly localeId = inject(LOCALE_ID);
  private readonly alphaFormatter = new Intl.NumberFormat(this.localeId, {
    minimumIntegerDigits: 2,
  });

  private readonly themeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('tmc');
  private readonly isDarkThemeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  readonly theme$ = this.themeSubject.asObservable();
  readonly isDarkTheme$ = this.isDarkThemeSubject.asObservable();
  readonly themeClass$ = combineLatest([this.themeSubject, this.isDarkThemeSubject]).pipe(
    map((arr) => this.getThemeClass(arr[0], arr[1])),
  );

  /**
   * Active theme.
   * Can be tmc or a value provided in the configuration.
   */
  set theme(theme: string) {
    if (!Object.keys(this.configuration.themes).includes(theme)) {
      throw Error('Invalid theme configured');
    }
    this.themeSubject.next(theme);
  }
  get theme(): string {
    return this.themeSubject.getValue();
  }

  /**
   * Active dark or light theme mode.
   */
  set isDarkTheme(isDarkTheme: boolean) {
    this.isDarkThemeSubject.next(isDarkTheme);
  }
  get isDarkTheme(): boolean {
    return this.isDarkThemeSubject.getValue();
  }

  getThemeColor(type: ThemeColorTypes, alpha?: NaturalNumber<0, 100>) {
    return this.optionalApplyAlpha(this.configuration.themes[this.theme][type], alpha);
  }

  getTextColor(alpha?: NaturalNumber<0, 100>) {
    return this.optionalApplyAlpha(
      this.isDarkTheme ? ThemeService.WHITE : ThemeService.BLACK,
      alpha,
    );
  }

  getBorderColor(alpha?: NaturalNumber<0, 100>) {
    return this.optionalApplyAlpha(
      this.isDarkTheme ? ThemeService.BLACK : ThemeService.WHITE,
      alpha,
    );
  }

  getTypography(type: TypographyTypes) {
    return this.configuration.typographies[type];
  }

  private optionalApplyAlpha(color: string, alpha?: NaturalNumber<0, 100>) {
    return alpha !== undefined ? color + this.alphaFormatter.format(alpha) : color;
  }

  private getThemeClass(theme: string, darkTheme: boolean) {
    if (theme === 'tmc' && !darkTheme) {
      return undefined;
    }
    return `theme-${theme}${darkTheme ? '-dark' : ''}`;
  }
}

// Extract values from Type
// e.g. type ExampleType = {a: 'value1'; b: 'value2'}; ValuesOfType<ExampleType> --> 'value1' | 'value2'
export type ValuesOfType<T> = T[keyof T];
