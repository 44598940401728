import { ChangeDetectionStrategy, Component, inject, Signal, signal } from '@angular/core';
import { VpnProfile, VpnProfileService } from '@tmc/mco-user-management-api';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { from } from 'rxjs';
import { VpnProfileFormComponent } from '@tmc/mco-user-management-ui';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '@tmc/fleet-core-api';
import { toSignal } from '@angular/core/rxjs-interop';
import { ProgressSpinnerComponent } from '@tmc/fleet-core-ui';

@Component({
  selector: 'tmc-vpn-profile-container',
  standalone: true,
  templateUrl: './vpn-profile-form-container.component.html',
  imports: [VpnProfileFormComponent, MatCardModule, CommonModule, ProgressSpinnerComponent],
  providers: [VpnProfileService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileContainerComponent {
  private readonly companyService = inject(CompanyService);
  private readonly profileService = inject(VpnProfileService);
  private readonly activatedRoute = inject(ActivatedRoute);
  profileId = this.activatedRoute.snapshot.paramMap.get('id') ?? '';
  profile!: Signal<VpnProfile>;

  manufacturers = toSignal(from(this.companyService.getAll('manufacturers')), {
    rejectErrors: true,
  });

  distributors = toSignal(from(this.companyService.getAll('distributors')), {
    rejectErrors: true,
  });
  constructor() {
    if (this.profileId === 'new') {
      this.profile = signal<VpnProfile>({ id: -1, name: '', accessLevels: [], limitations: [] });
    } else {
      this.profile = toSignal(from(this.profileService.getOne(this.profileId)), {
        rejectErrors: true,
        initialValue: {
          id: -1,
          name: '',
          accessLevels: [],
          limitations: [],
        },
      });
    }
  }
}
