<mat-sidenav-container
  class="sidenav-container"
  [ngClass]="{
    'sidenav-opened': isSidenavOpened,
    'sidenav-fixed': isSidenavFixed,
    'sidenav-sticky': isSidenavSticky,
  }"
>
  <mat-sidenav
    class="sidenav"
    mode="side"
    opened="true"
    (mouseenter)="mouseEnter()"
    (mouseleave)="mouseLeave()"
  >
    <div class="sidenav-arrow-icon cursor-pointer">
      <mat-icon color="primary">keyboard_arrow_right</mat-icon>
    </div>
    <div class="sidenav-content box-border">
      <div class="sidenav-header">
        <ng-content select="[sidenav-header]"></ng-content>
      </div>
      <div class="sidenav-scrollable-content">
        <ng-content select="[sidenav-body]"></ng-content>
      </div>
      <div class="sidenav-footer">
        <ng-content select="[sidenav-footer]"></ng-content>
        <button
          mat-icon-button
          class="sidenav-toggle-button"
          color="primary"
          [attr.aria-label]="isSidenavFixed ? 'SIDENAV.PERMANENT' : 'SIDENAV.AUTO_HIDE'"
          (click)="toggleLayout()"
        >
          <mat-icon>push_pin</mat-icon>
        </button>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-page-content">
    <div class="box-border">
      <ng-content select="[page-body]"></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
