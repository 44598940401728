@if (this.allUsers()?.loading === true) {
  <tmc-progress-spinner></tmc-progress-spinner>
} @else {
  <div class="box-border h-full">
    <div class="flex justify-end">
      <div class="my-sm flex">
        @if (
          this.adminAppUserDetail.view() === 'manufacturer' ||
          this.adminAppUserDetail.view() === 'vendor'
        ) {
          <button
            mat-button
            class="flex items-center text-base font-semibold text-primary"
            (click)="openCreateUserDialog()"
          >
            <mat-icon class="mr-1" matPrefix>add</mat-icon>
            {{ 'USER_MANAGEMENT.USERS.CREATE_NEW_USER' | translate }}
          </button>
        }
        <tmc-table-search-box [(filterCriteria)]="listFilter"></tmc-table-search-box>
      </div>
    </div>
  </div>

  @if (allUsers()?.data?.length === 0) {
    <div class="m-xl flex">
      <span>{{ 'USER_MANAGEMENT.USERS.NO_USERS' | translate }}</span>
    </div>
  } @else {
    <tmc-mco-user-management-list
      [users]="filteredUsers()"
      (openUpdateUserAdmin)="openUpdateUserAdmin($event.user, $event.checked)"
      (openAddGroupToUser)="openAddGroupToUser($event)"
      (openRemoveGroup)="openRemoveGroupFromUser($event.user, $event.group)"
      (removeGroupFromUser)="removeGroupFromUser($event.user, $event.groupId)"
      (deleteUserEvent)="deleteUser($event)"
    ></tmc-mco-user-management-list>
  }
}
