import { Injectable, inject } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable, map, shareReplay } from 'rxjs';

export type ScreenBreakPoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export const SCREEN_SIZES: Record<string, ScreenBreakPoint> = {
  '(min-width: 640px)': 'sm',
  '(min-width: 768px)': 'md',
  '(min-width: 1024px)': 'lg',
  '(min-width: 1280px)': 'xl',
  '(min-width: 1536px)': '2xl',
};

/**
 * Screensize Service provides the functionality to react in code to screensize changes
 * If subscribed to this service, on screen breakpoint changes an array gets published that contains
 * all currently active screen breakpoints that are defined in SCREEN_SIZES
 */
@Injectable({ providedIn: 'root' })
export class ScreenSizeService {
  private readonly breakpointObserver = inject(BreakpointObserver);
  private onBreakpointChanges?: Observable<ScreenBreakPoint[]>;

  subscribeToLayoutChanges(): Observable<ScreenBreakPoint[]> {
    if (!this.onBreakpointChanges) {
      this.onBreakpointChanges = this.breakpointObserver.observe(Object.keys(SCREEN_SIZES)).pipe(
        map((observeResponse) => this.parseBreakpointsResponse(observeResponse.breakpoints)),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
    }
    return this.onBreakpointChanges;
  }

  private parseBreakpointsResponse(breakpoints: Record<string, boolean>): ScreenBreakPoint[] {
    return Object.keys(breakpoints)
      .filter((breakpointKey) => breakpoints[breakpointKey])
      .map((breakpointKey) => SCREEN_SIZES[breakpointKey]);
  }
}
