<div class="flex h-screen items-center justify-center py-8">
  <div class="m-auto flex w-3/5 flex-col items-center justify-center">
    @if (!this.form || loading()) {
      <tmc-progress-spinner></tmc-progress-spinner>
    } @else {
      <mat-card class="mb-5 h-3/4 p-2">
        <form id="form" name="form" [formGroup]="form" (ngSubmit)="submit()">
          @if (fields | formlyTranslate | async; as fields) {
            <formly-form
              [model]="machine()"
              [fields]="fields"
              [options]="options"
              [form]="form"
            ></formly-form>
          }
          <mat-form-field class="m-0 flex w-full items-center justify-center">
            <tmc-picture-upload
              [formPhoto]="this.photo()"
              (modifiedFormPhoto)="photograph.set($event)"
              (photographFile)="setPhotographFile($event)"
            ></tmc-picture-upload>
          </mat-form-field>
        </form>
        <mat-card-actions class="items-center justify-end">
          <button mat-raised-button color="primary" class="uppercase" [routerLink]="['../']">
            {{ 'COMMON.ACTIONS.CANCEL' | translate }}
          </button>
          <button
            mat-raised-button
            color="primary"
            class="ml-2 uppercase"
            [disabled]="!this.form.valid || !this.form.dirty"
            (click)="submit()"
          >
            {{ 'COMMON.ACTIONS.CONFIRM' | translate }}
          </button>
        </mat-card-actions>
      </mat-card>
    }
  </div>
</div>
