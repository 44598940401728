import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, firstValueFrom, lastValueFrom, switchMap } from 'rxjs';
import { MachinePhotograph } from './machine-photograph.model';

@Injectable({
  providedIn: 'root',
})
export class MachinePhotographService {
  private readonly http = inject(HttpClient);

  public async pushPhotograph(
    machineId: string,
    regionCode: string,
    isCreated: boolean,
    photographFile?: File,
  ): Promise<string | undefined> {
    if (photographFile === undefined) {
      return Promise.resolve(undefined);
    }
    return this.getSignedURL(machineId, regionCode, photographFile, isCreated);
  }

  private getSignedURL(
    vehicleExternalId: string,
    awsRegion: string,
    file: File,
    isCreated: boolean,
  ): Promise<string> {
    const params = new HttpParams({
      fromObject: {
        contentType: file.type,
        externalId: vehicleExternalId,
        fileSize: file.size,
        fileExtension: file.name.split('.').pop() ?? '',
        isCreated,
      },
    });
    return lastValueFrom(
      this.http
        .get<MachinePhotograph>(
          `/fleet-admin/machines_photographs/${awsRegion}?${params.toString()}`,
        )
        .pipe(
          switchMap((photograph) =>
            this.putRawImage(photograph.signedUrl, file).then(() => photograph.signedUrl),
          ),
        ),
    );
  }

  private putRawImage(signedPath: string, file: File): Promise<string> {
    return firstValueFrom(this.http.put<string>(signedPath, file).pipe(catchError(() => 'error')));
  }
}
