import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  output,
  viewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Group, User } from '@tmc/mco-user-management-api';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  DELAY_VALUES,
  IListDialogConfig,
  ListDialogComponent,
  RemovableListDialogData,
} from '@tmc/fleet-core-ui';
import { Pagination } from '@tmc/fleet-core-api';
import { MatChipsModule } from '@angular/material/chips';
import { DialogService, TruncateTooltipDirective } from '@tmc/core-ui';

@Component({
  selector: 'tmc-mco-user-management-list',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSortModule,
    TranslateModule,
    MatTooltipModule,
    MatChipsModule,
    TruncateTooltipDirective,
  ],
  templateUrl: './mco-user-management-list.component.html',
  styleUrls: ['../../../../../fleet-core/ui/src/lib/styles/lists.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class McoUserManagementListComponent {
  dialogService = inject(DialogService);
  dataSource!: MatTableDataSource<User>;
  delayValues = DELAY_VALUES;
  pageSizeOptions = Pagination.PAGE_SIZE_OPTIONS;
  displayedColumns = [
    'email',
    'status',
    'groups',
    'generatedTime',
    'modifiedTime',
    'lastLoggedTime',
    'companyName',
    'registryAdmin',
    'ACTIONS',
  ];

  paginator = viewChild.required(MatPaginator);
  sort = viewChild.required(MatSort);
  users = input<User[] | undefined>();
  editUserEvent = output<User>();
  deleteUserEvent = output<User>();
  openRemoveGroup = output<{ user: User; group: Group }>();
  removeGroupFromUser = output<{ groupId: number; user: User }>();
  openAddGroupToUser = output<User>();
  openUpdateUserAdmin = output<{ user: User; checked: boolean }>();

  constructor() {
    effect(() => {
      if (this.users()) {
        this.dataSource = new MatTableDataSource(this.users());
        this.dataSource.sort = this.sort();
        this.dataSource.paginator = this.paginator();
        this.configureSorting();
      }
    });
  }

  private configureSorting() {
    this.dataSource.sortingDataAccessor = (item: User, header: string) => {
      if (header === 'registryAdmin') {
        return this.isAdmin(item) ? 1 : 0;
      }
      return String(item[header as keyof User]);
    };
  }

  editUser(user: User) {
    this.editUserEvent.emit(user);
  }

  updateUserAdmin(user: User) {
    this.openUpdateUserAdmin.emit({ user, checked: !this.isAdmin(user) });
  }

  isAdmin(user: User): boolean {
    const permissionAsRegistry = user.permissions?.find((item) => item.module === 'Registry');
    return permissionAsRegistry !== undefined;
  }

  deleteUser(user: User) {
    this.deleteUserEvent.emit(user);
  }

  addGroupToUser(user: User) {
    this.openAddGroupToUser.emit(user);
  }

  openRemoveGroupFromUser(user: User, group: Group) {
    this.openRemoveGroup.emit({ user, group });
  }

  openAllGroupsDialog(user: User) {
    const config: IListDialogConfig = {
      data: [
        {
          option: 'admins',
          list: user.groups.map((g: Group) => ({
            value: g.name,
            isRemovable: true,
          })),
        },
      ],
    };
    const dialogRef = this.createListDialog(config);
    dialogRef.afterClosed().subscribe((toBeRemovedItems: RemovableListDialogData[]) => {
      if (toBeRemovedItems?.length === 0) {
        return;
      }
      this.removeGroups(user, toBeRemovedItems);
    });
  }

  private removeGroups(user: User, toBeRemovedItems: RemovableListDialogData[]) {
    toBeRemovedItems[0]?.list.forEach((toBeRemovedItem: string) => {
      this.removeGroupFromUser.emit({ groupId: Number(toBeRemovedItem), user });
    });
  }

  private createListDialog(config: IListDialogConfig) {
    return this.dialogService.open(ListDialogComponent, config, config.autoFocus, config.minWidth);
  }
}
