<mat-dialog-content class="border-[10px]">
  <mat-toolbar class="flex" color="primary" fxFlex="65px">
    <span>{{ 'USER_MANAGEMENT.VPN_PROFILES.IP_RANGES' | translate }}</span>
    <span class="flex grow"></span>
    @if (isNotVendor) {
      <mat-icon
        class="text-yellow-500"
        matTooltip="{{ 'USER_MANAGEMENT.VPN_PROFILES.IP_RANGES_WARNING' | translate }}"
        >warning</mat-icon
      >
    }
  </mat-toolbar>

  <mat-table
    matSort
    fxFlex="700px"
    matSortActive="lastModificationTs"
    multiTemplateDataRows
    [dataSource]="dataSource"
  >
    <ng-container matColumnDef="company">
      <mat-header-cell
        *matHeaderCellDef
        class="border-collapse p-2 px-6 py-3 text-left leading-5"
        >{{ 'COMMON.COMPANY' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let configuration" class="truncate p-2 px-6 text-left">
        <span tmcTruncateTooltip>{{ configuration.company || '-' }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="manufacturer">
      <mat-header-cell
        *matHeaderCellDef
        class="border-collapse p-2 px-6 py-3 text-left leading-5"
        >{{ 'COMMON.MANUFACTURER' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let configuration" class="truncate p-2 px-6 text-left">
        <span tmcTruncateTooltip>{{ configuration.manufacturer || '-' }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="ips">
      <mat-header-cell
        *matHeaderCellDef
        fxFlex
        class="border-collapse p-2 px-6 py-3 text-left leading-5"
        >{{ 'USER_MANAGEMENT.VPN_PROFILES.IPS' | translate }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let configuration"
        fxFlex="300px"
        fxFlex
        class="truncate p-2 px-6 text-left"
      >
        <mat-chip-set>
          @for (ips of formatIPRanges(configuration.ips); track ips) {
            <mat-chip
              ><span tmcTruncateTooltip>{{ ips }}</span></mat-chip
            >
          }
        </mat-chip-set>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="copy">
      <mat-header-cell
        *matHeaderCellDef
        fxFlex="60px"
        class="border-collapse justify-center whitespace-nowrap p-2 px-6 py-3 text-center leading-5"
      >
      </mat-header-cell>
      <mat-cell
        *matCellDef="let configuration"
        fxFlex="60px"
        class="justify-center whitespace-nowrap text-center"
      >
        <button
          mat-icon-button
          class="form-view-mat-field"
          class="management-invisible-button"
          [cdkCopyToClipboard]="configuration.ips"
          (cdkCopyToClipboardCopied)="onClipboardCopy()"
        >
          <mat-icon
            class="fill-gray-500"
            role="img"
            aria-hidden="true"
            matTooltip="{{ 'COMMON.ACTIONS.COPY_CONTENT_TO_CLIPBOARD' | translate }}"
            [matTooltipShowDelay]="delayValues.SHORT_DELAY"
            [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
          >
            content_copy
          </mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="displayedColumns"
      class="list-view-header-row mat-header-row custom-padding"
    ></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="list-view-row element-row mat-row custom-padding cursor-pointer"
    ></mat-row>
  </mat-table>
  <div class="flex justify-end pb-3 pr-5">
    <mat-dialog-actions fxLayout="row" fxFlexFill>
      <button button mat-raised-button mat-dialog-close color="primary" class="uppercase">
        {{ 'COMMON.ACTIONS.OK' | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</mat-dialog-content>
