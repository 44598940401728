export class MachineStatus {
  private static readonly TRANSLATION_PATH = 'MACHINE_MANAGEMENT.MACHINE_STATUS';
  private static readonly statuses = ['disabled', 'enabled', 'paused'];
  static readonly machineStatuses = MachineStatus.statuses.map((status) => ({
    label: `${MachineStatus.TRANSLATION_PATH}.${status.toUpperCase()}`,
    value: status,
  }));

  constructor(
    public label: string,
    public value: string,
  ) {}
}
