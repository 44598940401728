import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to format size in bytes to an appropriate size unit.
 */
@Pipe({
  name: 'bytes',
  standalone: true,
})
export class BytesPipe implements PipeTransform {
  private static readonly SIZE_UNITS = ['B', 'KB', 'MB', 'GB'];

  /**
   * Convert size number in bytes to a proper size unit.
   *
   * @param sizeInBytes single number value, must be in bytes.
   * @param unit optional single string value of output unit, must be from [B, KB, MB, GB].
   * @returns string of converted value rounded with maximum 2 decimals and a size unit.
   * Possible result size units: [B, KB, MB, GB].
   */
  transform(sizeInBytes: number, unit?: string): string {
    let power: number;
    let newUnit: string;

    if (!unit) {
      power = Math.round(Math.log(sizeInBytes) / Math.log(1000));
      power = Math.min(power, BytesPipe.SIZE_UNITS.length - 1);
      newUnit = BytesPipe.SIZE_UNITS[power];
    } else {
      power = BytesPipe.SIZE_UNITS.findIndex((u) => u === unit);
      newUnit = unit;
    }

    const size = sizeInBytes / 1000 ** power;
    const formattedSize = Math.round(size * 100) / 100;

    return `${formattedSize} ${newUnit}`;
  }
}
