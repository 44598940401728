export class MachineCategory {
  private static readonly TRANSLATION_PATH = 'MACHINE_MANAGEMENT.MACHINE_CATEGORIES';
  private static readonly CATEGORIES = [
    'tamping',
    'ballast_distribution',
    'stabilisation_compaction',
    'cleaning',
    'leveling_improvement',
    'material_logistics',
    'track_building',
    'superstructure_track_maintenance',
  ];
  static readonly MACHINE_CATEGORIES = MachineCategory.CATEGORIES.map((category) => ({
    label: `${MachineCategory.TRANSLATION_PATH}.${category.toUpperCase()}`,
    value: category,
  }));

  constructor(
    public label: string,
    public value: string,
  ) {}
}
