import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResult } from '../models/paginated-result.model';
import { Language } from './language.model';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private readonly http = inject(HttpClient);

  getAll(): Promise<Language[]> {
    return lastValueFrom(
      this.http
        .get<PaginatedResult<Language>>(`/fleet-admin/languages`)
        .pipe(map((languages: PaginatedResult<Language>) => languages.items)),
    );
  }
}
