import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VpnProfileService } from '@tmc/mco-user-management-api';
import { CompanyService, MachineService } from '@tmc/fleet-core-api';
import { ProfileContainerComponent } from './fleet-user-management-vpn-profiles-form/vpn-profile-form-container.component';
import { FleetUserManagementLandingComponent } from './fleet-user-management-landing/fleet-user-management-landing.component';
import { FleetUserManagementUsersComponent } from './fleet-user-management-users/fleet-user-management-users.component';
import { FleetUserManagementGroupsComponent } from './fleet-user-management-groups/fleet-user-management-groups.component';
import { FleetUserManagementVpnProfilesComponent } from './fleet-user-management-vpn-profiles/fleet-user-management-vpn-profiles.component';
import { FleetUserManagementPermissionsComponent } from './fleet-user-management-permissions/fleet-user-management-permissions.component';

const fleetUserManagementRoutes: Routes = [
  {
    path: '',
    component: FleetUserManagementLandingComponent,
    data: { title: 'User Landing' },
  },
  {
    path: 'users',
    component: FleetUserManagementUsersComponent,
    data: { title: 'User Listing' },
  },
  {
    path: 'groups',
    component: FleetUserManagementGroupsComponent,
    data: { title: 'Group Listing' },
  },
  {
    path: 'remote-features-groups',
    component: FleetUserManagementGroupsComponent,
    data: {
      title: 'Remote Features Group Listing',
    },
  },
  {
    path: 'vpn-profiles',
    pathMatch: 'full',
    component: FleetUserManagementVpnProfilesComponent,
    data: { title: 'Vpn Profile Listing' },
  },
  {
    path: 'vpn-profiles/:id',
    component: ProfileContainerComponent,
    data: { title: 'VPN Profile Details' },
  },
  {
    path: 'users/:email',
    component: FleetUserManagementPermissionsComponent,
    data: { title: 'User Permissions List' },
  },
  {
    path: 'groups/:id',
    component: FleetUserManagementPermissionsComponent,
    data: { title: 'Group Permissions List' },
  },
  {
    path: 'remote-features-groups/:id',
    component: FleetUserManagementPermissionsComponent,
    data: {
      title: 'Remote Group Permissions List',
    },
  },
  {
    path: 'users/:email',
    component: FleetUserManagementPermissionsComponent,
    data: { title: 'Fleet Permissions Listing' },
  },
  {
    path: 'groups/:id',
    component: FleetUserManagementPermissionsComponent,
    data: { title: 'Group Permissions Listing' },
  },
  {
    path: 'remote-features-groups/:id',
    component: FleetUserManagementPermissionsComponent,
    data: {
      title: 'Remote Group Permissions Listing',
      subtitle: 'Permissions Listing',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(fleetUserManagementRoutes)],
  exports: [RouterModule],
  providers: [MachineService, CompanyService, VpnProfileService],
})
export class FleetUserManagementModule {}
