import { Component, inject } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, NgFor } from '@angular/common';
import { DELAY_VALUES } from '@tmc/fleet-core-ui';
import { DialogService, TruncateTooltipDirective } from '@tmc/core-ui';
import { CreateVpnProfileLimitationsDialogConfig, ILimitation } from '@tmc/mco-user-management-api';

@Component({
  selector: 'tmc-vpn-profile-limitations',
  standalone: true,
  templateUrl: './vpn-profile-limitations.component.html',
  imports: [
    MatDialogModule,
    MatToolbarModule,
    MatIconModule,
    MatTableModule,
    TranslateModule,
    MatTooltipModule,
    MatChipsModule,
    ClipboardModule,
    NgFor,
    CommonModule,
    TruncateTooltipDirective,
  ],
})
export class VpnProfileLimitationsComponent {
  private readonly dialogService = inject(DialogService);
  private readonly data: CreateVpnProfileLimitationsDialogConfig = inject(MAT_DIALOG_DATA);
  dataSource: ILimitation[] = [];
  isNotVendor: boolean;
  displayedColumns: string[] = ['company', 'manufacturer', 'ips', 'copy'];
  delayValues = DELAY_VALUES;

  constructor() {
    this.dataSource = this.data.limitations;
    this.isNotVendor = !this.data.isVendor;
  }

  formatIPRanges(ips: string): string[] {
    const allIPs = ips.split(',');

    let startIP = '';
    let endIP = '';
    const ranges: string[] = [];

    for (let i = 0; i < allIPs.length; i += 1) {
      const currentIP = allIPs[i];

      if (i === 0) {
        // Start a new range
        startIP = currentIP;
        endIP = currentIP;
      } else {
        // Continue the current range
        endIP = currentIP;
      }

      if (!this.isNextIP(allIPs[i], allIPs[i + 1])) {
        // End the current range and add it to the result
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        startIP !== endIP ? ranges.push(`${startIP} - ${endIP}`) : ranges.push(startIP);
        // Initialize values for new range
        startIP = allIPs[i + 1];
      }
    }
    return ranges;
  }

  private isNextIP(ip1: string, ip2?: string): boolean {
    const ip1Parts = ip1.split('.').map(Number);
    const ip2Parts = ip2?.split('.').map(Number) || [];

    for (let i = 0; i < 4; i += 1) {
      if (ip1Parts[i] !== ip2Parts[i]) {
        if (i !== 3 || ip2Parts[3] - ip1Parts[3] !== 1) {
          return false;
        }
      }
    }
    return true;
  }

  onClipboardCopy(): void {
    this.dialogService.openSnackBar({ message: 'COMMON.ACTIONS.COPIED_TO_CLIPBOARD' });
  }
}
