import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  computed,
  inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DialogService } from '@tmc/core-ui';
import { RxjsUtils } from '@tmc/core-utils';
import { CompanyListComponent } from '@tmc/fleet-company-management-ui';
import {
  CompanyService,
  RegionService,
  Company,
  CompanyAssociatedChildren,
  SubordinateCompanyData,
  SimpleCompany,
} from '@tmc/fleet-core-api';
import { AdminAppUserDetailsService } from '@tmc/fleet-core-feature';
import {
  BreadcrumbComponent,
  ProgressSpinnerComponent,
  TableSearchBoxComponent,
} from '@tmc/fleet-core-ui';
import { Subject, from } from 'rxjs';

@Component({
  selector: 'tmc-fleet-company-management-list',
  standalone: true,
  providers: [CompanyService],
  templateUrl: './fleet-company-management-list.component.html',
  imports: [
    CommonModule,
    BreadcrumbComponent,
    MatButtonToggleModule,
    TableSearchBoxComponent,
    MatTabsModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    TranslateModule,
    CompanyListComponent,
    ProgressSpinnerComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetCompanyManagementListComponent implements OnInit {
  private readonly dialogService = inject(DialogService);
  private readonly companyService = inject(CompanyService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly regionService = inject(RegionService);
  private readonly adminAppUserDetail = inject(AdminAppUserDetailsService);
  reload = new Subject<void>();
  layerPath = this.route.routeConfig?.path ?? '';
  listFilter = signal<string>('');
  allManufacturers = toSignal(
    this.reload.pipe(
      RxjsUtils.switchMapWithLoading(() => from(this.companyService.getAll(`manufacturers`))),
    ),
    {
      initialValue: undefined,
    },
  );

  allDistributors = toSignal(
    this.reload.pipe(
      RxjsUtils.switchMapWithLoading(() => from(this.companyService.getAll(`distributors`))),
    ),
    {
      initialValue: undefined,
    },
  );

  allCustomers = toSignal(
    this.reload.pipe(
      RxjsUtils.switchMapWithLoading(() => from(this.companyService.getAll(`customers`))),
    ),
    {
      initialValue: undefined,
    },
  );
  companies = computed(() => {
    if (this.layerPath === 'manufacturers' && this.allManufacturers()?.loading === false) {
      return this.allManufacturers()?.data;
    }
    if (this.layerPath === 'distributors' && this.allDistributors()?.loading === false) {
      return this.allDistributors()?.data;
    }
    if (this.layerPath === 'customers' && this.allCustomers()?.loading === false) {
      return this.allCustomers()?.data;
    }
    return undefined;
  });

  regions = toSignal(from(this.regionService.getAll()), {
    rejectErrors: true,
    initialValue: [],
  });
  view = this.adminAppUserDetail.view();

  areCompaniesNotAvailable = computed(
    () =>
      !this.companies() ||
      this.allCustomers()?.loading === true ||
      this.allDistributors()?.loading === true ||
      this.allManufacturers()?.loading === true,
  );
  filteredCompanies = computed(() =>
    this.companies()?.filter((company: Company) => this.filterCompanies(company)),
  );

  filterCompanies(company: Company) {
    // has to be separated because of "_"
    return (
      company.name.toLowerCase().includes(this.listFilter().toLowerCase()) ||
      company.externalId.toLowerCase().includes(this.listFilter().toLowerCase())
    );
  }

  ngOnInit() {
    this.reload.next();
  }

  openCreateNewCompany(): void {
    this.router
      .navigate(['./new'], {
        relativeTo: this.route,
        state: {
          regions: this.regions(),
          manufacturers: this.allManufacturers(),
          distributors: this.allDistributors(),
          customers: this.allCustomers(),
        },
      })
      .catch(() => {
        this.dialogService.openSnackBar({ message: 'COMMON.ERROR' });
      });
  }

  removeCompaniesFromDialog(toBeRemovedCompanies: CompanyAssociatedChildren[]) {
    toBeRemovedCompanies.forEach((set: CompanyAssociatedChildren) => {
      set.listChildrenIds?.forEach((child: { id: number; partners: boolean }) => {
        this.removeAssignedCompany(set.option, set.company, child.id, child.partners).catch(() => {
          this.dialogService.openSnackBar({ message: 'COMMON.ERROR' });
        });
      });
      return undefined;
    });
    return true;
  }

  async removeAssignedCompany(
    option: string,
    company: Company,
    childCompanyId: number,
    isChildPartner: boolean,
  ): Promise<void> {
    const query: SubordinateCompanyData = this.getUpdateQuery(
      'delete',
      childCompanyId,
      isChildPartner,
    );
    await this.companyService.edit(option, company.externalId, query).then(() => {
      this.reload.next();
      return undefined;
    });
  }

  async removeCompany(data: { option: string; companyId: number }): Promise<void> {
    await this.companyService.delete(data.option, data.companyId).then(() => {
      this.reload.next();
      return undefined;
    });
  }

  async updateCompany(event: { action: string; company: Company; child: SimpleCompany }) {
    const query: SubordinateCompanyData = this.getUpdateQuery(
      event.action,
      event.child.id,
      event.child.partners ?? false,
    );
    await this.companyService.edit(this.layerPath, event.company.externalId, query).then(() => {
      this.reload.next();
      return undefined;
    });
  }

  private getUpdateQuery(
    action: string,
    childId: number,
    partners: boolean,
  ): SubordinateCompanyData {
    return {
      action,
      child_id: childId,
      partners,
    };
  }
}
