<input
  matInput
  [matAutocomplete]="auto"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [placeholder]="props.placeholder || ''"
  [errorStateMatcher]="errorStateMatcher"
  [(ngModel)]="currentValue"
/>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
  @if (labels().length > 0) {
    @for (option of filteredOptions(); track option) {
      <mat-option [value]="option">
        {{ option.label }}
      </mat-option>
    }
  } @else {
    @for (option of filter | async; track option) {
      <mat-option [value]="option">
        {{ option }}
      </mat-option>
    }
  }
</mat-autocomplete>
