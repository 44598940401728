import { Component, signal, ChangeDetectionStrategy, computed, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogConfig,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TextSelectComponent, TextSelectOption } from '@tmc/core-ui';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { Company, SimpleCompany } from '@tmc/fleet-core-api';
import { DELAY_VALUES } from '@tmc/fleet-core-ui';
import { SubordinateModalData } from './subordinate-company.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-fleet-company-management-ui',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatButtonToggleModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    TextSelectComponent,
    MatCheckboxModule,
  ],
  templateUrl: './fleet-company-subordinate-dialog.component.html',
  styleUrl: './fleet-company-subordinate-dialog.component.scss',
})
export class FleetSubordinateCompanyComponent {
  private readonly translate = inject(TranslateService);
  protected readonly dialogRef = inject(MatDialogRef<FleetSubordinateCompanyComponent>);
  protected readonly config = inject(MAT_DIALOG_DATA) as MatDialogConfig<SubordinateModalData>;
  distributors!: { value: string; label: string }[];
  company: Company;
  companyDistributors: SimpleCompany[];
  companyCustomers: SimpleCompany[];
  customers: { value: string; label: string }[];
  isPartner = signal<boolean>(false);
  selection = signal<TextSelectOption<string> | null>(null);
  selectedScope = signal<string>('customer');

  changePartner = computed(() => {
    const partnerStatus = this.companyDistributors.find(
      (dist) => dist.id === Number(this.selection()?.value) && dist.partners,
    );
    return partnerStatus !== undefined;
  });
  selectionNotAllowed = computed(() => {
    const scope =
      this.selectedScope() === 'customer' ? this.companyCustomers : this.companyDistributors;
    const result = scope.find(
      (c: SimpleCompany) =>
        c.id === Number(this.selection()?.value) && c.partners === this.isPartner(),
    );
    if (result) {
      return true;
    }
    return false;
  });
  newPartner = computed(() => {
    if (this.selectedScope() === 'customer') {
      return true;
    }
    const result = this.companyDistributors.find(
      (c: SimpleCompany) => c.id === Number(this.selection()?.value),
    );
    if (result) {
      return false;
    }
    return true;
  });

  result = computed(() => ({
    company: this.selection(),
    isPartner: this.isPartner(),
    action: this.newPartner() ? 'post' : 'put',
  }));
  delayValues = DELAY_VALUES;

  constructor() {
    this.company = this.config.data?.company ?? ({} as Company);
    this.companyDistributors = this.config.data?.company?.distributors ?? [];
    this.companyCustomers = this.config.data?.company?.customers ?? [];
    this.distributors = this.getCompanies(this.config.data?.allDistributors ?? []);
    this.customers = this.getCompanies(this.config.data?.allCustomers ?? []);
  }

  onSelect(selectedSubordinate: TextSelectOption<string> | null) {
    if (selectedSubordinate) {
      const selectedCompany = selectedSubordinate?.value;
      const childCompany = this.companyDistributors?.find(
        (dist: SimpleCompany) => dist.id === Number(selectedCompany),
      );
      this.isPartner.set(childCompany?.partners ?? false);
      this.selection.set(selectedSubordinate);
    }
  }

  onPartnerChange($event: boolean) {
    this.isPartner.set($event);
  }

  scopeChanged(value: string) {
    this.selectedScope.set(value);
    this.selection.set(null);
  }

  onSubmit() {
    this.dialogRef.close(this.result());
  }

  private getCompanies(companies: Company[]): { value: string; label: string }[] {
    return this.mapCompany(companies).sort(
      (a: TextSelectOption<string>, b: TextSelectOption<string>) => a.label.localeCompare(b.label),
    );
  }

  private mapCompany(companies: Company[]): { value: string; label: string }[] {
    return companies?.map((company: Company) => ({
      value: company.id.toString(),
      label: company.name,
    }));
  }
}
