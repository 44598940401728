import { Injectable, computed, inject } from '@angular/core';
import { BehaviorSubject, Observable, map, of, shareReplay, switchMap } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { toLazySignal } from 'ngxtension/to-lazy-signal';
import { UserCompany, UserCompanyService } from '@tmc/fleet-core-api';
import { AdminAppUserDetails } from './admin-app-user-details.model';

interface UserData {
  email?: string;
  preferred_username?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AdminAppUserDetailsService {
  private readonly userCompanyService = inject(UserCompanyService);
  private readonly oidcSecurityService = inject(OidcSecurityService);
  view$ = new BehaviorSubject<string>('');
  details$ = this.getUserSettings().pipe(shareReplay({ refCount: false, bufferSize: 1 }));

  // Only use signals behind the userCompanyResolver otherwise a error is thrown.
  // If it needs to be used outside use userCompany$ Observable
  details = toLazySignal(this.details$);
  view = computed(() => {
    const details = this.details();
    if (details === null || details === undefined) return '';
    this.view$.next(AdminAppUserDetailsService.toView(details.company));
    return AdminAppUserDetailsService.toView(details.company);
  });

  static toView(userCompany: UserCompany): string {
    if (userCompany.isVendor) return 'vendor';
    if (userCompany.isManufacturer) return 'manufacturer';
    if (userCompany.isDistributor) return 'distributor';
    if (userCompany.isCustomer) return 'customer';
    throw Error('Unexpected user company response!');
  }

  private getUserSettings(): Observable<AdminAppUserDetails | null> {
    return this.oidcSecurityService.userData$.pipe(
      switchMap(({ userData }) => {
        if (!this.isUserData(userData)) return of(null);
        const email = this.getEmail(userData);
        if (email === undefined) return of(null);
        return this.userCompanyService
          .getUserCompany(email)
          .pipe(map((userCompany) => ({ company: userCompany, email: userData.email })));
      }),
    );
  }

  private getEmail(userData: UserData) {
    if (userData.preferred_username === 'admin') return 'tmos@tmc.com';
    return userData.email;
  }

  private isUserData(userData: unknown): userData is UserData {
    return (
      typeof userData === 'object' &&
      userData !== null &&
      (!('email' in userData) || typeof userData.email === 'string') &&
      (!('preferred_username' in userData) || typeof userData.preferred_username === 'string')
    );
  }
}
