import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'masonry',
  standalone: true,
})
export class MasonryPipe<T> implements PipeTransform {
  transform(value: T[], numColumns: number, colNum: number): unknown {
    if (value.length === 0) return value;
    if (
      numColumns < 1 ||
      colNum < 0 ||
      Number.isNaN(numColumns) ||
      Number.isNaN(colNum) ||
      colNum >= numColumns
    ) {
      throw Error('Incorrect configuration for masonry layout provided.');
    }
    return value.filter((val, index) => index % numColumns === colNum);
  }
}
