import { Component, ChangeDetectionStrategy, viewChild, inject } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { ThemeDirective, OAuthDirective, HeaderComponent } from '@tmc/core-base';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { BreadcrumbComponent, ProgressSpinnerComponent, TitleComponent } from '@tmc/fleet-core-ui';
import { CommonModule } from '@angular/common';
import { outputToObservable, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, from, map, switchMap, withLatestFrom } from 'rxjs';
import { hasValue } from '@tmc/core-utils';
import { TranslateModule } from '@ngx-translate/core';
import { AdminAppUserDetailsService } from '@tmc/fleet-core-feature';

@Component({
  selector: 'tmos-fleet-admin-root',
  templateUrl: './admin-app.component.html',
  styleUrls: ['./admin-app.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    OAuthDirective,
    ThemeDirective,
    HeaderComponent,
    TitleComponent,
    BreadcrumbComponent,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatExpansionModule,
    TranslateModule,
    ProgressSpinnerComponent,
  ],
  hostDirectives: [OAuthDirective, ThemeDirective],
  styles: `
    :host {
      display: block;
      height: 100%;
    }
  `,
})
export class AppComponent {
  private readonly header = viewChild(HeaderComponent);
  private readonly sideNav = viewChild(MatSidenav);
  private readonly adminAppUserDetail = inject(AdminAppUserDetailsService);
  view = toSignal(
    this.adminAppUserDetail.details$.pipe(
      filter(hasValue),
      map((details) => AdminAppUserDetailsService.toView(details.company)),
    ),
  );

  constructor() {
    toObservable(this.header)
      .pipe(
        filter(hasValue),
        switchMap((header) => outputToObservable(header.toggleSidenav)),
        withLatestFrom(toObservable(this.sideNav).pipe(filter(hasValue))),
        switchMap(([, sideNav]) => from(sideNav.toggle())),
      )
      .subscribe();
  }
}
