import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UpdateService } from '@tmc/fleet-core-api';
import { lastValueFrom, firstValueFrom, BehaviorSubject } from 'rxjs';
import { FleetPermission } from './fleet-permission.model';
import { ScopeItem } from '../scope-type/scope-type.model';
import { GroupStandardPermissionSync } from './group/group-standard-permission.model';
import { GroupPermission } from './group/group-fleet-permission.model';
import { UserPermission } from './user/user-fleet-permission.model';

@Injectable()
export class FleetPermissionService {
  private readonly updateService = inject(UpdateService<FleetPermission>);
  private readonly http = inject(HttpClient);

  getAll(): Promise<FleetPermission[]> {
    return lastValueFrom(this.http.get<FleetPermission[]>(`/fleet-admin/permissions`));
  }

  create(body: object): Promise<FleetPermission> {
    return firstValueFrom(this.http.post<FleetPermission>(`/fleet-admin/permissions`, body));
  }

  async deletePermission(permission_id: number): Promise<void> {
    await firstValueFrom(this.http.delete(`/fleet-admin/permissions/${permission_id.toString()}`));
  }

  addPermissions(
    permissions: FleetPermission[],
    _permissions$: BehaviorSubject<FleetPermission[]>,
  ) {
    let allPermissions = _permissions$.getValue();
    allPermissions = this.updateService.addAll(permissions, allPermissions) as FleetPermission[];
    _permissions$.next(allPermissions);
  }

  removePermission(permission: FleetPermission, _permissions$: BehaviorSubject<FleetPermission[]>) {
    let permissions = _permissions$.getValue();
    permissions = this.updateService.remove(permission, permissions) as FleetPermission[];
    _permissions$.next(permissions);
  }

  transformToGroupStandardFleetPermissions(
    permissions: GroupStandardPermissionSync[],
  ): FleetPermission[] {
    const result: FleetPermission[] = [];
    Object.values(permissions)?.forEach((permission: GroupStandardPermissionSync) => {
      result.push(
        new GroupStandardPermissionSync(
          permission.id,
          permission.application,
          permission.moduleRole,
          permission.scope,
        ),
      );
    });
    return result;
  }

  transformToGroupFleetPermissions(
    groupPermissions: GroupPermission[],
    permissions: GroupStandardPermissionSync[],
  ): FleetPermission[] {
    const result: FleetPermission[] = [];
    Object.values(groupPermissions)?.forEach((groupPermission: GroupPermission) => {
      const permission = Object.values(permissions).find(
        (perm: GroupStandardPermissionSync) => perm.id === groupPermission.permissionId,
      );
      result.push({
        ...groupPermission,
        id: groupPermission.permissionId,
        scope:
          permission !== undefined
            ? (this.getScopeForGroupPermission(permission) as ScopeItem | undefined)
            : undefined,
      } as FleetPermission);
    });
    return result;
  }

  transformToUserFleetPermissions(permissions: UserPermission[]): FleetPermission[] {
    const result: FleetPermission[] = [];
    permissions?.forEach((permission: UserPermission) => {
      result.push({
        ...permission,
        id: permission.permissionId,
        scope: this.getScopeForUserPermission(permission) as ScopeItem | undefined,
      });
    });
    return result;
  }

  private getScopeForUserPermission(permission: UserPermission): string | undefined {
    if (permission.scopeCompany !== null) return permission.scopeCompany;
    if (permission.scopeMachine !== null) return permission.scopeMachine;
    if (permission.scopeFleet !== null) return permission.scopeFleet;
    return undefined;
  }

  private getScopeForGroupPermission(permission: GroupStandardPermissionSync): string | undefined {
    if (permission.scope.company !== null) return permission.scope.company.name;
    if (permission.scope.machine !== null) return permission.scope.machine.externalId;
    if (permission.scope.fleet !== null) return permission.scope.fleet.name;
    return undefined;
  }
}
