import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, firstValueFrom, lastValueFrom, map } from 'rxjs';
import { UtilsService } from '@tmc/fleet-core-utils';
import {
  Company,
  CompanySync,
  CompanyDataSync,
  SimpleCompany,
  SubordinateCompanyData,
} from './company.model';
import { UserEmail } from '../user/user-email.model';
import { PaginatedResult } from '../models/paginated-result.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private readonly http = inject(HttpClient);
  private readonly utilsService = inject(UtilsService);

  manufacturers: SimpleCompany[] = [];
  customers: SimpleCompany[] = [];
  distributors: SimpleCompany[] = [];

  transform = (company: CompanySync): Company => ({
    ...company,
    companies: company.distributedCompanies?.map((item: SimpleCompany) => item.name),
    customers: [
      ...((company.customers as SimpleCompany[]) ?? []),
      ...(company.distributedCompanies ?? []),
    ],
    admins: company.adminUsers?.map(
      (user: UserEmail) => new UserEmail(user.email, user.originalEmail),
    ),
    machines: this.getNumberOfMachines(company),
    manufacturedMachines: company.manufacturedMachinesExternalIds?.length ?? 0,
    distributedMachines: company.distributedMachines?.length ?? 0,
    manufacturedCompanies: company.manufacturedCompaniesNames?.map((c) => c.name) ?? [],
    countryIso: company.countryCode,
    allRelatedCompanies: [
      ...(company.manufacturers ?? []),
      ...(company.distributedCompanies ?? []),
      ...(company.distributors ?? []),
      ...(company.customers ?? []),
    ],
  });

  getAll(url: string, plain?: boolean, pageIndex?: number): Promise<Company[]> {
    const params = new HttpParams();
    params.set('_page_size', 0);
    if (pageIndex !== undefined) params.set('_page', pageIndex);
    if (plain !== undefined) {
      params.set('plain', true);
    }
    return lastValueFrom(
      this.http
        .get<PaginatedResult<CompanySync>>(`/fleet-admin/${url}`, { params })
        .pipe(
          map((companyList: PaginatedResult<CompanySync>) =>
            companyList.items.map((company: CompanySync) => this.transform(company)),
          ),
        )
        .pipe(catchError(this.utilsService.handleError<Company[]>('getAll', []))),
    );
  }

  getOne(url: string, externalId: string): Promise<Company> {
    return lastValueFrom(
      this.http
        .get<CompanySync>(`/fleet-admin/${url}/${externalId}`)
        .pipe(map((company: CompanySync) => this.transform(company))),
    );
  }

  edit(
    url: string,
    externalId: string,
    query: CompanyDataSync | SubordinateCompanyData,
  ): Promise<Company> {
    return lastValueFrom(
      this.http
        .put<CompanySync>(`/fleet-admin/${url}/${encodeURIComponent(externalId)}`, query)
        .pipe(map((company: CompanySync) => this.transform(company))),
    );
  }

  delete(url: string, companyId: number): Promise<void> {
    return firstValueFrom(
      this.http.delete(`/fleet-admin/${url}/${companyId.toString()}`).pipe(map(() => undefined)),
    );
  }

  create(url: string, body: CompanyDataSync): Promise<Company | undefined> {
    return lastValueFrom(
      this.http
        .post<CompanySync>(`/fleet-admin/${url}`, body, {})
        .pipe(map((company: CompanySync) => this.transform(company))),
    );
  }

  private getNumberOfMachines(company: CompanySync): number {
    if (company.isCustomer) {
      return company.machines?.length ?? 0;
    }
    if (company.isDistributor === true) {
      return company.distributedMachines?.length ?? 0;
    }
    if (company.isManufacturer === true) {
      return company.manufacturedMachinesExternalIds?.length ?? 0;
    }
    return 0;
  }
}
