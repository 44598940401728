import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, ViewChild, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i1 from '@ngx-formly/core';
import { ɵobserve, FormlyModule } from '@ngx-formly/core';
import * as i4 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import * as i3 from '@angular/material/input';
import { MatInputModule } from '@angular/material/input';
import * as i2 from '@angular/material/datepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
const _c0 = ["datepickerToggle"];
function FormlyFieldDatepicker_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-datepicker-toggle", 4);
    i0.ɵɵlistener("click", function FormlyFieldDatepicker_ng_template_1_Template_mat_datepicker_toggle_click_0_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.detectChanges());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    const picker_r4 = i0.ɵɵreference(4);
    i0.ɵɵproperty("disabled", ctx_r2.props.disabled)("for", picker_r4);
  }
}
class FormlyFieldDatepicker extends FieldType {
  constructor(config, cdRef) {
    super();
    this.config = config;
    this.cdRef = cdRef;
    this.defaultOptions = {
      props: {
        datepickerOptions: {
          startView: 'month',
          datepickerTogglePosition: 'suffix',
          disabled: false,
          opened: false,
          dateInput: () => {},
          dateChange: () => {},
          monthSelected: () => {},
          yearSelected: () => {}
        }
      }
    };
  }
  detectChanges() {
    this.options.detectChanges?.(this.field);
  }
  ngAfterViewInit() {
    this.props[this.props.datepickerOptions.datepickerTogglePosition] = this.datepickerToggle;
    ɵobserve(this.field, ['props', 'datepickerOptions', 'opened'], () => {
      this.cdRef.detectChanges();
    });
    // temporary fix for https://github.com/angular/components/issues/16761
    if (this.config.getValidatorMessage('matDatepickerParse')) {
      this.fieldErrorsObserver = ɵobserve(this.field, ['formControl', 'errors'], ({
        currentValue
      }) => {
        if (currentValue && currentValue.required && currentValue.matDatepickerParse) {
          const errors = Object.keys(currentValue).sort(prop => prop === 'matDatepickerParse' ? -1 : 0).reduce((errors, prop) => ({
            ...errors,
            [prop]: currentValue[prop]
          }), {});
          this.fieldErrorsObserver?.setValue(errors);
        }
      });
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    this.fieldErrorsObserver?.unsubscribe();
  }
}
FormlyFieldDatepicker.ɵfac = function FormlyFieldDatepicker_Factory(t) {
  return new (t || FormlyFieldDatepicker)(i0.ɵɵdirectiveInject(i1.FormlyConfig), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
FormlyFieldDatepicker.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldDatepicker,
  selectors: [["formly-field-mat-datepicker"]],
  viewQuery: function FormlyFieldDatepicker_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.datepickerToggle = _t.first);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 5,
  vars: 22,
  consts: [["datepickerToggle", ""], ["picker", ""], ["matInput", "", 3, "dateInput", "dateChange", "id", "name", "errorStateMatcher", "formControl", "matDatepicker", "matDatepickerFilter", "max", "min", "formlyAttributes", "placeholder", "tabindex", "readonly", "required"], [3, "monthSelected", "yearSelected", "opened", "closed", "color", "dateClass", "disabled", "panelClass", "startAt", "startView", "touchUi", "calendarHeaderComponent"], [3, "click", "disabled", "for"]],
  template: function FormlyFieldDatepicker_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelementStart(0, "input", 2);
      i0.ɵɵlistener("dateInput", function FormlyFieldDatepicker_Template_input_dateInput_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.props.datepickerOptions.dateInput(ctx.field, $event));
      })("dateChange", function FormlyFieldDatepicker_Template_input_dateChange_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.props.datepickerOptions.dateChange(ctx.field, $event));
      });
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(1, FormlyFieldDatepicker_ng_template_1_Template, 1, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementStart(3, "mat-datepicker", 3, 1);
      i0.ɵɵlistener("monthSelected", function FormlyFieldDatepicker_Template_mat_datepicker_monthSelected_3_listener($event) {
        i0.ɵɵrestoreView(_r1);
        const picker_r4 = i0.ɵɵreference(4);
        return i0.ɵɵresetView(ctx.props.datepickerOptions.monthSelected(ctx.field, $event, picker_r4));
      })("yearSelected", function FormlyFieldDatepicker_Template_mat_datepicker_yearSelected_3_listener($event) {
        i0.ɵɵrestoreView(_r1);
        const picker_r4 = i0.ɵɵreference(4);
        return i0.ɵɵresetView(ctx.props.datepickerOptions.yearSelected(ctx.field, $event, picker_r4));
      })("opened", function FormlyFieldDatepicker_Template_mat_datepicker_opened_3_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.props.datepickerOptions.opened = true);
      })("closed", function FormlyFieldDatepicker_Template_mat_datepicker_closed_3_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.props.datepickerOptions.opened = false);
      });
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      const picker_r4 = i0.ɵɵreference(4);
      i0.ɵɵproperty("id", ctx.id)("name", ctx.field.name)("errorStateMatcher", ctx.errorStateMatcher)("formControl", ctx.formControl)("matDatepicker", picker_r4)("matDatepickerFilter", ctx.props.datepickerOptions.filter)("max", ctx.props.datepickerOptions.max)("min", ctx.props.datepickerOptions.min)("formlyAttributes", ctx.field)("placeholder", ctx.props.placeholder)("tabindex", ctx.props.tabindex)("readonly", ctx.props.readonly)("required", ctx.required);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("color", ctx.props.color)("dateClass", ctx.props.datepickerOptions.dateClass)("disabled", ctx.props.datepickerOptions.disabled)("opened", ctx.props.datepickerOptions.opened)("panelClass", ctx.props.datepickerOptions.panelClass)("startAt", ctx.props.datepickerOptions.startAt)("startView", ctx.props.datepickerOptions.startView)("touchUi", ctx.props.datepickerOptions.touchUi)("calendarHeaderComponent", ctx.props.datepickerOptions.calendarHeaderComponent);
    }
  },
  dependencies: [i2.MatDatepickerToggle, i2.MatDatepicker, i3.MatInput, i4.DefaultValueAccessor, i2.MatDatepickerInput, i4.NgControlStatus, i4.FormControlDirective, i1.ɵFormlyAttributes, i4.RequiredValidator],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldDatepicker, [{
    type: Component,
    args: [{
      selector: 'formly-field-mat-datepicker',
      template: `
    <input
      matInput
      [id]="id"
      [name]="field.name"
      [errorStateMatcher]="errorStateMatcher"
      [formControl]="formControl"
      [matDatepicker]="picker"
      [matDatepickerFilter]="props.datepickerOptions.filter"
      [max]="props.datepickerOptions.max"
      [min]="props.datepickerOptions.min"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder"
      [tabindex]="props.tabindex"
      [readonly]="props.readonly"
      [required]="required"
      (dateInput)="props.datepickerOptions.dateInput(field, $event)"
      (dateChange)="props.datepickerOptions.dateChange(field, $event)"
    />
    <ng-template #datepickerToggle>
      <mat-datepicker-toggle
        (click)="detectChanges()"
        [disabled]="props.disabled"
        [for]="picker"
      ></mat-datepicker-toggle>
    </ng-template>
    <mat-datepicker
      #picker
      [color]="props.color"
      [dateClass]="props.datepickerOptions.dateClass"
      [disabled]="props.datepickerOptions.disabled"
      [opened]="props.datepickerOptions.opened"
      [panelClass]="props.datepickerOptions.panelClass"
      [startAt]="props.datepickerOptions.startAt"
      [startView]="props.datepickerOptions.startView"
      [touchUi]="props.datepickerOptions.touchUi"
      [calendarHeaderComponent]="props.datepickerOptions.calendarHeaderComponent"
      (monthSelected)="props.datepickerOptions.monthSelected(field, $event, picker)"
      (yearSelected)="props.datepickerOptions.yearSelected(field, $event, picker)"
      (opened)="props.datepickerOptions.opened = true"
      (closed)="props.datepickerOptions.opened = false"
    >
    </mat-datepicker>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: i1.FormlyConfig
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    datepickerToggle: [{
      type: ViewChild,
      args: ['datepickerToggle', {
        static: true
      }]
    }]
  });
})();
class FormlyMatDatepickerModule {}
FormlyMatDatepickerModule.ɵfac = function FormlyMatDatepickerModule_Factory(t) {
  return new (t || FormlyMatDatepickerModule)();
};
FormlyMatDatepickerModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyMatDatepickerModule
});
FormlyMatDatepickerModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, MatInputModule, MatDatepickerModule, FormlyMatFormFieldModule, FormlyModule.forChild({
    types: [{
      name: 'datepicker',
      component: FormlyFieldDatepicker,
      wrappers: ['form-field']
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyMatDatepickerModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldDatepicker],
      imports: [CommonModule, ReactiveFormsModule, MatInputModule, MatDatepickerModule, FormlyMatFormFieldModule, FormlyModule.forChild({
        types: [{
          name: 'datepicker',
          component: FormlyFieldDatepicker,
          wrappers: ['form-field']
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFieldDatepicker, FormlyMatDatepickerModule };
