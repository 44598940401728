/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Provider } from '@angular/core';
import { CompanyService, MachineService } from '@tmc/fleet-core-api';
import {
  PERMISSIONS_FORM_SERVICE,
  ScopeTypeService,
  ApplicationService,
  FleetManagementPermissionsFormService,
} from '@tmc/mco-user-management-api';

export function providePermissionsFormOptions(): Provider {
  return [
    {
      provide: PERMISSIONS_FORM_SERVICE,
      useFactory: () => new FleetManagementPermissionsFormService(),
    },
    MachineService,
    CompanyService,
    ScopeTypeService,
    ApplicationService,
  ];
}
