/* eslint-disable @typescript-eslint/no-unsafe-member-access */ // Needed for modulesIds and widgetIds conversion
import { CommonModule, JsonPipe } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  inject,
  computed,
  input,
  effect,
  signal,
} from '@angular/core';
import { ReactiveFormsModule, FormsModule, UntypedFormBuilder } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { FormlyModule, FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatMultiCheckboxModule } from '@ngx-formly/material/multicheckbox';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormlyTranslatePipe, TextSelectComponent } from '@tmc/core-ui';
import {
  FormlyAutocompleteTypeComponent,
  FormlyMultiselectionTypeComponent,
  EXTRA_MDC_MODULE,
  MANUFACTURERS_WITH_LICENSES,
  PictureUploadComponent,
  ProgressSpinnerComponent,
  UtcTimeSelectorComponent,
} from '@tmc/fleet-core-ui';
import { MatIconModule } from '@angular/material/icon';
import {
  FileType,
  Machine,
  MachineModule,
  Region,
  SimpleCompany,
  Widget,
} from '@tmc/fleet-core-api';
import { toSignal } from '@angular/core/rxjs-interop';
import { map, of, tap } from 'rxjs';
import { MACHINE_FORM_SERVICE } from './fleet-machine-form.token';
import { MachineFormData, MachineFormState } from './fleet-machine-form.model';

@Component({
  selector: 'tmc-fleet-machine-form',
  standalone: true,
  imports: [
    CommonModule,
    FormlyModule,
    FormlyMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatNativeDateModule,
    FormlyMatMultiCheckboxModule,
    FormlyMatDatepickerModule,
    FormlySelectModule,
    RouterModule,
    TextSelectComponent,
    TranslateModule,
    FormlyAutocompleteTypeComponent,
    FormlyMultiselectionTypeComponent,
    UtcTimeSelectorComponent,
    ProgressSpinnerComponent,
    PictureUploadComponent,
    FormlyTranslatePipe,
    JsonPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './fleet-machine-form.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetMachineFormComponent {
  private readonly machineFormService = inject(MACHINE_FORM_SERVICE);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly translate = inject(TranslateService);
  id = input<string | null>();
  machine = input.required<Machine | null | undefined>();
  form = this.fb.group({
    photo: [],
  });
  model: Machine = {} as Machine;
  photograph = signal<string | undefined>(undefined);
  photographFile: File | undefined;
  manufacturers$ = this.machineFormService.getManufacturers();
  distributors$ = this.machineFormService.getDistributors();
  customers$ = this.machineFormService.getCustomers();
  languages$ = this.machineFormService.getLanguages();
  modules$ = this.machineFormService.getModules();
  regions$ = this.machineFormService.getRegions();
  widgets$ = this.machineFormService.getWidgets();
  fileTypes$ = this.machineFormService.getFileTypes();
  machineDataChildren$ = this.machineFormService.getMachineDataChildren();
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {
    formState: {
      limitDate: true as boolean,
      today: new Date(),
      hideConfiguration: true as boolean,
      hideLicenseType: true as boolean,
      hiddenModuleOptions: [] as number[],
      distributorSelected: false,
      customerSelected: false,
    } as MachineFormState,
  };
  photo = computed(() => this.machine()?.signedPhotograph) ?? undefined;
  view = toSignal(this.machineFormService.getView());
  machineDataChildren = toSignal(this.machineDataChildren$);
  allFileTypes = toSignal(this.fileTypes$);
  fileTypesLabels = toSignal(this.machineFormService.getFileTypesLabels());
  allModules = toSignal(this.modules$);
  manufacturersSignal = toSignal(this.machineFormService.getManufacturers(), { initialValue: [] });
  distributorsSignal = toSignal(this.machineFormService.getDistributors(), { initialValue: [] });
  customersSignal = toSignal(this.machineFormService.getCustomers(), { initialValue: [] });
  languagesSignal = toSignal(this.machineFormService.getLanguages(), { initialValue: [] });
  regionsSignal = toSignal(this.machineFormService.getRegions(), { initialValue: [] });
  widgetsSignal = toSignal(this.machineFormService.getWidgets(), { initialValue: [] });

  loading = computed(
    () =>
      this.view() === '' ||
      this.view() === undefined ||
      (this.view() === 'vendor' && this.manufacturersSignal().length === 0) ||
      (this.view() === 'vendor' && this.languagesSignal().length === 0) ||
      (this.view() === 'vendor' && this.regionsSignal().length === 0) ||
      this.allModules()?.length === 0 ||
      this.widgetsSignal().length === 0,
  );

  constructor() {
    effect(() => {
      if (this.machine() !== null && this.view() !== undefined) {
        this.model = this.machine() ?? ({} as Machine);
        this.initializeOptions();
        this.initializeForm();
      }
    });
  }

  setPhotographFile(file: File) {
    this.photographFile = file;
  }

  submit() {
    if (this.form.valid) {
      const formData = this.getValidatedData();
      this.machineFormService.saveOrUpdate(
        formData,
        (this.form.value.region as Region)?.code,
        this.id() === 'new' ? 'create' : 'update',
        this.photographFile ?? undefined,
      );
    }
  }

  private initializeOptions() {
    this.options.formState.distributorSelected = this.machine()?.distributor !== null;
    this.options.formState.customerSelected =
      (this.view() === 'manufacturer' && this.machine()?.distributor === null) ||
      (this.view() === 'distributor' && this.machine()?.customers !== null);
  }

  private initializeForm() {
    this.fields = [
      this.machine() !== undefined ? this.createExternalIdField() : {},
      {
        fieldGroupClassName: 'flex space-x-4',
        fieldGroup: [this.createCodeField(), this.createMachineNameField()],
      },
      this.createDescriptionField(),
      this.createManufacturedDateField(),
      this.createCategoryField(),
      this.createMachineType(),
      {
        fieldGroupClassName: 'flex flex-wrap justify-center space-x-4',
        wrappers: ['tmc-formly-utc-time-selector'],
        props: {
          label: 'MACHINE_MANAGEMENT.FORM_LABELS.UTC',
        },
        fieldGroup: [this.createUTCHoursField(), this.createUTCMinutesField()],
      },
      this.createStatusField(),
      this.createLanguageField(),
      this.createRegionField(),
      this.view() === 'vendor' ? this.createManufacturerField() : {},
      this.view() === 'manufacturer' ? this.createDistributorField() : {},
      this.view() === 'manufacturer' || this.view() === 'distributor'
        ? this.createCustomerField()
        : {},
      this.createLicenseField(),
      this.createFileTypeField(),
      this.createMachineFileNameField(),
      this.createConfigurationField(),
      this.createModulesField(),
      this.createWidgetsField(),
    ];
  }

  private createExternalIdField(): FormlyFieldConfig {
    return {
      key: 'externalId',
      type: 'textarea',
      defaultValue: this.machine()?.externalId,
      props: {
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.EXTERNAL_ID',
        required: false,
      },
      expressions: {
        'props.readonly': of(true),
      },
    };
  }

  setValue(field: FormlyFieldConfig, value: unknown) {
    const control = field.formControl;
    control?.setValue(value);
  }

  private createCodeField(): FormlyFieldConfig {
    return {
      className: 'flex-1',
      key: 'code',
      type: 'input',
      props: {
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.CODE',
        pattern: /^[a-zA-Z0-9]*$/,
        required: true,
        maxLength: 10,
      },
      hooks: {
        onInit: (field) => {
          this.setValue(field, this.machine()?.code);
        },
      },
      expressions: {
        'props.readonly': of(this.view() !== 'vendor'),
      },
      validation: {
        messages: {
          pattern: () => this.translate.stream('MACHINE_MANAGEMENT.FORM_LABELS.INVALID_CODE'),
        },
      },
    };
  }

  private createMachineNameField(): FormlyFieldConfig {
    return {
      className: 'flex-1',
      key: 'name',
      type: 'input',
      props: {
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.MACHINE_NAME',
        required: true,
      },
      hooks: {
        onInit: (field) => {
          const control = field.formControl;
          control?.setValue(this.machine()?.name);
        },
      },
    };
  }

  private createDescriptionField() {
    return {
      key: 'description',
      type: 'textarea',
      defaultValue: this.machine()?.description,
      props: {
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.DESCRIPTION',
        required: false,
      },
    };
  }

  private createManufacturedDateField(): FormlyFieldConfig {
    return {
      key: 'manufactured',
      type: 'datepicker',
      defaultValue:
        this.machine()?.commissionedTime === undefined || this.machine()?.commissionedTime === null
          ? null
          : new Date(this.machine()?.commissionedTime ?? 0),
      props: {
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.MANUFACTURED',
        placeholder: 'mm/dd/yyyy',
        format: 'mm-dd-yyyy',
        required: false,
      },
      expressionProperties: {
        'props.datepickerOptions.max': `formState.limitDate ? formState.today : null`,
      },
    };
  }

  private createCategoryField() {
    return {
      key: 'category',
      type: 'select',
      defaultValue: this.machine()?.group,
      props: {
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.CATEGORY',
        required: true,
        options: this.machineFormService.getMachineCategories(),
      },
    };
  }

  private createMachineType() {
    return {
      key: 'machineType',
      type: 'input',
      defaultValue: this.machine()?.type,
      props: {
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.TYPE',
        required: false,
      },
    };
  }

  private createUTCHoursField() {
    return {
      key: 'utcHours',
      type: 'input',
      defaultValue: this.machine()?.utcHours ?? 0,
      props: {
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.HOURS',
        type: 'number',
        min: -12,
        max: 12,
        required: false,
        className: 'form-input mt-1 block w-full',
      },
      expressions: {
        'props.readonly': of(this.view() !== 'vendor'),
      },
      className: 'flex-15',
    };
  }

  private createUTCMinutesField() {
    return {
      key: 'utcMinutes',
      type: 'input',
      defaultValue: this.machine()?.utcMinutes ?? 0,
      props: {
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.MINUTES',
        type: 'number',
        min: 0,
        max: 55,
        step: 5,
        className: 'form-input mt-1 block w-full',
        required: false,
      },
      expressions: {
        'props.readonly': of(this.view() !== 'vendor'),
      },
      className: 'flex-15',
    };
  }

  private createStatusField() {
    return {
      key: 'status',
      type: 'select',
      defaultValue: this.machine()?.status,
      props: {
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.STATUS',
        required: true,
        options: this.machineFormService.getMachineStatuses(),
      },
      expressions: {
        'props.readonly': of(this.view() !== 'vendor'),
      },
    };
  }

  private createLanguageField() {
    return {
      key: 'languageId',
      type: 'select',
      props: {
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.LANGUAGE',
        required: true,
        options: this.languages$,
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          this.onLanguageInit(field);
        },
      },
      expressions: {
        'props.readonly': of(this.view() !== 'vendor'),
      },
    };
  }
  private onLanguageInit(field: FormlyFieldConfig) {
    const control = field.formControl;
    this.languages$
      .pipe(
        map(
          (languages) =>
            languages.find((item) => item.value.id === this.machine()?.languageId)?.value ??
            undefined,
        ),
        map((value) => {
          if (value !== undefined) {
            control?.setValue(value);
          }
        }),
      )
      .subscribe();
  }

  private createRegionField() {
    return {
      key: 'region',
      type: 'select',
      props: {
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.REGION',
        required: true,
        options: of(this.regionsSignal()),
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          this.onRegionInit(field);
        },
      },
      expressions: {
        'props.readonly': of(this.view() !== 'vendor'),
      },
    };
  }

  private onRegionInit(field: FormlyFieldConfig) {
    const control = field.formControl;
    control?.setValue(
      this.regionsSignal().find((region) => region.value.id === this.machine()?.regionId)?.value,
    );
    field.formControl?.valueChanges
      .pipe(
        map(() => {
          (this.options.formState as MachineFormState).hideConfiguration = true;
        }),
        tap(() => {
          (this.options.formState as MachineFormState).hideConfiguration = !(
            this.form.value.fileType as FileType
          )?.requiresConfiguration;
        }),
      )
      .subscribe();
  }

  private createManufacturerField() {
    if (this.machine() !== undefined) {
      return {
        key: 'formManufacturer',
        type: 'input',
        defaultValue: this.machine()?.manufacturer?.name,
        props: {
          label: 'MACHINE_MANAGEMENT.FORM_LABELS.MANUFACTURER',
          placeholder: 'MACHINE_MANAGEMENT.FORM_LABELS.MANUFACTURER',
          required: true,
        },
        expressions: {
          'props.readonly': of(true),
        },
      };
    }

    return {
      key: 'manufacturer',
      type: 'autocomplete',
      props: {
        required: true,
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.MANUFACTURER',
        options: of(this.manufacturersSignal()),
        filter: () => of([]),
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          this.onManufacturerInit(field);
        },
      },
    };
  }

  private onManufacturerInit(field: FormlyFieldConfig) {
    const control = field.formControl;
    if (this.machine()?.manufacturer !== null) {
      control?.setValue(this.machine()?.manufacturer?.name.toUpperCase());
      if (this.machine()?.license === undefined || this.machine()?.license === null) {
        (this.options.formState as MachineFormState).hideLicenseType = true;
      } else {
        (this.options.formState as MachineFormState).hideLicenseType = false;
      }
    }
    field.formControl?.valueChanges
      .pipe(
        map((value) => {
          (this.options.formState as MachineFormState).hideConfiguration = true;
          (this.options.formState as MachineFormState).hideLicenseType =
            !MANUFACTURERS_WITH_LICENSES.includes(value?.label?.toUpperCase());

          return value as SimpleCompany;
        }),
        tap(() => {
          (this.options.formState as MachineFormState).hideConfiguration = !(
            this.form.value.fileType as FileType
          )?.requiresConfiguration;
        }),
      )
      .subscribe();
  }

  private createDistributorField() {
    return {
      key: 'distributor',
      type: 'autocomplete',
      defaultValue: {
        value: this.machine()?.distributor,
        label: this.machine()?.distributor?.name,
      },
      props: {
        required: false,
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.DISTRIBUTOR',
        options: of(this.distributorsSignal()),
        filter: () => of([]),
      },
      expressionProperties: {
        'templateOptions.disabled': () =>
          !!(this.options.formState as MachineFormState).customerSelected,
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          this.onDistributorInit(field);
        },
      },
    };
  }
  private onDistributorInit(field: FormlyFieldConfig) {
    const control = field.formControl;
    if (this.machine()?.distributor !== null) {
      control?.setValue({
        value: this.machine()?.distributor,
        label: this.machine()?.distributor?.name,
      });
    }
    field.formControl?.valueChanges
      .pipe(
        map((value) => {
          (this.options.formState as MachineFormState).distributorSelected = value !== '';
        }),
      )
      .subscribe();
  }

  private createCustomerField() {
    // TO DO disable the custom type when distributor is selected
    return {
      key: 'customer',
      type: 'multiselection',
      defaultValue: [],
      props: {
        required: false,
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.CUSTOMER',
        options: this.customersSignal(),
      },
      expressions: {
        'props.readonly': () => !!(this.options.formState as MachineFormState).distributorSelected,
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          this.onCustomerInit(field);
        },
      },
    };
  }
  private onCustomerInit(field: FormlyFieldConfig) {
    const control = field.formControl;
    if (this.machine()?.customers !== null) {
      control?.setValue(this.machine()?.customer?.name?.toUpperCase());
      (this.options.formState as MachineFormState).customerSelected = true;
    } else {
      (this.options.formState as MachineFormState).customerSelected = false;
    }

    field.formControl?.valueChanges
      .pipe(
        map((value) => {
          if (value === '') (this.options.formState as MachineFormState).customerSelected = false;
        }),
      )
      .subscribe();
  }

  private createLicenseField() {
    return {
      key: 'license',
      type: 'radio',
      defaultValue: this.machine()?.license,
      props: {
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.LICENSE',
        description: 'Type of license',
        required: true,
        options: [
          { value: 'advanced', label: 'ADVANCED' },
          { value: 'basic', label: 'BASIC' },
        ],
      },
      hideExpression: () =>
        (this.options.formState as MachineFormState).hideLicenseType ||
        !MANUFACTURERS_WITH_LICENSES.includes(this.machine()?.manufacturer?.name ?? ''),
    };
  }

  private createFileTypeField() {
    if (this.view() === 'vendor') {
      return {
        key: 'fileType',
        type: 'radio',
        props: {
          required: true,
          label: 'MACHINE_MANAGEMENT.FORM_LABELS.FILE',
          options: this.fileTypesLabels(),
        },
        hooks: {
          onInit: (field: FormlyFieldConfig) => {
            this.onInitFileType(field);
          },
        },
      };
    }
    return {
      key: 'fileType',
      type: 'textarea',
      props: {
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.FILE',
      },
      expressions: {
        'props.readonly': of(true),
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          this.onInitFileType(field);
        },
      },
    };
  }

  private onInitFileType(field: FormlyFieldConfig) {
    const control = field.formControl;
    if (this.view() !== 'vendor') {
      control?.setValue(this.machine()?.fileType.type ?? undefined);
    } else {
      control?.setValue(
        this.fileTypesLabels()?.find((type) => type.value.id === this.machine()?.fileTypeId)?.value,
      );
      field.formControl?.valueChanges
        .pipe(
          map((value) => {
            (this.options.formState as MachineFormState).hideConfiguration = true;
            return value as FileType;
          }),
          tap((value) => {
            (this.options.formState as MachineFormState).hideConfiguration = !(
              this.allFileTypes()?.find((item) => item.id === value.id)?.requiresConfiguration ??
              false
            );
            // configuration management just available for mdcs
            if (value.id === 1) {
              (this.options.formState as MachineFormState).hiddenModuleOptions = [];
            } else {
              (this.options.formState as MachineFormState).hiddenModuleOptions = [
                EXTRA_MDC_MODULE.id,
              ];
            }
          }),
        )
        .subscribe();
    }
  }

  private createMachineFileNameField() {
    //   // Just shown for fds and dds
    return {
      key: 'machineFileName',
      type: 'input',
      props: {
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.MACHINE_FILENAME',
        description: 'Name of the machine in data files',
        required: true,
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          this.onMachineNameInit(field);
        },
      },
      hideExpression: () =>
        ((this.options.formState as MachineFormState).hideConfiguration &&
          this.machine() === undefined) ||
        this.machine()?.configuration === null ||
        this.machine()?.configuration === undefined,
    };
  }

  private onMachineNameInit(field: FormlyFieldConfig) {
    const control = field.formControl;
    if (this.machine()?.machineName !== null) {
      control?.setValue(this.machine()?.machineName);
    }
  }
  private createConfigurationField() {
    //   // Just shown for fds and dds
    return {
      key: 'configuration',
      type: 'autocomplete',
      defaultValue: this.machine()?.configuration?.configurationName,
      props: {
        required: true,
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.CONFIGURATION',
        options: [],
        filter: (term: string) =>
          this.machineFormService
            .getConfigurations(
              this.form.value.manufacturer,
              this.form.value.region,
              this.form.value.fileType,
            )
            .pipe(
              map((configurations) => configurations.map((item) => item.label)),
              map((configurations) =>
                configurations.filter((item) => item.toLowerCase().startsWith(term?.toLowerCase())),
              ),
            ),
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          this.onConfigurationInit(field);
        },
      },
      hideExpression: () =>
        ((this.options.formState as MachineFormState).hideConfiguration &&
          this.machine() === undefined) ||
        this.machine()?.configuration === null ||
        this.machine()?.configuration === undefined,
    };
  }
  private onConfigurationInit(field: FormlyFieldConfig) {
    const control = field.formControl;
    if (this.machine()?.configuration !== null) {
      control?.setValue(this.machine()?.configuration?.configurationName);
    }
  }

  private createModulesField() {
    return {
      key: 'modulesIds',
      type: 'multicheckbox',
      props: {
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.MODULES',
      },
      expressions: {
        'props.readonly': of(this.view() !== 'vendor'),
      },
      expressionProperties: {
        'props.options': (model: unknown) =>
          this.allModules()
            ?.filter(
              (option) =>
                !(this.options.formState as MachineFormState).hiddenModuleOptions.includes(
                  option.value,
                ),
            )
            .map((option) => {
              if (this.machineDataChildren()?.includes(option.label.toUpperCase()) === false) {
                return option;
              }
              // option deactivated if not machine data checked
              const isMachineDataChecked = (model as MachineFormData)?.modulesIds?.[6] ?? false; // Machine Data id
              return {
                ...option,
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                disabled: !isMachineDataChecked,
              };
            }),
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          this.onModulesInit(field);
        },
      },
    };
  }

  private createWidgetsField() {
    return {
      key: 'widgetsIds',
      type: 'multicheckbox',
      props: {
        label: 'MACHINE_MANAGEMENT.FORM_LABELS.WIDGETS',
        options: this.widgets$.pipe(
          map((widgets) =>
            widgets.map((item) => ({
              ...item,
              label: this.translate.instant(item.label.toUpperCase()) as string,
            })),
          ),
        ),
      },
      expressions: {
        'props.readonly': of(this.view() !== 'vendor'),
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          this.onInitWidget(field);
        },
      },
    };
  }

  private onModulesInit(field: FormlyFieldConfig) {
    const control = field.formControl;
    if (this.machine()?.modules !== null) {
      this.machine()
        ?.modules.map((item: MachineModule) => ({ [item.id]: true }))
        .forEach((item) => {
          control?.setValue({ ...control?.value, ...item });
        });
    }
  }

  private onInitWidget(field: FormlyFieldConfig) {
    const control = field.formControl;
    if (this.machine()?.widgets !== null) {
      this.machine()
        ?.widgets?.map((item: Widget) => ({ [item.id]: true }))
        .forEach((item) => {
          control?.setValue({ ...control?.value, ...item });
        });
    }
  }

  private getValidatedData(): MachineFormData {
    return {
      ...this.form.value,
      fileTypeId: this.form.value.fileTypeId?.id as number,
      languageId: this.form.value.languageId?.id as number,
      regionId: this.form.value.region.id as number,
      externalId:
        this.machine() !== undefined
          ? this.machine()?.externalId
          : `${this.form.value.manufacturer.value.externalId}_${this.form.value.code}`,
      manufacturerId:
        this.machine() !== undefined
          ? this.machine()?.manufacturer?.id
          : (this.form.value.manufacturer.value as SimpleCompany).id,
      modulesIds:
        this.form.value.modulesIds !== undefined
          ? this.parseEntries(this.form.value.modulesIds as Record<string, unknown>[])
          : [],
      widgetsIds:
        this.form.value.widgetsIds !== undefined
          ? this.parseEntries(this.form.value.widgetsIds as Record<string, unknown>[])
          : [],
      photograph: this.photograph() ?? this.machine()?.photograph,
    } as MachineFormData;
  }

  private parseEntries(object: Record<string, unknown>[]) {
    return Object.fromEntries(
      Object.entries(object).filter((pair) => typeof pair[1] === 'boolean' && pair[1] === true), // filter out checkout boxes with numeric or false value
    );
  }
}
