import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

/**
 * Callback component is used as a public route for the application
 * It will only be shown very briefly until the Oicd library redirects to the previous route
 */
@Component({
  selector: 'tmc-callback',
  template: `<div class="flex size-full justify-center">
    <mat-spinner class="m-auto"></mat-spinner>
  </div>`,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatProgressSpinnerModule],
  styles: `
    :host {
      display: block;
      height: 100%;
    }
  `,
})
export class CallbackComponent {}
