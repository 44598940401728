<form class="flex grow flex-col" [formGroup]="form!">
  <h3>{{ 'USER_MANAGEMENT.VPN_PROFILES.VPN_PROFILE_FORM.PROFILE_NAME' | translate }}</h3>
  <div class="flex grow flex-row">
    <mat-form-field class="block w-4 grow">
      <input
        matInput
        formControlName="name"
        required
        [placeholder]="'USER_MANAGEMENT.VPN_PROFILES.VPN_PROFILE_FORM.PROFILE_NAME' | translate"
      />
    </mat-form-field>
  </div>

  <h3>{{ 'USER_MANAGEMENT.VPN_PROFILES.VPN_PROFILE_FORM.ACCESS_LEVELS' | translate }}</h3>
  <div class="flex flex-row justify-around">
    @for (level of getAccessLevels; track $index) {
      <div>
        <mat-checkbox color="primary" [formControl]="level">
          {{ accessLevels[$index].name | translate }}
        </mat-checkbox>
      </div>
    }
  </div>

  <h3>{{ 'USER_MANAGEMENT.VPN_PROFILES.VPN_PROFILE_FORM.IP_RANGES' | translate }}</h3>

  <div formArrayName="limitations" class="flex w-full table-auto flex-col overflow-auto">
    @for (limitation of limitations.controls; track $index) {
      <div class="flex grow flex-row">
        <div class="flex grow justify-around gap-[10px]" [formGroupName]="$index">
          <mat-form-field class="block w-1/4">
            <mat-select required formControlName="company" [placeholder]="'Company'">
              @if (getAccessLevels[0].value) {
                <mat-option [value]="'TMC'">TMC</mat-option>
              }
              @for (company of companies(); track company) {
                <mat-option [value]="company.externalId">{{ company.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field class="block w-1/4">
            <mat-select required formControlName="manufacturer" [placeholder]="'Manufacturer'">
              @for (manufacturer of manufacturers(); track manufacturer) {
                <mat-option [value]="manufacturer.externalId">{{ manufacturer.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field class="block w-1/4">
            <textarea
              matInput
              formControlName="ips"
              cdkTextareaAutosize
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="5"
              required
              [placeholder]="
                'USER_MANAGEMENT.VPN_PROFILES.VPN_PROFILE_FORM.LIST_OF_IPS' | translate
              "
            ></textarea>
          </mat-form-field>
          @if (limitations.length > 1) {
            <button mat-icon-button (click)="deleteLimitation($index)">
              <mat-icon style="color: #be1e2d">delete_forever</mat-icon>
            </button>
          }
        </div>
      </div>
    }
  </div>

  <div class="flex items-end justify-end">
    <button mat-button class="flex items-end justify-end" (click)="addLimitation()">
      <mat-icon>add</mat-icon>
      {{ 'USER_MANAGEMENT.VPN_PROFILES.VPN_PROFILE_FORM.ADD_ENTRY' | translate }}
    </button>
  </div>
</form>

<mat-card-actions class="items-center justify-end">
  <button
    mat-raised-button
    color="primary"
    class="uppercase"
    [routerLink]="['../']"
    [state]="{ vpnProfilesSelected: true }"
  >
    {{ 'COMMON.ACTIONS.CANCEL' | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    class="ml-[8px] uppercase"
    [disabled]="!form!.valid || !accessLevelsValid"
    (click)="saveConfiguration(form!.getRawValue())"
  >
    {{ 'COMMON.ACTIONS.CONFIRM' | translate }}
  </button>
</mat-card-actions>
