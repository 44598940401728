<div class="flex size-full flex-col">
  <header>
    <tmc-header></tmc-header>
  </header>
  <main class="mat-app-background flex-1 overflow-auto">
    <mat-sidenav-container class="size-full">
      <mat-sidenav #sidenav mode="side" class="drawer-sidenav flex w-1/5 flex-col" opened="false">
        <mat-accordion [multi]="false">
          @if (view()) {
            <mat-nav-list>
              <mat-list-item [routerLink]="['/home']">
                <mat-icon class="mr-1 h-5 text-white">home</mat-icon>
                <span class="text-sm font-bold text-white">{{ 'SIDEBAR.HOME' | translate }}</span>
              </mat-list-item>
              <mat-expansion-panel [expanded]="false">
                <mat-expansion-panel-header>
                  <mat-list-item [routerLink]="['/fleet-user-management/user-management']">
                    <mat-icon class="mr-1 h-5 text-white">group</mat-icon>
                    <span class="text-sm font-bold text-white">{{
                      'SIDEBAR.USER_MANAGEMENT' | translate
                    }}</span>
                  </mat-list-item>
                </mat-expansion-panel-header>
                <mat-list-item
                  class="border-t border-white"
                  [routerLink]="['/fleet-user-management/user-management/users']"
                >
                  <span class="text-sm font-bold text-white">{{
                    'USER_MANAGEMENT.LANDING.TILE.HEADING.USERS' | translate
                  }}</span>
                </mat-list-item>
                <mat-list-item
                  class="border-t border-white"
                  [routerLink]="['/fleet-user-management/user-management/groups']"
                >
                  <span class="text-sm font-bold text-white">{{
                    'USER_MANAGEMENT.LANDING.TILE.HEADING.GROUPS' | translate
                  }}</span>
                </mat-list-item>
                <mat-list-item
                  class="border-t border-white"
                  [routerLink]="['/fleet-user-management/user-management/remote-features-groups']"
                >
                  <span class="text-sm font-bold text-white">{{
                    'USER_MANAGEMENT.LANDING.TILE.HEADING.REMOTE_FEATURES_GROUPS' | translate
                  }}</span>
                </mat-list-item>
                @if (view() === 'vendor' || view() === 'manufacturer') {
                  <mat-list-item
                    class="border-t border-white"
                    [routerLink]="['/fleet-user-management/user-management/vpn-profiles']"
                  >
                    <span class="text-sm font-bold text-white">{{
                      'USER_MANAGEMENT.LANDING.TILE.HEADING.VPN_PROFILES' | translate
                    }}</span>
                  </mat-list-item>
                }
                @if (view() === 'vendor') {
                  <mat-list-item class="border-t border-white" [routerLink]="['/home']">
                    <span class="text-sm font-bold text-white">{{
                      'USER_MANAGEMENT.LANDING.TILE.HEADING.GHOST_USERS' | translate
                    }}</span>
                  </mat-list-item>
                }
              </mat-expansion-panel>
              @if (view() !== 'customer') {
                <mat-expansion-panel [expanded]="false">
                  <mat-expansion-panel-header>
                    <mat-list-item [routerLink]="['/fleet-user-management/company-management']">
                      <mat-icon class="mr-1 h-5 text-white">factory</mat-icon>
                      <span class="text-sm font-bold text-white">{{
                        'SIDEBAR.COMPANY_MANAGEMENT' | translate
                      }}</span>
                    </mat-list-item>
                  </mat-expansion-panel-header>
                  @if (view() === 'vendor') {
                    <mat-list-item
                      class="border-t border-white"
                      [routerLink]="['/fleet-user-management/company-management/manufacturers']"
                    >
                      <span class="text-sm font-bold text-white">{{
                        'COMPANY_MANAGEMENT.LANDING.TILE.HEADING.MANUFACTURERS' | translate
                      }}</span>
                    </mat-list-item>
                  }
                  @if (view() !== 'distributor') {
                    <mat-list-item
                      class="border-t border-white"
                      [routerLink]="['/fleet-user-management/company-management/distributors']"
                    >
                      <span class="text-sm font-bold text-white">{{
                        'COMPANY_MANAGEMENT.LANDING.TILE.HEADING.DISTRIBUTORS' | translate
                      }}</span>
                    </mat-list-item>
                  }
                  <mat-list-item
                    class="border-t border-white"
                    [routerLink]="['/fleet-user-management/company-management/customers']"
                  >
                    <span class="text-sm font-bold text-white">{{
                      'COMPANY_MANAGEMENT.LANDING.TILE.HEADING.CUSTOMERS' | translate
                    }}</span>
                  </mat-list-item>
                </mat-expansion-panel>
              }
              <mat-expansion-panel [expanded]="false">
                <mat-expansion-panel-header>
                  <mat-list-item [routerLink]="['/fleet-user-management/machine-management']">
                    <mat-icon class="mr-1 h-5 text-white">train</mat-icon>
                    <span class="text-sm font-bold text-white">{{
                      'SIDEBAR.MACHINE_MANAGEMENT' | translate
                    }}</span>
                  </mat-list-item>
                </mat-expansion-panel-header>
                <mat-list-item
                  class="border-t border-white"
                  [routerLink]="['/fleet-user-management/machine-management/machines']"
                >
                  <span class="text-sm font-bold text-white">{{
                    'MACHINE_MANAGEMENT.LANDING.HEADING.MACHINES' | translate
                  }}</span>
                </mat-list-item>
                <mat-list-item
                  class="border-t border-white"
                  [routerLink]="['/fleet-machine-management/maintenance']"
                >
                  <span class="text-sm font-bold text-white">{{
                    'MACHINE_MANAGEMENT.LANDING.HEADING.MAINTENANCE' | translate
                  }}</span>
                </mat-list-item>
                <mat-list-item
                  class="border-t border-white"
                  [routerLink]="['/fleet-user-management/machine-management/geo-fence-management']"
                >
                  <span class="text-sm font-bold text-white">{{
                    'MACHINE_MANAGEMENT.LANDING.HEADING.GEO_FENCE_MANAGEMENT' | translate
                  }}</span>
                </mat-list-item>
                <mat-list-item
                  class="border-t border-white"
                  [routerLink]="['/fleet-user-management/machine-management/mdc-management']"
                >
                  <span class="text-sm font-bold text-white">{{
                    'MACHINE_MANAGEMENT.LANDING.HEADING.MDC_MANAGEMENT' | translate
                  }}</span>
                </mat-list-item>
                <mat-list-item
                  class="border-t border-white"
                  [routerLink]="['/fleet-user-management/machine-management/signals']"
                >
                  <span class="text-sm font-bold text-white">{{
                    'MACHINE_MANAGEMENT.LANDING.HEADING.SIGNALS' | translate
                  }}</span>
                </mat-list-item>
              </mat-expansion-panel>
              <mat-list-item [routerLink]="['/fleet-user-management/configuration-management']">
                <mat-icon class="mr-1 h-5 text-white">warehouse</mat-icon>
                <span class="text-sm font-bold text-white">{{
                  'SIDEBAR.CONFIGURATION_MANAGEMENT' | translate
                }}</span>
              </mat-list-item>
              <mat-expansion-panel [expanded]="false">
                <mat-expansion-panel-header>
                  <mat-list-item [routerLink]="['/fleet-user-management/api-key-management']">
                    <mat-icon class="mr-1 h-5 text-white">key</mat-icon>
                    <span class="text-sm font-bold text-white">{{
                      'SIDEBAR.API_KEYS' | translate
                    }}</span>
                  </mat-list-item>
                </mat-expansion-panel-header>
                <mat-list-item
                  class="border-t border-white"
                  [routerLink]="['/fleet-user-management/api-key-management/owned-keys']"
                >
                  <span class="text-sm font-bold text-white">{{
                    'API_KEYS_MANAGEMENT.LANDING.HEADING.OWNED' | translate
                  }}</span>
                </mat-list-item>
                <mat-list-item
                  class="border-t border-white"
                  [routerLink]="['/fleet-user-management/api-key-management/managed-keys']"
                >
                  <span class="text-sm font-bold text-white">{{
                    'API_KEYS_MANAGEMENT.LANDING.HEADING.MANAGED' | translate
                  }}</span>
                </mat-list-item>
              </mat-expansion-panel>
              <mat-expansion-panel [expanded]="false">
                <mat-expansion-panel-header>
                  <mat-list-item [routerLink]="['/fleet-user-management/logging']">
                    <mat-icon class="mr-1 h-5 text-white">computer</mat-icon>
                    <span class="text-sm font-bold text-white">{{
                      'SIDEBAR.MONITORING' | translate
                    }}</span>
                  </mat-list-item>
                </mat-expansion-panel-header>
                <mat-list-item
                  class="border-t border-white"
                  [routerLink]="['/fleet-user-management/system-logs']"
                >
                  <span class="text-sm font-bold text-white">{{
                    'SIDEBAR.SYSTEMS_LOGS' | translate
                  }}</span>
                </mat-list-item>
              </mat-expansion-panel>
            </mat-nav-list>
          }
        </mat-accordion>
      </mat-sidenav>

      <mat-sidenav-content class="overflow-hidden">
        <div class="p-lg">
          <tmc-breadcrumb></tmc-breadcrumb>
          <tmc-title></tmc-title>
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
    @if (!view()) {
      <tmc-progress-spinner></tmc-progress-spinner>
    }
  </main>
</div>
