import {
  Component,
  effect,
  inject,
  input,
  output,
  viewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Group, User } from '@tmc/mco-user-management-api';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { Pagination, UserEmail } from '@tmc/fleet-core-api';
import { DialogService, TruncateTooltipDirective } from '@tmc/core-ui';
import {
  DELAY_VALUES,
  IListDialogConfig,
  ListDialogComponent,
  RemovableListDialogData,
} from '@tmc/fleet-core-ui';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-groups-list',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTooltipModule,
    TranslateModule,
    MatSortModule,
    TruncateTooltipDirective,
  ],
  templateUrl: './groups-list.component.html',
  styleUrls: ['../../../../../fleet-core/ui/src/lib/styles/lists.scss'],
})
export class GroupsListComponent {
  private readonly dialogService = inject(DialogService);
  dataSource!: MatTableDataSource<Group>;
  pageSizeOptions = Pagination.PAGE_SIZE_OPTIONS;
  delayValues = DELAY_VALUES;
  paginator = viewChild.required(MatPaginator);
  sort = viewChild.required(MatSort);
  displayedColumns = ['name', 'users', 'ACTIONS'];
  groups = input.required<Group[] | undefined>();
  deleteGroupEvent = output<Group>();
  openAddUserToGroup = output<Group>();
  openRemoveUserFromGroup = output<{ user: User; group: Group }>();
  removeUserEmailFromGroup = output<{
    userEmail: string;
    group: Group;
  }>();
  constructor() {
    effect(() => {
      if (this.groups()) {
        this.dataSource = new MatTableDataSource(this.groups());
        this.dataSource.sort = this.sort();
        this.dataSource.paginator = this.paginator();
      }
    });
  }

  addUserToGroup(group: Group) {
    this.openAddUserToGroup.emit(group);
  }

  removeUserFromGroup(user: User, group: Group) {
    this.openRemoveUserFromGroup.emit({ user, group });
  }

  deleteGroup(group: Group) {
    this.deleteGroupEvent.emit(group);
  }

  openAllAdminsDialog(group: Group) {
    const config: IListDialogConfig = {
      data: [
        {
          option: 'admins',
          list: group.users.map((admin: UserEmail) => ({
            value: admin.email,
            isRemovable: true,
          })),
        },
      ],
    };
    const dialogRef = this.createListDialog(config);
    dialogRef.afterClosed().subscribe((toBeRemovedItems: RemovableListDialogData[]) => {
      if (!toBeRemovedItems) {
        return;
      }
      this.removeUsers(group, toBeRemovedItems);
    });
  }

  private createListDialog(config: IListDialogConfig) {
    return this.dialogService.open(ListDialogComponent, config, config.autoFocus, config.minWidth);
  }

  private removeUsers(group: Group, toBeRemovedItems: RemovableListDialogData[]) {
    toBeRemovedItems[0]?.list.forEach((toBeRemovedItem: string) => {
      this.removeUserEmailFromGroup.emit({ userEmail: toBeRemovedItem, group });
    });
  }
}
