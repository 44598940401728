import { CoreConfiguration } from './core-configuration.model';

export const DEFAULT_CONFIGURATION: CoreConfiguration = {
  defaultLocale: 'en',
  httpRetryCount: 3,
  httpRetryTimeout: 0,
  themes: {
    tmc: {
      primary: '#237881',
      secondary: '#f59c00',
    },
  },
  typographies: {
    header: {
      family: 'roboto',
      weight: 500,
      size: 20,
    },
    subtitle: {
      family: 'roboto',
      weight: 500,
      size: 14,
    },
    body: {
      family: 'roboto',
      weight: 400,
      size: 14,
    },
    caption: {
      family: 'roboto',
      weight: 400,
      size: 12,
    },
  },
};
