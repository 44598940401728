@if (breadcrumbs.length > 0) {
  <div class="flex items-center">
    <span class="flex cursor-pointer items-center text-sm text-gray-500" [routerLink]="['/home']">
      {{ 'COMMON.ADMINISTRATION' | translate }}
    </span>
    @for (breadcrumb of breadcrumbs; track breadcrumb; let last = $last) {
      <ng-container>
        <div
          class="flex cursor-pointer items-center text-sm text-gray-500"
          [routerLink]="[breadcrumb.url]"
        >
          <mat-icon class="mx-2 scale-75">arrow_forward_ios</mat-icon>
          <span [ngClass]="{ 'text-primary': last }">{{ breadcrumb.label | translate }}</span>
        </div>
      </ng-container>
    }
  </div>
}
