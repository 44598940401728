import { Component, ChangeDetectionStrategy, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HeaderLogoComponent } from './logo/header-logo.component';
import { HeaderLanguageComponent } from './language/header-language.component';
import { HeaderThemeComponent } from './theme/header-theme.component';
import { HeaderProfileComponent } from './profile/header-profile.component';

@Component({
  selector: 'tmc-header',
  templateUrl: './header.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    HeaderLogoComponent,
    HeaderLanguageComponent,
    HeaderThemeComponent,
    HeaderProfileComponent,
  ],
})
export class HeaderComponent {
  toggleSidenav = output();
}
