import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  output,
  viewChild,
} from '@angular/core';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { Pagination, Machine } from '@tmc/fleet-core-api';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogService, TruncateTooltipDirective } from '@tmc/core-ui';
import { ListDialogComponent, DELAY_VALUES, IListDialogConfig } from '@tmc/fleet-core-ui';
import { switchMap } from 'rxjs';

interface ListData {
  id: string;
  name: string;
}

@Component({
  selector: 'tmc-list-machines',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    TranslateModule,
    MatPaginatorModule,
    MatIconModule,
    MatSortModule,
    MatTooltipModule,
    ListDialogComponent,
    UpperCasePipe,
    MatMenuModule,
    TruncateTooltipDirective,
  ],
  templateUrl: './list-machines.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListMachinesComponent {
  private readonly dialogService = inject(DialogService);
  private readonly translate = inject(TranslateService);
  removeSingleMachine = output<string>();
  openForm = output<Machine>();
  machines = input.required<Machine[] | undefined>();
  dataSource!: MatTableDataSource<Machine>;
  sort = viewChild.required(MatSort);
  paginator = viewChild.required(MatPaginator);
  delayValues = DELAY_VALUES;
  displayedColumns = [
    'displayId',
    'name',
    'region.name',
    'manufacturer.name',
    'distributor',
    'customers',
    'status',
    'license',
    'version',
    'modules',
    'actions',
  ];
  pageSizeOptions = Pagination.PAGE_SIZE_OPTIONS;

  constructor() {
    effect(() => {
      this.dataSource = new MatTableDataSource(this.machines());
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'region.name':
            return item.region.name;
          case 'manufacturer.name':
            return item.manufacturer ? item.manufacturer.name : '';
          default:
            return String(item[property as keyof Machine]);
        }
      };
      this.dataSource.sort = this.sort();
      this.dataSource.paginator = this.paginator();
    });
  }

  showTableDialog(listData: ListData[], heading: string) {
    const config: IListDialogConfig = {
      data: [
        {
          option: '',
          list: listData.map((data: ListData) => ({
            value: data.name,
            isRemovable: false,
          })),
        },
      ],
    };
    config.heading = heading;

    return this.dialogService.open(ListDialogComponent, config);
  }

  deleteMachine(machine: Machine) {
    this.translate
      .get('MACHINE_MANAGEMENT.ARE_YOU_SURE_REMOVE_TO_REMOVE_MACHINE', { element: machine.name })
      .pipe(
        switchMap((message: string) =>
          this.dialogService
            .openConfirmationDialog({
              title: machine.name,
              confirmationQuestion: message,
            })
            .afterClosed(),
        ),
      )
      .subscribe((response) => {
        if (response ?? false) {
          const removeMachine = machine.externalId;
          this.removeSingleMachine.emit(removeMachine);
        }
      });
  }

  openMachineForm(machine: Machine) {
    this.openForm.emit(machine);
  }
}
