import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import { Machine } from './machine.model';
import { FleetMachineDataEntry } from './machine-data/machine-data.model';
import { PaginatedResult } from '../models/paginated-result.model';

@Injectable({
  providedIn: 'root',
})
export class MachineService {
  private readonly http = inject(HttpClient);

  private readonly transform = (machine: Machine): Machine => ({
    ...machine,
    offsetHours: Math.trunc(machine.utcOffsetMinutes / 60),
    offsetMinutes: Math.abs(machine.utcOffsetMinutes % 60),
  });

  getAll(): Promise<Machine[]> {
    return lastValueFrom(
      this.http
        .get<PaginatedResult<Machine>>(`/fleet-admin/machines`)
        .pipe(map((machines: PaginatedResult<Machine>) => machines.items.map(this.transform))),
    );
  }

  create(query: FleetMachineDataEntry): Promise<Machine> {
    return lastValueFrom(this.http.post<Machine>('/fleet-admin/machines', query, {}));
  }

  update(externaId: string, query: FleetMachineDataEntry): Promise<Machine> {
    return lastValueFrom(this.http.put<Machine>(`/fleet-admin/machines/${externaId}`, query, {}));
  }

  getMachineData(externaId: string): Promise<Machine> {
    return firstValueFrom(this.http.get<Machine>(`/fleet-admin/machines/${externaId}`));
  }
  delete(machineId: string) {
    return this.http.delete(`/fleet-admin/machines/${machineId}`);
  }
}
