import { Injectable, inject } from '@angular/core';
import { Company, CompanyService, Machine, MachineService } from '@tmc/fleet-core-api';
import { Observable, from, of } from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AdminAppUserDetailsService } from '@tmc/fleet-core-feature';
import { Application } from '../application/application.model';
import { ApplicationService } from '../application/application.service';
import { ScopeType } from '../scope-type/scope-type.model';
import { ScopeTypeService } from '../scope-type/scope-type.service';
import { CreatePermissionFormService } from './create-permission-form-service.interface';

@Injectable({
  providedIn: 'root',
})
export class FleetManagementPermissionsFormService implements CreatePermissionFormService {
  companyService = inject(CompanyService);
  machineService = inject(MachineService);
  applicationService = inject(ApplicationService);
  scopeTypeService = inject(ScopeTypeService);
  adminAppUserDetail = inject(AdminAppUserDetailsService);

  getView(): Observable<string> {
    return of(this.adminAppUserDetail.view());
  }
  getCustomers(): Observable<Company[]> {
    return from(this.companyService.getAll('customers'));
  }
  getDistributors(): Observable<Company[]> {
    return from(this.companyService.getAll('distributors'));
  }
  getManufacturers(): Observable<Company[]> {
    return from(this.companyService.getAll('manufacturers'));
  }
  getMachines(): Observable<Machine[]> {
    return from(this.machineService.getAll());
  }
  getScopes(): Observable<ScopeType[]> {
    return from(this.scopeTypeService.getAll());
  }
  getApplications(isRemoteFeaturesGroup?: boolean | undefined): Observable<Application[]> {
    return from(this.applicationService.getAll(isRemoteFeaturesGroup ?? false));
  }
}
