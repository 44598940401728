@if (areCompaniesNotAvailable()) {
  <tmc-progress-spinner></tmc-progress-spinner>
} @else {
  <div class="box-border h-full">
    <div class="flex justify-end">
      <div class="my-sm flex">
        @if (view === 'manufacturer' || view === 'vendor') {
          <button
            mat-button
            class="flex items-center text-base font-semibold text-primary"
            (click)="openCreateNewCompany()"
          >
            <mat-icon class="mr-1" matPrefix>add</mat-icon>
            {{ 'COMMON.ACTIONS.ADD' | translate }}
          </button>
        }
        <tmc-table-search-box [(filterCriteria)]="listFilter"></tmc-table-search-box>
      </div>
    </div>
  </div>
  @if (companies()?.length === 0) {
    <div class="m-xl flex">
      <span>{{ 'COMPANY_MANAGEMENT.LIST.NO_COMPANIES' | translate }}</span>
    </div>
  } @else {
    <tmc-list-companies
      [companies]="filteredCompanies()"
      [layerPath]="layerPath"
      [manufacturers]="allManufacturers()?.data"
      [distributors]="allDistributors()?.data"
      [customers]="allCustomers()?.data"
      [regions]="regions()"
      [view]="view"
      (removeSingleCompany)="removeCompany($event)"
      (toBeRemovedAssignedCompanies)="removeCompaniesFromDialog($event)"
      (updateCompanyRelationsEvent)="updateCompany($event)"
    ></tmc-list-companies>
  }
}
