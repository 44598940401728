/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, signal, ChangeDetectionStrategy, inject } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { TextSelectOption, TextSelectComponent, DialogService } from '@tmc/core-ui';
import {
  Region,
  Country,
  UpdateService,
  Company,
  CompanyService,
  CompanySync,
  CompanyDataSync,
} from '@tmc/fleet-core-api';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import countries from 'typed-countries';
import { HttpErrorResponse } from '@angular/common/http';
import { FleetPermissionService, UserService } from '@tmc/mco-user-management-api';

interface NewCompany {
  externalId: string;
  companyName: string;
  region: TextSelectOption<string>;
  countryIso: string;
  parentCompany: string;
  isPartner: boolean;
  isDistributor: boolean;
  isManufacturer: boolean;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-company-configuration-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    RouterModule,
    TextSelectComponent,
    TranslateModule,
  ],
  providers: [CompanyService, UserService, FleetPermissionService, UpdateService, DialogService],
  templateUrl: './company-configuration-form.component.html',
})
export class CompanyConfigurationFormComponent {
  private readonly router = inject(Router);
  private readonly formBuilder = inject(FormBuilder);
  private readonly route = inject(ActivatedRoute);
  private readonly companyService = inject(CompanyService);
  private readonly userService = inject(UserService);
  private readonly dialogService = inject(DialogService);
  private readonly location = inject(Location);
  intervalForm!: FormGroup;
  emailInput!: string;
  emails: string[] = [];
  regions: Region[] = [];
  countries: Country[] = [];
  parentCompanies: Company[] = [];
  countriesOptions!: { value: string; label: string }[];
  parentCompaniesOptions = signal<{ value: string; label: string }[]>([]);
  regionsOptions!: { value: string; label: string }[];
  selectedCompany!: CompanySync;
  selectedCountryName!: string | null;
  selectedRegionName!: string | null;

  manufacturers!: Company[];
  distributors!: Company[];
  customers!: Company[];
  currentNavigation = this.router.getCurrentNavigation();

  parentType = '';
  parentCompanyTypeOptions = [
    { value: 'Manufacturer', label: 'Manufacturer' },
    { value: 'Distributor', label: 'Distributor' },
  ];

  layerPath = (this.route.routeConfig?.path ?? '').split('/')[0];
  constructor() {
    this.initializeComponent();
  }

  loadCompaniesData(url: string): void {
    if (this.currentNavigation?.extras?.state) {
      this.manufacturers = this.currentNavigation.extras.state['manufacturers'];
      this.distributors = this.currentNavigation.extras.state['distributors'];
      this.customers = this.currentNavigation.extras.state['customers'];
    }
    if (url === 'distributors' && this.manufacturers.length) {
      this.parentCompaniesOptions.set(this.mapCompany(this.manufacturers));
    } else if (url === 'customers' && this.distributors.length) {
      this.parentCompaniesOptions.set(this.mapCompany(this.distributors));
    }
  }
  mapCompany(companies: Company[]): { value: string; label: string }[] {
    return companies.map((company: Company) => ({
      value: company.id.toString(),
      label: company.name,
    }));
  }

  loadRegionsAndCountriesData(): void {
    this.countries = this.getAllCountries();
    this.mapCountriesToOptions();
  }

  getAllCountries(): Country[] {
    return countries;
  }

  initForm() {
    this.intervalForm = this.formBuilder.group({
      externalId: ['', Validators.required],
      companyName: ['', Validators.required],
      isPartner: [false],
      isManufacturer: [false],
      isDistributor: [false],
      region: ['', Validators.required],
      regionId: [null],
      countryIso: [null, Validators.required],
      parentType: [''],
      parentCompany: [''],
    });
    if (this.layerPath === 'customers') {
      this.intervalForm.get('parentType')?.setValidators([Validators.required]);
      this.intervalForm.get('parentCompany')?.setValidators([Validators.required]);
    } else if (this.layerPath === 'distributors') {
      this.intervalForm.get('parentCompany')?.setValidators([Validators.required]);
    }
  }

  initFormExistingCompany() {
    const selectedCountry = this.countries.find(
      (country) => country.iso === this.selectedCompany.countryCode,
    );
    const selectedRegion = this.regions.find(
      (region) => region.id === this.selectedCompany.regionId,
    );
    this.selectedCountryName = selectedCountry?.name ?? null;
    this.selectedRegionName = selectedRegion?.name ?? null;
    this.intervalForm = this.formBuilder.group({
      emailInput: ['', Validators.email],
      externalId: [this.selectedCompany.externalId],
      companyName: [this.selectedCompany.name],
      isPartner: [this.selectedCompany.isPartner],
      isDistributor: [this.selectedCompany.isDistributor],
      countryIso: [this.selectedCountryName],
      region: [this.selectedRegionName],
      regionId: [this.selectedCompany.regionId],
      isManufacturer: [this.selectedCompany.isManufacturer],
    });

    if (this.layerPath === 'manufacturers' && (this.selectedCompany.isDistributor ?? false)) {
      this.intervalForm.get('isDistributor')?.disable();
    }
    if (this.layerPath === 'distributors' && (this.selectedCompany.isManufacturer ?? false)) {
      this.intervalForm.get('isManufacturer')?.disable();
    }

    if (this.selectedCompany.adminUsers.length && Array.isArray(this.selectedCompany.adminUsers)) {
      this.emails = this.selectedCompany.adminUsers.map((admin: { email: string }) => admin.email);
    }
  }

  handleSelectedRegion(selection: TextSelectOption<string> | null) {
    const selectedRegionId = selection ? parseInt(selection.value, 10) : null;
    this.intervalForm.get('regionId')?.setValue(selectedRegionId);
    this.intervalForm.get('region')?.setValue(selection);
  }

  handleSelectedCountry(selection: TextSelectOption<string> | null) {
    const selectedCountry = selection?.value ?? '';
    this.intervalForm.get('countryIso')?.setValue(selectedCountry);
  }

  parentCompanySelected(selection: TextSelectOption<string> | null) {
    const selectedParentCompany = selection?.value ?? '';
    this.intervalForm.get('parentCompany')?.setValue(selectedParentCompany);
  }

  parentTypeSelected(selection: TextSelectOption<string> | null) {
    this.parentType = selection?.label ?? '';
    this.intervalForm.get('parentType')?.setValue(this.parentType);

    const selectedParentType = selection?.label ?? '';
    this.intervalForm.get('parentType')?.setValue(selection?.value);
    if (selectedParentType === 'Manufacturer') {
      this.parentCompaniesOptions.set(
        this.manufacturers?.map((company: Company) => ({
          value: company.id.toString(),
          label: company.name,
        })) ?? [],
      );
    } else if (selectedParentType === 'Distributor') {
      this.parentCompaniesOptions.set(
        this.distributors?.map((company: Company) => ({
          value: company.id.toString(),
          label: company.name,
        })) ?? [],
      );
    }
  }

  addEmail() {
    const email = this.intervalForm.get('emailInput')?.value as string;
    if (email && !this.emails.includes(email)) {
      this.addAdmin(email);
    }
    this.intervalForm.get('emailInput')?.setValue('');
  }

  async removeEmail(email: string) {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
    await this.removeAdmin(email);
  }

  isFormInitialized(): boolean {
    return this.intervalForm !== undefined;
  }
  newCompany() {
    const data: NewCompany = this.intervalForm.value;

    const body: CompanyDataSync = {
      external_id: data.externalId.toUpperCase(),
      name: data.companyName,
      region_id: Number(data.region.value),
      country_code: data.countryIso.toUpperCase(),
      parent_company: data.parentCompany,
      is_partner: data.isPartner,
      is_distributor: data.isDistributor,
      is_manufacturer: data.isManufacturer,
    };
    this.companyService
      .create(this.layerPath, body)
      .then(() => {
        this.location.back();
        return true;
      })
      .catch((err: unknown) => {
        if ((err as HttpErrorResponse).status === 409) {
          this.dialogService.openSnackBar({ message: 'ERROR.COMPANY_ID_ALREADY_EXISTS' });
        }
      });
  }

  editCompany() {
    const data: {
      companyName: string;
      region: TextSelectOption<string>;
      countryIso: string;
      isManufacturer: boolean;
      isDistributor: boolean;
    } = this.intervalForm.value;
    const body: {
      name: string;
      region_id: number;
      country_code: string;
      is_manufacturer?: boolean;
      is_distributor?: boolean;
    } = {
      name: data.companyName,
      region_id: +data.region.value,
      country_code: data.countryIso.toUpperCase(),
    };
    if (this.layerPath === 'distributors') {
      body.is_manufacturer = data.isManufacturer;
    }
    if (this.layerPath === 'manufacturers') {
      body.is_distributor = data.isDistributor;
    }
    this.companyService
      .edit(this.layerPath, this.selectedCompany.externalId, body)
      .then(() => {
        this.location.back();
        return true;
      })
      .catch(() => {
        this.dialogService.openSnackBar({ message: 'ERROR.ERROR_OCCURRED' });
      });
  }

  addAdmin(email: string) {
    this.userService
      .create(email, this.getCompanyOptions())
      .then(() => {
        this.emails.push(email);
      })
      .catch((err: unknown) => {
        if ((err as HttpErrorResponse).status === 409) {
          this.dialogService.openSnackBar({ message: 'COMMON.EXISTS' });
        }
      });
  }

  async removeAdmin(email: string) {
    await this.userService.delete(email, this.getCompanyOptions()).then(() => {
      this.dialogService.openSnackBar({ message: email, acceptButton: 'COMMON.REMOVED' });
      return true;
    });
  }

  getCompanyOptions() {
    const companyType = `${this.layerPath.slice(0, -1)}_id`;
    const options: Record<string, string | number> = {};
    options[companyType] = this.selectedCompany.id;
    return options;
  }

  protected mapRegionsToOptions(): void {
    this.regionsOptions = this.regions.map((region: Region) => ({
      value: region.id.toString(),
      label: region.name,
    }));
  }

  protected mapCountriesToOptions(): void {
    this.countriesOptions = this.countries.map((country: Country) => ({
      value: country.iso.toString(),
      label: country.name,
    }));
  }

  private initializeComponent(): void {
    this.loadCompaniesData(this.layerPath);

    this.route.params.subscribe((params) => {
      const externalId = params['external_id'];
      if (this.currentNavigation?.extras?.state) {
        this.selectedCompany = this.currentNavigation.extras.state['selectedCompanyData'];
        this.regions = this.currentNavigation.extras.state['regions'];
        this.mapRegionsToOptions();
      }

      this.loadRegionsAndCountriesData();

      if (externalId !== undefined && this.selectedCompany !== undefined) {
        this.initFormExistingCompany();
      } else {
        this.initForm();
      }
    });
  }
}
