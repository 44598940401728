import { CommonModule } from '@angular/common';
import {
  Component,
  inject,
  viewChild,
  input,
  output,
  computed,
  effect,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DialogService, TruncateTooltipDirective } from '@tmc/core-ui';
import { Pagination } from '@tmc/fleet-core-api';
import { DELAY_VALUES } from '@tmc/fleet-core-ui';
import { VpnProfile } from '@tmc/mco-user-management-api';
import { TranslateModule } from '@ngx-translate/core';
import { VpnProfileLimitationsComponent } from './vpn-profile-limitations/vpn-profile-limitations.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-vpn-profiles-list',
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatTableModule,
    MatIconModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSortModule,
    MatProgressSpinnerModule,
    RouterModule,
    TranslateModule,
    TruncateTooltipDirective,
  ],
  templateUrl: './vpn-profiles-list.component.html',
  styleUrls: ['../../../../../fleet-core/ui/src/lib/styles/lists.scss'],
})
export class VpnProfilesListComponent {
  private readonly dialogService = inject(DialogService);

  dataSource!: MatTableDataSource<VpnProfile>;
  displayedColumns = [
    'name',
    'isVendor',
    'isManufacturer',
    'isDistributor',
    'limitations',
    'ACTIONS',
  ];
  delayValues = DELAY_VALUES;
  pageSizeOptions = Pagination.PAGE_SIZE_OPTIONS;

  sort = viewChild.required(MatSort);
  paginator = viewChild.required(MatPaginator);

  profiles = input.required<VpnProfile[] | undefined>();
  deleteProfileEvent = output<VpnProfile>();
  view = input<string>();
  isVendor = computed<boolean>(() => this.view() === 'vendor');

  constructor() {
    effect(() => {
      if (this.profiles) {
        this.dataSource = new MatTableDataSource(this.profiles());
        this.dataSource.sort = this.sort();
        this.dataSource.paginator = this.paginator();
      }
    });
  }

  deleteProfile(profile: VpnProfile) {
    this.deleteProfileEvent.emit(profile);
  }

  showLimitations(profile: VpnProfile) {
    const config: MatDialogConfig = new MatDialogConfig();
    config.data = {
      limitations: profile.limitations,
      isVendor: this.isVendor(),
    };
    config.autoFocus = false;
    config.closeOnNavigation = true;
    config.minWidth = '70vw';
    this.dialogService.dialog.open(VpnProfileLimitationsComponent, config);
  }
}
