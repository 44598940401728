import { Route } from '@angular/router';
import { FleetUserManagementModule } from '@tmc/mco-user-management-feature';
import { HomeModule } from '@tmc/home';
import { FleetCompanyManagementModule } from '@tmc/fleet-company-management-feature';
import { FleetMachineManagementModule } from '@tmc/fleet-machine-management-feature';

export const ADMIN_APP_ROUTES: Route[] = [
  {
    path: 'home',
    children: [
      {
        path: '',
        children: [{ path: '', loadChildren: () => HomeModule }],
      },
    ],
  },
  {
    path: 'fleet-user-management',
    children: [
      {
        path: 'user-management',
        children: [{ path: '', loadChildren: () => FleetUserManagementModule }],
      },
      {
        path: 'company-management',
        children: [{ path: '', loadChildren: () => FleetCompanyManagementModule }],
      },
      {
        path: 'machine-management',
        children: [{ path: '', loadChildren: () => FleetMachineManagementModule }],
      },
    ],
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];
