import { inject } from '@angular/core';
import { HttpErrorResponse, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { retry, timer } from 'rxjs';
import { CoreConfiguration } from '../configuration/core-configuration.model';

/**
 * Angular http interceptor for retrying failed requests.
 * For details on configuration see {@link CoreConfiguration}
 */
export function retryInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn) {
  const config = inject(CoreConfiguration);
  return next(request).pipe(
    retry({
      count: config.httpRetryCount,
      delay: (error: HttpErrorResponse) => shouldRetry(error, config.httpRetryTimeout),
    }),
  );
}

function shouldRetry(error: HttpErrorResponse, httpRetryTimeout: number) {
  if (error.status >= 500) {
    return timer(httpRetryTimeout);
  }
  throw new Error(error.message);
}
