<div class="max-h-table flex flex-col">
  <div class="relative my-5 grow overflow-y-auto">
    <mat-table
      class="w-full table-fixed"
      matSort
      multiTemplateDataRows
      aria-label="List of companies"
      [dataSource]="dataSource"
    >
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse p-2 px-6 py-3 text-left leading-5"
          mat-sort-header="externalId"
          >{{ 'COMMON.TABLE_HEADERS.ID' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let company" class="truncate p-2 px-6 text-left"
          ><span tmcTruncateTooltip>{{ company.externalId }}</span></mat-cell
        >
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse p-2 text-left leading-5"
          mat-sort-header="name"
          >{{ 'COMMON.TABLE_HEADERS.NAME' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let company" class="text-left">{{ company.name }}</mat-cell>
      </ng-container>

      <!-- Number of Machines Column -->
      <ng-container matColumnDef="machine">
        <mat-header-cell *matHeaderCellDef class="border-collapse p-2 text-left leading-5">{{
          'COMMON.TABLE_HEADERS.MACHINES' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let company" class="w-10 border-collapse p-2 text-center">{{
          company.machines || 0
        }}</mat-cell>
      </ng-container>

      <!-- Country Column -->
      <ng-container matColumnDef="country">
        <mat-header-cell *matHeaderCellDef class="border-collapse p-2 text-left leading-5">{{
          'COMMON.TABLE_HEADERS.COUNTRY' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let company" class="border-collapse truncate p-2 text-center">{{
          company.countryCode
        }}</mat-cell>
      </ng-container>
      @if (layerPath() !== 'customers') {
        <ng-container matColumnDef="companies">
          <mat-header-cell *matHeaderCellDef class="border-collapse p-2 text-left leading-5">
            <span>{{ 'COMPANY_MANAGEMENT.COMPANIES' | translate }}</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let company">
            @if (company.allRelatedCompanies && company.allRelatedCompanies.length > 0) {
              <mat-chip-listbox class="w-full space-x-2">
                @if (layerPath() === 'manufacturers') {
                  <mat-chip
                    class="relative grid items-center truncate"
                    matTooltip="{{ this.getOptionList(company, 'distributors').length }} {{
                      'COMPANY_MANAGEMENT.DISTRIBUTORS' | translate
                    }}"
                  >
                    <span
                      translate
                      [translateParams]="{
                        element: this.getOptionList(company, 'distributors').length || 0,
                      }"
                      >COMPANY_MANAGEMENT.LIST.DISTRIBUTORS</span
                    >
                  </mat-chip>
                  <mat-chip
                    class="relative grid items-center truncate"
                    matTooltip="{{ this.getOptionList(company, 'partner distributors').length }} {{
                      'COMPANY_MANAGEMENT.PARTNER_DISTRIBUTORS' | translate
                    }} "
                  >
                    <span
                      translate
                      [translateParams]="{
                        element: this.getOptionList(company, 'partner distributors').length || 0,
                      }"
                      >COMPANY_MANAGEMENT.LIST.PARTNER_DISTRIBUTORS</span
                    >
                  </mat-chip>
                } @else {
                  <mat-chip
                    class="relative grid items-center truncate"
                    matTooltip="{{ this.getOptionList(company, 'manufacturers').length }} {{
                      'COMPANY_MANAGEMENT.MANUFACTURERS' | translate
                    }}"
                  >
                    <span
                      translate
                      [translateParams]="{
                        element: this.getOptionList(company, 'manufacturers').length || 0,
                      }"
                      >COMPANY_MANAGEMENT.LIST.MANUFACTURERS</span
                    >
                  </mat-chip>
                  <mat-chip
                    class="relative grid items-center truncate"
                    matTooltip="{{ this.getOptionList(company, 'partner manufacturers').length }} {{
                      'COMPANY_MANAGEMENT.PARTNER_MANUFACTURERS' | translate
                    }}"
                  >
                    <span
                      translate
                      [translateParams]="{
                        element: this.getOptionList(company, 'partner manufacturers').length || 0,
                      }"
                      >COMPANY_MANAGEMENT.LIST.PARTNER_MANUFACTURERS</span
                    >
                  </mat-chip>
                }
                @if (layerPath() !== 'customers') {
                  <mat-chip
                    class="relative grid items-center truncate"
                    matTooltip="{{ company.customers?.length || 0 }} {{
                      'COMPANY_MANAGEMENT.CUSTOMERS' | translate
                    }}"
                  >
                    <span
                      translate
                      [translateParams]="{
                        element: this.getOptionList(company, 'customers').length,
                      }"
                      >COMPANY_MANAGEMENT.LIST.CUSTOMERS</span
                    >
                  </mat-chip>
                  <mat-chip
                    class="relative grid items-center truncate"
                    [matTooltip]="'COMMON.DIALOGS.OPEN_DETAIL' | translate"
                    (click)="openRelatedCompaniesDialog(company)"
                  >
                    <span> ... </span>
                  </mat-chip>
                } @else {
                  <mat-chip class="relative grid items-center truncate" [matTooltip]="company.name">
                    <span> company.name </span>
                  </mat-chip>
                }
              </mat-chip-listbox>
            } @else {
              <span class="flex items-center justify-center text-center"> - </span>
            }
          </mat-cell>
        </ng-container>
      }
      <!-- Admins Column -->
      <ng-container matColumnDef="admins">
        <mat-header-cell *matHeaderCellDef class="border-collapse p-2 text-left leading-5">
          <span>{{ 'COMPANY_MANAGEMENT.ADMINS' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let company">
          @if (company.adminUsers && company.adminUsers.length > 0) {
            <mat-chip-listbox class="w-full space-x-2">
              @for (admin of company.adminUsers.slice(0, 2); track admin) {
                <mat-chip class="relative grid items-center truncate">
                  <span> {{ admin.original_email }} </span>
                </mat-chip>
              }
              @if (company.adminUsers.length > 2) {
                <mat-chip
                  class="relative grid items-center truncate"
                  [matTooltip]="'COMMON.DIALOGS.OPEN_DETAIL' | translate"
                  (click)="openAllAdminsDialog(company)"
                >
                  <span> ... </span>
                </mat-chip>
              }
            </mat-chip-listbox>
          } @else {
            <span class="flex-col items-center justify-center text-center"> - </span>
          }
        </mat-cell>
      </ng-container>

      <!-- Actions Column -->
      @if (this.view() === 'vendor') {
        <ng-container matColumnDef="actions">
          <mat-header-cell
            *matHeaderCellDef
            class="grow border-collapse justify-center text-center leading-5"
          >
            {{ 'COMMON.TABLE_HEADERS.ACTIONS' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let company"
            class="grow justify-center whitespace-nowrap text-center"
          >
            <button
              class="pr-2"
              mat-icon-button
              [disabled]="company.multiParents"
              (click)="configureCompany(company)"
            >
              <mat-icon
                [ngClass]="{
                  'text-gray-300 cursor-not-allowed': company.multiParents,
                  'text-gray-800': !company.multiParents,
                }"
                [matTooltip]="'COMMON.ACTIONS.SETTINGS' | translate"
                [matTooltipShowDelay]="delayValues.SHORT_DELAY"
                [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
                >settings</mat-icon
              >
            </button>
            @if (layerPath() !== 'customers') {
              <button class="pr-2" mat-icon-button (click)="manageSubordinates(company)">
                <mat-icon
                  [matTooltip]="'COMPANY_MANAGEMENT.MANAGE_CHILDREN_TOOLTIP' | translate"
                  [matTooltipShowDelay]="delayValues.SHORT_DELAY"
                  [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
                  >lan</mat-icon
                >
              </button>
            }
            <button
              class="pr-2"
              mat-icon-button
              [disabled]="isDeleteDisabled(company)"
              (click)="deleteSingleCompany(company)"
            >
              @if (isDeleteDisabled(company)) {
                <mat-icon class="cursor-not-allowed text-red-300">delete</mat-icon>
              } @else {
                <mat-icon
                  class="text-red-700"
                  [matTooltip]="'COMMON.ACTIONS.DELETE' | translate"
                  [matTooltipShowDelay]="delayValues.SHORT_DELAY"
                  [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
                  >delete</mat-icon
                >
              }
            </button>
          </mat-cell>
        </ng-container>
      }
      <!-- Partners Column -->
      @if (this.view() !== 'vendor' && this.layerPath() === 'distributors') {
        <ng-container matColumnDef="partners">
          <mat-header-cell
            *matHeaderCellDef
            fxFlex="10"
            class="grow border-collapse justify-center text-center leading-5"
          >
            <span tmcTruncateTooltip>{{ 'COMPANY_MANAGEMENT.PARTNER' | translate }}</span>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let company; let i = index"
            fxFlex="10"
            class="flex justify-center overflow-visible whitespace-nowrap text-center"
          >
            <mat-checkbox
              #checkbox
              color="primary"
              [checked]="company.isPartner"
              [disabled]="company.multiParents || this.view() !== 'vendor'"
            >
            </mat-checkbox>
          </mat-cell>
        </ng-container>
      }

      <mat-header-row *matHeaderRowDef="displayedColumns(); sticky: true"></mat-header-row>
      <mat-row *matRowDef="let company; columns: displayedColumns()"></mat-row>
    </mat-table>
  </div>
  <mat-paginator
    class="sticky bottom-0 z-10"
    aria-label="Select page"
    [pageSize]="25"
    [pageSizeOptions]="pageSizeOptions"
  >
  </mat-paginator>
</div>
