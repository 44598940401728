<mat-toolbar color="primary" class="justify-between">
  <div class="flex flex-row items-center gap-8">
    <button mat-icon-button (click)="toggleSidenav.emit()">
      <mat-icon>apps</mat-icon>
    </button>
    <tmc-header-logo></tmc-header-logo>
  </div>
  <div class="flex flex-row items-center">
    <tmc-header-theme></tmc-header-theme>
    <tmc-header-language></tmc-header-language>
    <tmc-header-profile></tmc-header-profile>
  </div>
</mat-toolbar>
