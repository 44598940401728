@if (this.allProfiles()?.loading === true) {
  <tmc-progress-spinner></tmc-progress-spinner>
} @else {
  <div class="box-border h-full">
    <div class="flex justify-end">
      <div class="my-sm flex">
        @if (view === 'vendor') {
          <button
            mat-button
            class="flex items-center text-base font-semibold text-primary"
            [matTooltip]="tooltipMessage() | translate"
            [disabled]="maxProfilesReached()"
            (click)="addNewProfile()"
          >
            @if (maxProfilesReached()) {
              <mat-icon class="mr-0.5 cursor-not-allowed" matPrefix>add</mat-icon>
            } @else {
              <mat-icon class="mr-0.5" matPrefix>add</mat-icon>
            }
            {{ 'USER_MANAGEMENT.VPN_PROFILES.CREATE_NEW_VPN_PROFILE' | translate }}
          </button>
        }
        <tmc-table-search-box [(filterCriteria)]="listFilter"></tmc-table-search-box>
      </div>
    </div>
  </div>
  <tmc-vpn-profiles-list
    [profiles]="filteredProfiles()"
    [view]="view"
    (deleteProfileEvent)="deleteProfile($event)"
  ></tmc-vpn-profiles-list>
}
