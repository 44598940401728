import { Directive, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { filter, switchMap } from 'rxjs';
import { TokenService } from './token.service';

/**
 * OAuthDirective initializes the Auth process with keycloak.
 * Should be applied on the app component.
 */
@Directive({
  selector: '[tmcOAuth]',
  standalone: true,
})
export class OAuthDirective {
  private readonly oidcService = inject(OidcSecurityService);
  private readonly oidcEventService = inject(PublicEventsService);
  private readonly tokenService = inject(TokenService);

  constructor() {
    // Initialize OAuth process
    this.oidcService.checkAuth().pipe(takeUntilDestroyed()).subscribe();

    // Push current token in Token service
    this.oidcEventService
      .registerForEvents()
      .pipe(
        filter(
          (notification) =>
            notification.type === EventTypes.CheckingAuthFinished ||
            notification.type === EventTypes.NewAuthenticationResult,
        ),
        switchMap(() => this.oidcService.getAccessToken()),
      )
      .subscribe((accessToken) => {
        this.tokenService.accessToken.next(accessToken);
      });
  }
}
