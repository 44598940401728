export const DELAY_VALUES = {
  NO_DELAY: 0,
  FAST_DELAY: 100, // 0.1 second
  NORMAL_DELAY: 200, // 0.2 second
  FASTEST_DELAY: 300, // 0.3 second
  SHORT_DELAY: 500, // 0.5 second
  MEDIUM_DELAY: 1000, // 1 second
};

export const ADMINS_LIMITS = 20;

export const MANUFACTURERS_WITH_LICENSES = ['PLASSER AND THEURER', 'PLASSER INDIA'];

export const PHOTOGRAPH_MAX_SIZE = 2 * 1024 * 1024;

export const EXTRA_MDC_MODULE = {
  name: 'CONFIGURATION MANAGEMENT',
  id: 46,
};
