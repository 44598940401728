import {
  Component,
  ChangeDetectionStrategy,
  viewChild,
  model,
  signal,
  computed,
  AfterViewInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { FieldType, FormlyMaterialModule } from '@ngx-formly/material';
import { MatInput, MatInputModule } from '@angular/material/input';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipInputEvent, MatChipListbox, MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { SelectOption } from '../select-option.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-formly-multiselection-type',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatChipsModule,
    MatChipListbox,
    MatIconModule,
  ],
  templateUrl: './formly-multiselection-type.component.html',
})
export class FormlyMultiselectionTypeComponent
  extends FieldType<FieldTypeConfig>
  implements AfterViewInit
{
  readonly currentValue = model<string>('');
  readonly selectable = true;
  readonly removable = true;
  readonly selectedOptions = signal<string[]>((this.field.defaultValue as string[]) ?? []);
  readonly filteredOptions = computed(() => {
    const checkedOptions = this.selectedOptions();
    const tippedValue = typeof this.currentValue() === 'string' ? this.currentValue() : '';
    if (this.labels().length > 0) {
      const availableLabels = this.labels().filter((label) => !checkedOptions.includes(label));
      return availableLabels.filter((label) =>
        label.toLowerCase().includes(tippedValue.toLowerCase()),
      );
    }
    return [];
  });
  labels = signal<string[]>([]); // To store extracted labels
  formFieldControl = viewChild.required(MatInput);
  multiselection = viewChild.required(MatAutocompleteTrigger);

  ngAfterViewInit() {
    (this.props.options as SelectOption[])
      .map((op) => op.label)
      .toSorted((a, b) => a.localeCompare(b))
      .forEach((op) => {
        this.labels.set([...this.labels(), op]);
      });
    // temporary fix for https://github.com/angular/material2/issues/6728
    (this.multiselection as any)._formField = this.formField;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our option
    if (value && this.labels().includes(value)) {
      this.selectedOptions.update((selectedOptions) => [...selectedOptions, value]);
    }
    this.setSelectedValues();
  }

  remove(op: string): void {
    this.selectedOptions.update((selectedOptions) => {
      const index = selectedOptions.indexOf(op);
      if (index < 0) {
        return selectedOptions;
      }
      selectedOptions.splice(index, 1);
      return [...selectedOptions];
    });
    this.setSelectedValues();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedOptions.set([...(this.selectedOptions() ?? []), event.option.viewValue]);
    this.setSelectedValues();
    event.option.deselect();
  }

  setSelectedValues() {
    this.currentValue.set('');
    const optionObjects = (this.props.options as SelectOption[])
      .filter((option) => this.selectedOptions().includes(option.label))
      .map((option) => option.value);
    this.formControl.setValue(optionObjects);
  }
}
