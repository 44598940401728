import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  effect,
  inject,
  input,
  output,
  viewChild,
  ChangeDetectionStrategy,
  viewChildren,
  ElementRef,
} from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogService, TextSelectOption, TruncateTooltipDirective } from '@tmc/core-ui';
import {
  ListDialogComponent,
  IListDialogConfig,
  RemovableListDialogData,
  DELAY_VALUES,
} from '@tmc/fleet-core-ui';
import {
  Company,
  Pagination,
  SimpleCompany,
  UserEmail,
  CompanyAssociatedChildren,
  Region,
} from '@tmc/fleet-core-api';
import { switchMap } from 'rxjs';
import { FleetSubordinateCompanyComponent } from '../subordinate-dialog/fleet-company-subordinate-dialog.component';
import { SubordinateModalData } from '../subordinate-dialog/subordinate-company.model';

@Component({
  selector: 'tmc-list-companies',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSortModule,
    TranslateModule,
    MatChipsModule,
    MatTooltipModule,
    TruncateTooltipDirective,
    RouterModule,
  ],
  templateUrl: './company-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyListComponent {
  private readonly dialogService = inject(DialogService);
  private readonly translate = inject(TranslateService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  allColumns = ['id', 'name', 'machine', 'country', 'companies', 'admins', 'actions'];
  pageSizeOptions = Pagination.PAGE_SIZE_OPTIONS;
  delayValues = DELAY_VALUES;
  dataSource!: MatTableDataSource<Company>;
  regions = input<Region[]>();
  companies = input.required<Company[] | undefined>();
  layerPath = input.required<string>();
  manufacturers = input<Company[] | undefined>(undefined);
  distributors = input<Company[] | undefined>(undefined);
  customers = input<Company[] | undefined>(undefined);
  view = input<string>();
  toBeRemovedAssignedCompanies = output<CompanyAssociatedChildren[]>();
  removeSingleCompany = output<{ option: string; companyId: number }>();
  updateCompanyRelationsEvent = output<{
    action: string;
    company: Company;
    child: SimpleCompany;
  }>();
  sort = viewChild.required(MatSort);
  paginator = viewChild.required(MatPaginator);
  checkboxes = viewChildren<ElementRef>('#checkbox');

  displayedColumns = computed(() => {
    if (this.layerPath() === 'customers') {
      if (this.view() !== 'vendor') {
        return this.allColumns.filter((x) => x !== 'companies' && x !== 'actions');
      }
      return this.allColumns.filter((x) => x !== 'companies');
    }
    if (this.view() !== 'vendor' && this.layerPath() === 'distributors') {
      return this.allColumns.filter((x) => x !== 'actions').concat(['partners']);
    }
    return this.allColumns;
  });

  constructor() {
    effect(() => {
      if (this.companies()) {
        this.dataSource = new MatTableDataSource(this.companies());
        this.dataSource.sort = this.sort();
        this.dataSource.paginator = this.paginator();
      }
    });
  }

  isDeleteDisabled(company: Company): boolean {
    return (
      company.adminUsers.length > 0 ||
      company.machines > 0 ||
      company.companies?.length > 0 ||
      (company.multiParents !== undefined && company.multiParents)
    );
  }

  manageSubordinates(selectedCompany: Company) {
    const config = new MatDialogConfig<SubordinateModalData>();
    config.data = {
      allDistributors:
        this.distributors()?.filter(
          (distributor: Company) =>
            distributor.id !== selectedCompany.id && !distributor.isParentManagementAllowed,
        ) ?? [],
      allCustomers:
        this.customers()?.filter(
          (customer: SimpleCompany) =>
            customer.id !== selectedCompany.id &&
            !selectedCompany.customers?.map((item: SimpleCompany) => item.id).includes(customer.id),
        ) ?? [],
      company: selectedCompany,
      url: this.layerPath(),
    };

    const dialogRef = this.dialogService.open(FleetSubordinateCompanyComponent, config);
    dialogRef
      .afterClosed()
      .subscribe(
        (result: {
          company: TextSelectOption<string> | null;
          isPartner: boolean;
          action: string;
        }) => {
          if (result === undefined) return;
          if (result?.company !== null) {
            const childCompany: SimpleCompany = {
              id: Number(result.company.value),
              externalId: result.company.value,
              name: result.company.label,
              partners: result.isPartner,
            };
            this.updateCompanyRelations(result.action, selectedCompany, childCompany);
          }
        },
      );
  }

  updateCompanyRelations(action: string, company: Company, child: SimpleCompany) {
    this.updateCompanyRelationsEvent.emit({ action, company, child });
  }

  async configureCompany(company: Company) {
    const selectedCompanyData = this.companies()?.find((c) => c.id === company.id);
    await this.router.navigate([`./${company.externalId}`], {
      relativeTo: this.route,
      state: {
        manufacturers: this.manufacturers(),
        distributors: this.distributors(),
        customers: this.customers(),
        selectedCompanyData,
        regions: this.regions(),
      },
    });
  }

  deleteSingleCompany(company: Company) {
    this.translate
      .get('PATH.USER_MGMT.ARE_YOU_SURE_TO_REMOVE_PROFILE', { element: company.name })

      .pipe(
        switchMap((msg: string) =>
          this.dialogService
            .openConfirmationDialog({ title: company.name, confirmationQuestion: msg })
            .afterClosed(),
        ),
      )
      .subscribe((accept) => {
        if (accept ?? false) {
          const removeCompany = {
            option: this.layerPath(),
            companyId: company.id,
          };
          this.removeSingleCompany.emit(removeCompany);
        }
      });
  }

  openAllAdminsDialog(company: Company) {
    const config: IListDialogConfig = {
      data: [
        {
          option: 'admins',
          list: company.adminUsers.map((admin: UserEmail) => ({
            value: admin.email,
            isRemovable: false,
          })),
        },
      ],
    };
    this.createListDialog(config);
  }

  openRelatedCompaniesDialog(company: Company) {
    let value = '';
    if (this.layerPath() === 'manufacturers') {
      value = 'distributors';
    }
    if (this.layerPath() === 'distributors') {
      value = 'manufacturers';
    }
    const vendorOptions = [value, `partner ${value}`];
    const options: string[] = [...vendorOptions, 'customers'];
    const config: IListDialogConfig = {
      data: options.map((option) => ({
        option,
        list: this.getOptionList(company, option).map((c: SimpleCompany) => ({
          value: c.name,
          isRemovable: c.removable,
        })),
      })),
    };
    config.autoFocus = false;
    config.minWidth = 'fit-content';
    const dialogRef = this.createListDialog(config);
    dialogRef.afterClosed().subscribe((toBeRemovedItems: RemovableListDialogData[]) => {
      this.removeAssignedCompanies(company, toBeRemovedItems);
    });
  }

  removeAssignedCompanies(selectedCompany: Company, toBeRemovedItems: RemovableListDialogData[]) {
    const result: CompanyAssociatedChildren[] = [];
    if (toBeRemovedItems !== null) {
      toBeRemovedItems?.forEach((item) => {
        result.push({
          option: this.layerPath(),
          company: selectedCompany,
          listChildrenIds: this.getCompanyIds(selectedCompany, item.option, item.list),
        });
      });
    }
    if (result.length > 0) this.toBeRemovedAssignedCompanies.emit(result);
  }

  protected getOptionList(company: Company, option: string): SimpleCompany[] {
    switch (option) {
      case 'manufacturers':
        return company.manufacturers?.filter(
          (manufacturer: SimpleCompany) => manufacturer.partners === false,
        );
      case 'partner manufacturers':
        return company.manufacturers?.filter(
          (manufacturer: SimpleCompany) => manufacturer.partners === true,
        );
      case 'distributors':
        return company.distributors?.filter(
          (distributor: SimpleCompany) => distributor.partners === false,
        );
      case 'partner distributors':
        return company.distributors?.filter(
          (distributor: SimpleCompany) => distributor.partners === true,
        );
      case 'customers':
        return company.customers ?? [];
      default:
        return [];
    }
  }

  private getCompanyIds(
    selectedCompany: Company,
    option: string,
    toBeRemovedCompanies: string[],
  ): { id: number; partners: boolean }[] {
    const ids: { id: number; partners: boolean }[] = [];
    toBeRemovedCompanies.forEach((item: string) => {
      const matchCompany = this.getOptionList(selectedCompany, option)?.find(
        (company: SimpleCompany) => company.name === item,
      );
      if (matchCompany) ids.push({ id: matchCompany.id, partners: matchCompany.partners ?? false });
    });
    return ids;
  }

  private createListDialog(config: IListDialogConfig) {
    return this.dialogService.open(ListDialogComponent, config, config.autoFocus, config.minWidth);
  }
}
