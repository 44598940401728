<h2 mat-dialog-title>
  {{ 'USER_MANAGEMENT.PERMISSIONS_MANAGEMENT.CREATE_NEW_PERMISSION' | translate }}
</h2>
<div class="min-h-80 min-w-80">
  @if (isAvailable()) {
    <div>
      <div mat-dialog-content class="min-w-80 border-8 border-transparent">
        <tmc-text-select
          [autofocus]="true"
          [label]="'USER_MANAGEMENT.PERMISSIONS_MANAGEMENT.NEW_PERMISSION.APPLICATION' | translate"
          [options]="applicationOptions()"
          [preSelection]="applicationOptions()[0].label"
          (selected)="onApplicationSelected($event?.value)"
        ></tmc-text-select>
        <tmc-text-select
          [autofocus]="true"
          [label]="'USER_MANAGEMENT.PERMISSIONS_MANAGEMENT.NEW_PERMISSION.MODULE' | translate"
          [options]="moduleOptions()"
          [preSelection]="moduleOptions()[0].label"
          (selected)="onModuleSelected($event?.value)"
        ></tmc-text-select>
        <tmc-text-select
          [label]="'USER_MANAGEMENT.PERMISSIONS_MANAGEMENT.NEW_PERMISSION.SCOPE' | translate"
          [options]="scopeOptions()"
          (selected)="onScopeSelected($event?.value)"
        ></tmc-text-select>
        <tmc-text-select
          #entitySelect
          [label]="'USER_MANAGEMENT.PERMISSIONS_MANAGEMENT.NEW_PERMISSION.SCOPE_ENTITY' | translate"
          [options]="scopeEntityOptions()"
          (selected)="onScopeEntitySelected($event?.value)"
        ></tmc-text-select>
        <tmc-text-select
          [autofocus]="true"
          [label]="
            this.data.isRemoteFeaturesGroup === true
              ? ('USER_MANAGEMENT.PERMISSIONS_MANAGEMENT.NEW_PERMISSION.PROFILE' | translate)
              : ('USER_MANAGEMENT.PERMISSIONS_MANAGEMENT.NEW_PERMISSION.ROLE' | translate)
          "
          [options]="roleOptions()"
          (selected)="onRoleSelected($event?.value)"
        ></tmc-text-select>
      </div>
    </div>

    <div class="flex justify-end pb-3 pr-5">
      <mat-dialog-actions>
        <button
          mat-raised-button
          mat-dialog-close
          color="secondary"
          class="uppercase"
          (click)="this.dialogRef.close(null)"
        >
          {{ 'COMMON.ACTIONS.CANCEL' | translate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          class="uppercase"
          [disabled]="permissionDefined() !== true"
          (click)="onSavePermission()"
        >
          {{ 'COMMON.ACTIONS.CONFIRM' | translate }}
        </button>
      </mat-dialog-actions>
    </div>
  } @else {
    <tmc-progress-spinner></tmc-progress-spinner>
  }
</div>
