<div class="my-2 h-auto rounded-xl border-2 border-[#d7d7d7]">
  <div class="flex h-20 items-center justify-between p-6">
    <mat-icon>{{ tileData().logo }}</mat-icon>
    <span class="m-0 text-lg font-bold leading-6">{{ tileData().heading! | translate }}</span>
    <button class="size-10 rounded-3xl border-2 border-[#d7d7d7] shadow-md" (click)="toRoute()">
      <mat-icon class="size-4 pt-0.5 text-[#0f575f]">open_in_new</mat-icon>
    </button>
  </div>
  <div class="m-2 h-48 px-6 pb-6 pt-4">
    <span class="w-96 text-sm font-normal leading-5">
      {{ tileData().description! | translate }}
    </span>
    <button class="mt-4 block text-sm font-bold leading-5 text-[#6cacb2]" (click)="toRoute()">
      @if (canCreate() !== undefined && canCreate()) {
        <span>{{ tileData().actionText! | translate }}</span>
      }
      @if (canCreate() !== undefined && !canCreate()) {
        <span>{{ tileData().alternativeActionText! | translate }}</span>
      }
    </button>
  </div>
</div>
