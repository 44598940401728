<button mat-icon-button [matMenuTriggerFor]="languageMenu">
  <mat-icon>language</mat-icon>
</button>
<mat-menu #languageMenu>
  @for (language of supportedLanguages; track language) {
    <button mat-menu-item (click)="updateLanguage(language)">
      <span>{{ language }}</span>
    </button>
  }
</mat-menu>
