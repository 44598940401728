import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserCompany } from './user-company.model';

@Injectable({
  providedIn: 'root',
})
export class UserCompanyService {
  private readonly http = inject(HttpClient);

  getUserCompany(email: string) {
    return this.http.get<UserCompany>(`/fleet-admin/users/${email}/company`);
  }
}
