/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable promise/catch-or-return */
/* eslint-disable promise/always-return */ // Needed because setting signals from Observable
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
  ChangeDetectionStrategy,
  WritableSignal,
  computed,
  signal,
  Signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  GroupService,
  UserService,
  FleetPermissionService,
  FleetPermission,
  User,
  Group,
  FleetPermissionInput,
} from '@tmc/mco-user-management-api';
import { UpdateService } from '@tmc/fleet-core-api';
import { ActivatedRoute } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DialogService } from '@tmc/core-ui';
import { BreadcrumbComponent, TableSearchBoxComponent } from '@tmc/fleet-core-ui';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ListPermissionsComponent, CreatePermissionComponent } from '@tmc/mco-user-management-ui';
import { UtilsService } from '@tmc/fleet-core-utils';
import { switchMap } from 'rxjs';

@Component({
  selector: 'tmc-fleet-user-management-permissions',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonToggleModule,
    MatTabsModule,
    BreadcrumbComponent,
    TranslateModule,
    ListPermissionsComponent,
    TableSearchBoxComponent,
  ],
  providers: [FleetPermissionService, UpdateService, UserService, GroupService],
  templateUrl: './fleet-user-management-permissions.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetUserManagementPermissionsComponent {
  private readonly fleetPermissionService = inject(FleetPermissionService);
  private readonly groupService = inject(GroupService);
  private readonly userService = inject(UserService);
  private readonly route = inject(ActivatedRoute);
  private readonly dialog = inject(MatDialog);
  private readonly dialogService = inject(DialogService);
  private readonly utilsService = inject(UtilsService);
  private readonly translate = inject(TranslateService);

  path: Signal<string> = signal(this.route.routeConfig?.path ?? '');
  isUser = computed(() => this.path().startsWith('users'));
  groupId: WritableSignal<number | undefined> = signal(undefined);

  permissions: WritableSignal<FleetPermission[] | undefined> = signal(undefined);
  filteredPermissions = computed(() =>
    this.permissions()?.filter((permission: FleetPermission) =>
      this.isFilteredPermission(permission),
    ),
  );
  listFilter = signal<string>('');

  constructor() {
    if (!this.isUser()) {
      this.groupId.set(this.route.snapshot.params['id'] as number);
    }
    this.loadData().catch((error: unknown) => {
      this.dialogService.openSnackBar({ message: this.utilsService.getErrorMessage(error) });
    });
  }

  isFilteredPermission(permission: FleetPermission) {
    return (
      this.getPermissionName(permission)?.toLowerCase().includes(this.listFilter().toLowerCase()) ||
      (typeof permission.scope === 'string' &&
        permission.scope?.toLowerCase().includes(this.listFilter().toLowerCase())) ||
      (typeof permission.scopeType === 'string' &&
        permission.scopeType?.toLowerCase().includes(this.listFilter().toLowerCase())) ||
      (typeof permission.module === 'string' &&
        permission.module?.toLowerCase().includes(this.listFilter().toLowerCase())) ||
      (typeof permission.role === 'string' &&
        permission.role?.toLowerCase().includes(this.listFilter().toLowerCase()))
    );
  }

  async loadData() {
    if (this.isUser()) {
      await this.userService
        .getOne(this.route.snapshot.params['email'] as string)
        .then((user: User) => {
          this.permissions.set(user.permissions);
          if (this.groupId() === undefined) this.groupId.set(user.ownGroupId);
        })
        .catch(() => {
          this.permissions.set([]);
        });
    } else {
      await this.groupService
        .getOne(this.groupId() ?? -1)
        .then((group: Group) => {
          this.permissions.set(group.groupPermissions);
        })
        .catch(() => {
          this.permissions.set([]);
        });
    }
  }

  openCreateNewPermissionDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      groupId: this.groupId(),
      currentPermissions: this.permissions(),
      isRemoteFeaturesGroup: this.isRemoteFeaturesGroup(),
    };
    const dialogRef = this.dialog.open(CreatePermissionComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: FleetPermissionInput) => {
      if (result !== null)
        this.fleetPermissionService
          .create(result)
          .catch((error: unknown) => {
            this.dialogService.openSnackBar({ message: this.utilsService.getErrorMessage(error) });
          })
          .then(() => this.loadData());
    });
  }

  deletePermission(permission: FleetPermission) {
    this.translate
      .get('PATH.USER_MGMT.ARE_YOU_SURE_TO_REMOVE_PROFILE', { element: permission.id })
      .pipe(
        switchMap((message: string) =>
          this.dialogService
            .openConfirmationDialog({
              title: this.getPermissionName(permission),
              confirmationQuestion: message,
            })
            .afterClosed(),
        ),
      )
      .subscribe((accept) => {
        if (accept === true && permission.id !== null && permission.id !== undefined) {
          this.fleetPermissionService
            .deletePermission(permission.id)
            .catch(() => {
              this.dialogService.openSnackBar({
                message: 'ERROR.ERROR_OCCURRED',
              });
            })
            .then(() => this.loadData());
        }
      });
  }

  openConfirmDialog(title: string, message: string) {
    const data = {
      title,
      confirmationQuestion: message,
      translate: true,
    };
    return this.dialogService.openConfirmationDialog(data);
  }

  private isRemoteFeaturesGroup() {
    return this.path().startsWith('remote-features-groups');
  }

  private getPermissionName(permission: FleetPermission) {
    return typeof permission.application === 'string'
      ? permission.application
      : permission.application.name;
  }
}
