<div>
  <div class="flex border-spacing-1 flex-col rounded-xl p-8">
    <div class="min-w-full items-center justify-center overflow-y-auto">
      <div class="mb-4 font-bold uppercase">
        @if (options.length > 1) {
          <mat-button-toggle-group
            class="uppercase"
            appearance="legacy"
            color="primary"
            [formControl]="optionsControl"
            (change)="onValChange($event.value)"
          >
            @for (option of options; track option) {
              <mat-button-toggle class="flex flex-wrap space-x-2" [value]="option">{{
                option | uppercase
              }}</mat-button-toggle>
            }
          </mat-button-toggle-group>
        }
      </div>
      <div class="relative h-72 overflow-y-auto shadow-md">
        <mat-list role="list">
          @for (item of getListForSelectedOption(); track item) {
            <mat-list-item role="listitem">
              <div class="flex justify-between">
                {{ item.value }}
                @if (item.isRemovable) {
                  <button
                    class="flex-fill size-button-remove hover:bg-petrol-20 flex items-center justify-center hover:rounded-full hover:text-primary"
                    (click)="removeItem(item)"
                  >
                    <mat-icon class="text-sm">close</mat-icon>
                  </button>
                }
              </div>
            </mat-list-item>
            <hr />
          } @empty {
            <mat-list-item role="listitem">No data</mat-list-item>
          }
        </mat-list>
      </div>
    </div>
    <div class="mt-8 flex items-center justify-end">
      <button mat-raised-button color="primary" (click)="close()">
        {{ 'CLOSE' | translate }}
      </button>
    </div>
  </div>
</div>
