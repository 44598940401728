<div class="max-h-table flex flex-col">
  <div class="relative my-5 grow overflow-y-auto">
    <mat-table
      matSort
      multiTemplateDataRows
      aria-label="List of permissions"
      [dataSource]="dataSource"
    >
      <!-- Email Column -->
      <ng-container matColumnDef="application">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse p-2 px-6 py-3 text-left leading-5"
          mat-sort-header="application"
          >{{ 'COMMON.TABLE_HEADERS.APPLICATION' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let permission" class="p-2 px-6 text-left"
          ><span tmcTruncateTooltip>{{
            permission.application ?? permission.applicationName
          }}</span></mat-cell
        >
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="module">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse p-2 px-6 py-3 text-left leading-5"
          mat-sort-header="module"
          >{{ 'COMMON.TABLE_HEADERS.MODULE' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let permission" class="p-2 px-6 text-left"
          ><span tmcTruncateTooltip>{{ permission.module }}</span></mat-cell
        >
      </ng-container>

      <!-- Generated Time Column -->
      <ng-container matColumnDef="role">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse p-2 px-6 py-3 text-left leading-5"
          mat-sort-header="role"
          >{{ 'COMMON.TABLE_HEADERS.ROLE' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let permission" class="p-2 px-6 text-left"
          ><span tmcTruncateTooltip>{{ permission.role || '' }}</span></mat-cell
        >
      </ng-container>

      <!-- Modified Time Column -->
      <ng-container matColumnDef="type">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse p-2 px-6 py-3 text-left leading-5"
          mat-sort-header="scopeType"
          >{{ 'COMMON.TABLE_HEADERS.TYPE' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let permission" class="p-2 px-6 text-left"
          ><span tmcTruncateTooltip>{{ permission.scopeType || '' }}</span></mat-cell
        >
      </ng-container>

      <!-- Last Logged Time Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse p-2 px-6 py-3 text-left leading-5"
          mat-sort-header="scope"
          >{{ 'COMMON.TABLE_HEADERS.NAME' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let permission" class="p-2 px-6 text-left"
          ><span tmcTruncateTooltip>{{
            permission.scope ?? permission.scopeName ?? '-'
          }}</span></mat-cell
        >
      </ng-container>

      <!-- Registry Group Column -->
      @if (isUser()) {
        <ng-container matColumnDef="group">
          <ng-container>
            <mat-header-cell
              *matHeaderCellDef
              class="border-collapse justify-center whitespace-nowrap p-2 px-6 py-3 text-center leading-5"
              mat-sort-header="isUserPermission"
              >{{ 'COMMON.TABLE_HEADERS.GROUP' | translate }}</mat-header-cell
            >

            <mat-cell *matCellDef="let permission" class="justify-center">
              @if (!permission.isUserPermission) {
                <mat-icon> check </mat-icon>
              }
            </mat-cell>
          </ng-container>
        </ng-container>
      }
      @if (!isUser) {
        <ng-template #groupView></ng-template>
      }

      <!-- Actions Column -->
      <ng-container matColumnDef="ACTIONS">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse justify-center whitespace-nowrap p-2 px-6 py-3 text-center leading-5"
          >{{ 'COMMON.TABLE_HEADERS.ACTIONS' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let permission" class="justify-center whitespace-nowrap text-center">
          <button
            class="pr-2"
            mat-icon-button
            [disabled]="isDisabled(permission)"
            (click)="deletePermission(permission)"
          >
            <mat-icon
              [ngClass]="{
                'text-red-300 cursor-not-allowed': isDisabled(permission),
                'text-red-700': !isDisabled(permission),
              }"
              [matTooltip]="'COMMON.ACTIONS.DELETE' | translate"
              [matTooltipShowDelay]="delayValues.SHORT_DELAY"
              [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
              >delete</mat-icon
            >
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns(); sticky: true"></mat-header-row>
      <mat-row *matRowDef="let permission; columns: displayedColumns()"></mat-row>
    </mat-table>
  </div>
  <mat-paginator [pageSize]="25" [pageSizeOptions]="pageSizeOptions"> </mat-paginator>
</div>
