<div class="my-auto h-[80vh] min-h-full overflow-auto py-8">
  <div class="m-auto w-3/5 items-center justify-center" fxFlexFill fxLayout="column">
    @if (this.intervalForm !== undefined) {
      <mat-card class="h-3/4 overflow-auto p-2">
        <form [formGroup]="intervalForm">
          <mat-card-content>
            @if (this.selectedCompany) {
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                <mat-form-field appearance="outline" class="w-full">
                  <input
                    type="email"
                    matInput
                    formControlName="emailInput"
                    [placeholder]="'COMMON.EMAIL' | translate"
                  />
                </mat-form-field>
                <button
                  mat-raised-button
                  color="primary"
                  [disabled]="
                    !intervalForm.get('emailInput')?.valid ||
                    intervalForm.get('emailInput')?.value === '' ||
                    emails.includes(intervalForm.get('emailInput')?.value)
                  "
                  (click)="addEmail()"
                >
                  {{ 'COMPANY_MANAGEMENT.ADD_ADMINISTRATOR' | translate }}
                </button>
              </div>

              <!-- Wrapper div with fixed size for the chip list -->
              <div
                class="my-3 flex h-14 w-full justify-center rounded-sm border border-[#b0b0b0] align-middle"
              >
                @if (emails.length === 0) {
                  <p class="w-full text-red-600">
                    {{ 'COMPANY_MANAGEMENT.NO_ADMINISTRATOR' | translate }}
                  </p>
                }
                <mat-chip-listbox
                  appearance="outline"
                  class="flex size-full h-24 max-h-full flex-wrap items-center overflow-y-auto overflow-x-hidden"
                >
                  @for (email of emails; track email) {
                    <mat-chip
                      class="max-w-full whitespace-normal break-words"
                      [removable]="true"
                      (removed)="removeEmail(email)"
                    >
                      {{ email }}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                  }
                </mat-chip-listbox>
              </div>
            }
            <mat-form-field appearance="outline" class="w-full">
              <input
                matInput
                type="text"
                formControlName="externalId"
                required
                maxlength="10"
                [readonly]="!(selectedCompany === undefined)"
                [placeholder]="'COMMON.EXTERNAL_ID' | translate"
              />
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-full">
              <input
                matInput
                type="text"
                formControlName="companyName"
                required
                [placeholder]="'PATH.USER_MGMT.COMPANIES.COMPANY_NAME' | translate"
              />
            </mat-form-field>
            <tmc-text-select
              [autofocus]="true"
              [label]="'COMPANY_MANAGEMENT.REGION' | translate"
              [options]="regionsOptions"
              [preSelection]="this.selectedRegionName || null"
              (selected)="handleSelectedRegion($event)"
            ></tmc-text-select>
            <tmc-text-select
              [autofocus]="true"
              [label]="'COMMON.TABLE_HEADERS.COUNTRY' | translate"
              [options]="countriesOptions"
              [preSelection]="this.selectedCountryName || null"
              (selected)="handleSelectedCountry($event)"
            ></tmc-text-select>
            @if (this.layerPath === 'customers' && !selectedCompany) {
              <tmc-text-select
                [autofocus]="true"
                [label]="'COMPANY_MANAGEMENT.PARENT_COMPANY_TYPE' | translate"
                [options]="parentCompanyTypeOptions"
                [preSelection]="null"
                (selected)="parentTypeSelected($event)"
              ></tmc-text-select>
            }

            @if (
              !selectedCompany &&
              (this.layerPath === 'distributors' || (this.layerPath === 'customers' && parentType))
            ) {
              <ng-container>
                <tmc-text-select
                  [autofocus]="true"
                  [label]="'COMPANY_MANAGEMENT.PARENT_COMPANY' | translate"
                  [options]="parentCompaniesOptions()"
                  [preSelection]="null"
                  (selected)="parentCompanySelected($event)"
                ></tmc-text-select>
              </ng-container>
            }
            <div class="flex flex-col">
              @if (this.layerPath === 'distributors' && !this.selectedCompany) {
                <mat-checkbox color="primary" formControlName="isPartner">
                  {{ 'COMPANY_MANAGEMENT.SET_DISTRIBUTOR_AS_PARTNER' | translate | uppercase }}
                </mat-checkbox>
              }

              @if (this.layerPath === 'distributors') {
                <mat-checkbox color="primary" formControlName="isManufacturer">{{
                  'COMPANY_MANAGEMENT.SET_DISTRIBUTOR_ALSO_AS_MANUFACTURER' | translate | uppercase
                }}</mat-checkbox>
              }
            </div>

            @if (this.layerPath === 'manufacturers') {
              <mat-checkbox color="primary" formControlName="isDistributor">{{
                'COMPANY_MANAGEMENT.SET_MANUFACTURER_ALSO_AS_DISTRIBUTOR' | translate | uppercase
              }}</mat-checkbox>
            }
          </mat-card-content>
        </form>
        <mat-card-actions class="items-center justify-end">
          <button mat-raised-button color="primary" class="uppercase" [routerLink]="['../']">
            {{ 'COMMON.ACTIONS.CANCEL' | translate }}
          </button>
          <button
            mat-raised-button
            color="primary"
            class="ml-2 uppercase"
            [disabled]="!(this.intervalForm.valid && this.intervalForm.dirty)"
            (click)="selectedCompany ? editCompany() : newCompany()"
          >
            {{ 'COMMON.ACTIONS.CONFIRM' | translate }}
          </button>
        </mat-card-actions>
      </mat-card>
    }
  </div>
</div>
