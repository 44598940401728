<div class="flex max-h-[80vh] flex-col overflow-y-auto">
  <div class="px-6 py-5">
    <div class="flex flex-row items-center justify-between">
      <h3 class="!m-0 !font-bold">
        {{ 'COMMON.CONFIGURE_CSV' | translate | uppercase }}
      </h3>
      <button mat-icon-button mat-dialog-close color="primary">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <form class="flex flex-col overflow-y-auto border-y" [formGroup]="form">
    <div class="flex space-x-4 px-5 py-3">
      <div class="flex-1 text-right">
        <span [ngClass]="{ 'opacity-50': this.filteredData.length === 0 }">{{
          toggleAllLabel | translate
        }}</span>
      </div>
      <mat-slide-toggle
        class="grow-0"
        color="primary"
        formControlName="filtered"
        [hideIcon]="true"
      ></mat-slide-toggle>
      <div class="flex-1 text-left">
        <span [ngClass]="{ 'opacity-50': this.filteredData.length === 0 }">{{
          toggleFilteredLabel | translate
        }}</span>
      </div>
    </div>
    <div class="flex flex-col overflow-y-auto">
      <span class="border-b px-4 py-3 font-bold">{{ 'COMMON.SELECT_HEADERS' | translate }}</span>
      <mat-selection-list class="overflow-y-auto" formGroupName="checkboxes">
        @for (header of headers; track header; let last = $last) {
          <mat-list-item class="[&_span]:h-full" [disableRipple]="true">
            <label
              class="flex h-full flex-row items-center justify-between"
              [ngClass]="{
                'cursor-pointer': !header.disabled,
              }"
            >
              <span
                class="content-center"
                tmcTruncateTooltip
                [ngClass]="{ 'opacity-50': header.disabled }"
                >{{ header.label | translate }}</span
              >
              <mat-checkbox color="primary" [formControlName]="header.key"></mat-checkbox>
            </label>
          </mat-list-item>
          @if (!last) {
            <mat-divider></mat-divider>
          }
        }
      </mat-selection-list>
    </div>
  </form>
  <div class="flex justify-end px-6 py-5">
    <button mat-raised-button color="primary" (click)="exportToCSV()">
      {{ 'COMMON.EXPORT' | translate }}
    </button>
  </div>
</div>
