import {
  Observable,
  OperatorFunction,
  pipe,
  scan,
  throttleTime,
  map,
  filter,
  pairwise,
  startWith,
  window,
  delay,
  share,
  mergeMap,
  from,
  switchMap,
} from 'rxjs';
import { ArrayUtils, Comperator } from '../array/array.util';
import { Range } from '../range/range.model';
import { RangeUtils } from '../range/range.util';
import { hasValue } from '../type/type.utils';

export class RxjsUtils {
  /**
   * Collect data points into an array, emits the current state of the collected data every interval.
   *
   * @param seed the initial data.
   * @param interval time between emits in milliseconds.
   * @returns
   */
  public static collectThrottle<
    T,
    R extends {
      push: (...values: T[]) => number;
    },
  >(seed: R, interval: number): OperatorFunction<T, R> {
    return pipe(
      scan((acc: R, newData: T) => {
        acc.push(newData);
        return acc;
      }, seed),
      throttleTime(interval, undefined, { leading: false, trailing: true }),
    );
  }

  /**
   * Provides the ResizeObserver as a observable
   * @see ResizeObserver
   * @param elem element to observe
   * @param options resize observer options
   * @returns observable of ResizeObserverEntry arrays
   */
  public static resizeObservable(
    elem: Element,
    options?: ResizeObserverOptions,
  ): Observable<ResizeObserverEntry[]> {
    return new Observable((subscriber) => {
      const ro = new ResizeObserver((entries) => {
        subscriber.next(entries);
      });
      ro.observe(elem, options);
      return () => {
        ro.unobserve(elem);
      };
    });
  }

  static intersectionObservable(
    elem: Element,
    options?: IntersectionObserverInit,
  ): Observable<IntersectionObserverEntry[]> {
    return new Observable((subscriber) => {
      const io = new IntersectionObserver((entries) => {
        subscriber.next(entries);
      }, options);
      io.observe(elem);
      return () => {
        io.unobserve(elem);
      };
    });
  }

  static windowOn<T>(condition: (value: T) => boolean): OperatorFunction<T, Observable<T>> {
    return (input$) => {
      const shared$ = input$.pipe(share());
      return shared$.pipe(delay(0), window(shared$.pipe(filter((value) => condition(value)))));
    };
  }

  static windowOnChange<T>(
    compare: (prev: T, curr: T) => boolean = (prev, curr) => prev === curr,
  ): OperatorFunction<T, Observable<T>> {
    return pipe(
      startWith(undefined),
      pairwise(),
      RxjsUtils.windowOn(
        ([prev, curr]) => prev !== undefined && curr !== undefined && compare(prev, curr),
      ),
      map((observable) =>
        observable.pipe(
          map(([, range]) => range),
          filter(hasValue),
        ),
      ),
    );
  }

  static collectSorted<T>(
    compare: Comperator<T>,
    transfrom?: (array: T[]) => T[],
  ): OperatorFunction<T, T[]> {
    return pipe(
      scan((store: T[], value: T) => {
        const index = ArrayUtils.binarySearch(store, value, compare);
        if (index >= 0) return store;
        const array = store.toSpliced(-index - 1, 0, value);
        if (!transfrom) return array;
        return transfrom(array);
      }, []),
    );
  }

  static graduallyExtendRange(): OperatorFunction<Range<number>, Range<number>> {
    return pipe(
      scan(
        ({ range }: { range: Range<number> | undefined }, extendingRange: Range<number>) => {
          if (range === undefined) return { range: extendingRange, queries: [extendingRange] };
          return {
            range: {
              start: Math.min(extendingRange.start, range.start),
              end: Math.max(extendingRange.end, range.end),
            },
            queries: RangeUtils.calculateExtensions(range, extendingRange),
          };
        },
        { range: undefined, queries: [] },
      ),
      mergeMap(({ queries }) => from(queries)),
    );
  }

  static switchMapWithLoading<S, T>(
    observableFunction: (value: S) => Observable<T>,
  ): OperatorFunction<S, LoadingState<T>> {
    return pipe(
      switchMap((value) =>
        observableFunction(value).pipe(
          map((data) => ({ data, loading: false })),
          startWith({ loading: true }),
        ),
      ),
      scan((state: LoadingState<T>, change: LoadingState<T>) => ({
        ...state,
        ...change,
      })),
    );
  }
}

export interface LoadingState<T = unknown> {
  loading: boolean;
  data?: T;
}
