import {
  Component,
  viewChild,
  ChangeDetectionStrategy,
  input,
  output,
  effect,
  computed,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FleetPermission } from '@tmc/mco-user-management-api';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DELAY_VALUES } from '@tmc/fleet-core-ui';
import { Pagination } from '@tmc/fleet-core-api';
import { TruncateTooltipDirective } from '@tmc/core-ui';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-list-permissions',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSortModule,
    TranslateModule,
    TruncateTooltipDirective,
  ],
  templateUrl: './list-permissions.component.html',
  styleUrls: ['../../../../../../fleet-core/ui/src/lib/styles/lists.scss'],
})
export class ListPermissionsComponent {
  dataSource!: MatTableDataSource<FleetPermission>;
  delayValues = DELAY_VALUES;
  pageSizeOptions = Pagination.PAGE_SIZE_OPTIONS;
  columns = ['application', 'module', 'role', 'type', 'name', 'group', 'ACTIONS'];
  sort = viewChild.required(MatSort);
  paginator = viewChild.required(MatPaginator);

  permissions = input.required<FleetPermission[] | undefined>();
  isUser = input<boolean>();
  deletePermissionEvent = output<FleetPermission>();

  displayedColumns = computed(() => {
    if (!this.isUser()) return this.columns.filter((x) => x !== 'group');
    return this.columns;
  });

  constructor() {
    effect(() => {
      if (this.permissions()) {
        this.dataSource = new MatTableDataSource(this.permissions());
        this.dataSource.sort = this.sort();
        this.dataSource.paginator = this.paginator();
      }
    });
  }

  deletePermission(permission: FleetPermission) {
    this.deletePermissionEvent.emit(permission);
  }

  isDisabled(permission: FleetPermission) {
    return (
      permission.module === 'Registry' ||
      (this.isUser() === true && permission.isUserPermission === false)
    );
  }
}
