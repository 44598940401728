import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { FormControl, ReactiveFormsModule, ValidatorFn } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

interface ValidatorData {
  validatorFunction: ValidatorFn;
  validatorErrorMsg: string;
  validatorErrorKey: string;
}

interface DATA {
  title: string;
  inputField: {
    label: string;
    validators: [ValidatorData];
  };
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-user-input-dialog',
  standalone: true,
  imports: [
    MatDialogClose,
    MatDialogContent,
    MatInputModule,
    MatDialogTitle,
    MatDialogActions,
    MatFormFieldModule,
    CommonModule,
    MatButtonModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss'],
})
export class InputDialogComponent {
  formControl: FormControl;
  formLabel: string;
  formValidators: [ValidatorData];
  title = '';
  constructor(
    public dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DATA,
  ) {
    this.title = data.title;
    this.formLabel = data.inputField.label;
    this.formValidators = data.inputField.validators;
    const validatorFunctions = data.inputField.validators.map(
      (validators) => validators.validatorFunction,
    );
    this.formControl = new FormControl('', validatorFunctions);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.formControl.valid) {
      this.dialogRef.close(this.formControl.value);
    }
  }
}
