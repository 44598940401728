// custom-wrapper.component.ts
import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-formly-utc-time-selector',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormlyModule, FormlyMaterialModule],
  template: `
    <div class="flex flex-row items-center justify-between">
      <h3>{{ to.label }}</h3>
      <ng-container #fieldComponent class="justify-between"></ng-container>
    </div>
  `,
})
export class UtcTimeSelectorComponent extends FieldWrapper {}
