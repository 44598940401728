@if (manufacturers() && distributors()) {
  <div class="flex w-full grow flex-col items-center justify-center overflow-y-auto p-[25px]">
    <div class="h-full w-4/5">
      <mat-card class="block p-0">
        <mat-card-content class="p-4 uppercase">
          <tmc-vpn-profile-form
            [profile]="profile()"
            [manufacturers]="manufacturers()"
            [distributors]="distributors()"
          ></tmc-vpn-profile-form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
} @else {
  <tmc-progress-spinner></tmc-progress-spinner>
}
