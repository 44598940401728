@if (username(); as username) {
  <button mat-icon-button [matMenuTriggerFor]="profileMenu">
    <img src="assets/images/default_profile.webp" [alt]="'Profile' | translate" />
  </button>
  <mat-menu #profileMenu>
    <span mat-menu-item disabled>{{ username }}</span>
    <button mat-menu-item (click)="logout()">
      <mat-icon color="primary">logout</mat-icon>
      <span>{{ 'PROFILE.LOGOUT' | translate }}</span>
    </button>
  </mat-menu>
} @else {
  <button mat-icon-button (click)="login()">
    <mat-icon>login</mat-icon>
  </button>
}
