import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResult } from '../../models/paginated-result.model';
import { MachineConfiguration } from './machine-configuration.model';

@Injectable({ providedIn: 'root' })
export class MachineConfigurationService {
  private readonly http = inject(HttpClient);

  getAll(
    manufacturerExternalId?: string,
    fileType?: string,
    regionCode?: string,
  ): Promise<MachineConfiguration[]> {
    const options: {
      manufacturer?: string;
      fileType?: string;
      region?: string;
    } = {};
    if (fileType != null) {
      options.fileType = fileType;
    }
    if (manufacturerExternalId != null) {
      options.manufacturer = manufacturerExternalId;
    }
    if (regionCode != null) {
      options.region = regionCode;
    }
    const query = new HttpParams({ fromObject: options });
    return lastValueFrom(
      this.http
        .get<
          PaginatedResult<MachineConfiguration>
        >(`/fleet-admin/configurations?${query.toString()}`)
        .pipe(map((result: PaginatedResult<MachineConfiguration>) => result.items)),
    );
  }
}
