import { DestroyRef, Directive, HostBinding, Input, OnInit, inject } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { startWith, pairwise } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ThemeService } from './theme.service';
import { CUSTOM_ICONS } from './custom-icons';

/**
 * ThemeDirective applies the theming classes to the appended container.
 * Input: themeOverlay, defines if the theme should also be applied to the material overlay.
 * Should be applied on the app component
 */
@Directive({
  selector: '[tmcTheme]',
  standalone: true,
})
export class ThemeDirective implements OnInit {
  @HostBinding('class.mat-typography') readonly typographyClass = true;
  @HostBinding('class') themeClass?: string;

  @Input() themeOverlay = true;

  private readonly destroyRef = inject(DestroyRef);
  private readonly themeService = inject(ThemeService);
  private readonly overlayContainer = inject(OverlayContainer);
  private readonly matIconRegistry = inject(MatIconRegistry);
  private readonly domSanitizer = inject(DomSanitizer);

  ngOnInit(): void {
    this.matIconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    this.registerCustomIcons();

    this.themeService.themeClass$
      .pipe(startWith(undefined), pairwise(), takeUntilDestroyed(this.destroyRef))
      .subscribe(([oldClass, newClass]) => {
        this.themeClass = newClass;
        if (this.themeOverlay) {
          this.updateOverlayClass(oldClass, newClass);
        }
      });

    if (!this.themeOverlay) return;
    this.overlayContainer.getContainerElement().classList.add('mat-typography');
  }

  private registerCustomIcons() {
    Object.entries(CUSTOM_ICONS).forEach(([folder, files]) => {
      files.forEach((file) => {
        this.matIconRegistry.addSvgIcon(
          `${folder}.${file}`,
          this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${folder}/${file}.svg`),
        );
      });
    });
  }

  private updateOverlayClass(oldClass?: string, newClass?: string) {
    const classes = this.overlayContainer.getContainerElement().classList;
    if (oldClass !== undefined) {
      classes.remove(oldClass);
    }
    if (newClass !== undefined) {
      classes.add(newClass);
    }
  }
}
