import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MachineService } from '@tmc/fleet-core-api';
import { from, of } from 'rxjs';
import { FleetMachineFormComponent } from '@tmc/fleet-machine-management-ui';
import { toSignal } from '@angular/core/rxjs-interop';
import { provideMachineFormOptions } from './fleet-machine-form.provider';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-machine-form',
  standalone: true,
  imports: [CommonModule, FleetMachineFormComponent],
  providers: [MachineService, provideMachineFormOptions()],
  templateUrl: './machine-form.component.html',
})
export class MachineFormComponent {
  private readonly machineService = inject(MachineService);
  private readonly route = inject(ActivatedRoute);
  id = this.route.snapshot.paramMap.get('externalId') ?? 'new';
  machine = toSignal(
    this.id !== 'new' ? from(this.machineService.getMachineData(this.id)) : of(undefined),
    { initialValue: null },
  );
}
