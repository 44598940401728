import { Component, input, ChangeDetectionStrategy, computed, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Tile } from '@tmc/fleet-core-api';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-tile',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule],
  templateUrl: './tile.component.html',
  providers: [TranslateModule],
})
export class TileComponent {
  private readonly router = inject(Router);
  view = input<string>();
  tileData = input.required<Tile>();
  canCreate = computed<boolean | undefined>(() => {
    if (this.view() !== undefined) {
      return this.tileData().views.find((v) => v.name === this.view())?.canCreate ?? false;
    }
    return undefined;
  });

  toRoute() {
    this.router.navigate([this.router.url, this.tileData().route]).catch(() => {});
  }
}
