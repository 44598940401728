import { ChangeDetectionStrategy, Component, model } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'tmc-table-search-box',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
  ],
  templateUrl: './table-search-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSearchBoxComponent {
  filter = model.required({
    alias: 'filterCriteria',
  });
}
