import { Injectable } from '@angular/core';

@Injectable()
export class UpdateService<T> {
  add(element: T, elements: T[]): T[] {
    return [...elements, element];
  }

  addAll(elements: T[], allElements: T[]): T[] {
    return [...elements, ...allElements];
  }

  update(element: T, newElement: T, elements: T[]): T[] {
    return this.add(newElement, this.remove(element, elements));
  }

  remove(element: T, elements: T[]): T[] {
    return elements.filter((e) => e !== element);
  }
}
