import { Component, ViewEncapsulation, effect, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatListModule } from '@angular/material/list';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { DialogService } from '@tmc/core-ui';
import { switchMap } from 'rxjs';
import { IListDialogConfig, ListItem } from './list-dialog.model';

// It breaks the functionality of the component
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'tmc-list-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonToggleModule,
    MatListModule,
    ReactiveFormsModule,
    TranslateModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
  ],
  templateUrl: './list-dialog.component.html',
  styleUrl: './list-dialog.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ListDialogComponent {
  private readonly dialogService = inject(DialogService);
  private readonly translate = inject(TranslateService);
  private readonly dialogRef = inject(MatDialogRef<ListDialogComponent>);
  public config: IListDialogConfig = inject(MAT_DIALOG_DATA) as IListDialogConfig;
  selectedOption = signal<string>(this.config.data[0].option);
  optionsControl = new FormControl(this.selectedOption());
  options: string[] = this.config.data.map(({ option }) => option);
  toBeRemovedItems = signal<{ option: string; list: string[] }[]>([]);
  constructor() {
    effect(() => {
      this.getListForSelectedOption();
    });
  }

  getListForSelectedOption(): ListItem[] {
    return (
      this.config.data
        .find(({ option: optionName }) => optionName === this.selectedOption())
        ?.list.filter(
          (item) =>
            !this.toBeRemovedItems().some(
              ({ option, list }) => option === this.selectedOption() && list.includes(item.value),
            ),
        ) ?? []
    );
  }

  close() {
    if (this.toBeRemovedItems.length > 0) this.dialogRef.close();
    this.dialogRef.close(this.toBeRemovedItems());
  }

  onValChange(value: string) {
    this.selectedOption.set(value);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  protected removeItem(item: ListItem) {
    this.translate
      .get('COMMON.DIALOGS.ARE_YOU_SURE_TO_REMOVE', { element: item.value })
      .pipe(
        switchMap((question: string) =>
          this.dialogService
            .openConfirmationDialog({ title: item.value, confirmationQuestion: question })
            .afterClosed(),
        ),
      )
      .subscribe((accept) => {
        if (accept ?? false) {
          this.toBeRemovedItems().push({ option: this.selectedOption(), list: [item.value] });
        }
      });
  }
}
