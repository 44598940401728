import {
  Component,
  computed,
  inject,
  signal,
  ChangeDetectionStrategy,
  model,
  viewChild,
  Signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CompanyListComponent } from '@tmc/fleet-company-management-ui';
import {
  BreadcrumbComponent,
  TableSearchBoxComponent,
  ProgressSpinnerComponent,
  CsvExportDialogComponent,
  CSVExportDialogData,
  CSVHeader,
  MANUFACTURERS_WITH_LICENSES,
} from '@tmc/fleet-core-ui';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ListMachinesComponent,
  FleetMachineSidebarComponent,
} from '@tmc/fleet-machine-management-ui';
import { Machine, MachineModule, MachineService } from '@tmc/fleet-core-api';
import { toSignal } from '@angular/core/rxjs-interop';
import { Subject, from, map } from 'rxjs';
import { MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule, MatAccordion } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { FilterOption } from '@tmc/fleet-ui';
import { MatButtonModule } from '@angular/material/button';
import { DialogService } from '@tmc/core-ui';
import { RxjsUtils, ScreenSizeService } from '@tmc/core-utils';
import { MatDialogConfig } from '@angular/material/dialog';
import { AdminAppUserDetailsService } from '@tmc/fleet-core-feature';
import { MachineStatus, MachineModuleService } from '@tmc/fleet-machine-management-api';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-fleet-machine-management-list',
  standalone: true,
  imports: [
    CommonModule,
    BreadcrumbComponent,
    MatButtonToggleModule,
    TableSearchBoxComponent,
    MatTabsModule,
    MatIconModule,
    MatInputModule,
    MatSidenavModule,
    MatFormFieldModule,
    TranslateModule,
    CompanyListComponent,
    ListMachinesComponent,
    ProgressSpinnerComponent,
    FleetMachineSidebarComponent,
    MatExpansionModule,
    MatAccordion,
    MatCardModule,
    MatButtonModule,
  ],
  providers: [MachineService, DatePipe],
  templateUrl: './fleet-machine-management-list.component.html',
})
export class FleetMachineManagementListComponent {
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly machineService = inject(MachineService);
  private readonly screenSizeService = inject(ScreenSizeService);
  private readonly dialogService = inject(DialogService);
  private readonly translateService = inject(TranslateService);
  private readonly machineModuleService = inject(MachineModuleService);
  private readonly datePipe = inject(DatePipe);
  private readonly reload = new Subject<void>();
  protected readonly adminAppUserDetail = inject(AdminAppUserDetailsService);
  modules = toSignal(
    from(this.machineModuleService.getAll()).pipe(
      map((modules: MachineModule[]) => modules.map((module: MachineModule) => module.name)),
    ),
    {
      initialValue: [],
    },
  );

  allMachines = toSignal(
    this.reload.pipe(RxjsUtils.switchMapWithLoading(() => from(this.machineService.getAll()))),
    {
      initialValue: undefined,
    },
  );
  machines = computed(() => {
    if (this.allMachines()?.loading === false && this.allMachines()?.data) {
      return this.allMachines()?.data?.toSorted((a, b) => a.name.localeCompare(b.name));
    }
    return undefined;
  });
  listFilter = signal<string>('');
  sideBarOpened = model<boolean>(true);
  machineAccordian = viewChild(MatAccordion);
  filteredOptions = signal<string[]>([]);
  modulesFilter = signal<string[]>([]);
  statusFilter = signal<string[]>([]);

  filteredMachines = computed(() => {
    const checkedModules = this.filteredOptions().includes('modules');
    const checkedStatus = this.filteredOptions().includes('status');
    if (checkedStatus && this.statusFilter().length === 0) return [];
    if (checkedModules && this.modulesFilter().length === 0) {
      return this.machines()?.filter((machine: Machine) => machine.modules.length === 0);
    }

    if (this.isFilterApplied()) {
      return this.machines()?.filter((machine: Machine) => {
        const matchModule = this.modulesFilter().length
          ? machine.modules.some((mod) => this.modulesFilter().includes(mod.name))
          : true;
        const matchStatus = this.statusFilter().length
          ? this.statusFilter().some((status) => status.toLowerCase() === machine.status)
          : true;
        const matchSearchString =
          this.listFilter() !== ''
            ? machine.name.toLowerCase().includes(this.listFilter().toLowerCase())
            : true;

        return matchModule && matchStatus && matchSearchString;
      });
    }
    return this.machines();
  });

  activeScreenBreakpoints = toSignal(this.screenSizeService.subscribeToLayoutChanges(), {
    requireSync: true,
  });

  sideBarMode: Signal<MatDrawerMode> = computed(() => {
    const activeBreakpoints = this.activeScreenBreakpoints();
    return activeBreakpoints !== undefined && activeBreakpoints.length > 1 ? 'side' : 'over';
  });

  constructor() {
    this.reload.next();
  }

  exportToCsv() {
    const machines = this.machines();
    if (machines !== undefined) {
      const fileName = 'machines';
      const headers = this.getHeaders();
      const data: CSVExportDialogData<Machine> = {
        headers,
        toggleAllLabel: 'MACHINE_MANAGEMENT.CSV_DIALOG.ALL_MACHINES',
        toggleFilteredLabel: 'MACHINE_MANAGEMENT.CSV_DIALOG.FILTERED_MACHINES',
        allData: this.machines() ?? [],
        filteredData: this.filteredMachines(),
        fileName,
        customCSVMapFunction: this.csvMapper.bind(this),
      };
      const config: MatDialogConfig = new MatDialogConfig();
      config.data = data;
      this.dialogService.open(CsvExportDialogComponent<Machine>, config).afterClosed();
    }
  }

  async openCreateNewMachine() {
    await this.router.navigate(['./new'], {
      relativeTo: this.route,
    });
  }

  async openMachineForm(machine: Machine) {
    await this.router.navigate(['./', machine.externalId], { relativeTo: this.route });
  }

  removeMachine(machineId: string) {
    this.machineService
      .delete(machineId)
      .pipe(
        map(() => {
          this.reload.next();
          this.dialogService.openSnackBar({
            message: 'MACHINE_MANAGEMENT.MESSAGES.MACHINE_DELETED',
          });
        }),
      )
      .subscribe();
  }

  onFilterChanged(filterOptions: FilterOption) {
    if (filterOptions.filterCategory === 'modules') {
      this.setFilterValue(this.modulesFilter, filterOptions);
    } else {
      this.setFilterValue(this.statusFilter, filterOptions);
    }
  }

  setFilterValue(filter: WritableSignal<string[]>, filterOptions: FilterOption) {
    if (filterOptions.filterChecked) filter.update((filters) => [...filters, filterOptions.filter]);
    else filter.update((filters) => filters.filter((item) => item !== filterOptions.filter));
  }

  onOptionChange(category: { filterCategory: string; categoryChecked: boolean }) {
    if (!category.categoryChecked) {
      this.filteredOptions.update((options) =>
        options.filter((option) => option !== category.filterCategory),
      );
      if (category.filterCategory === 'modules') this.modulesFilter.set([]);
      else this.statusFilter.set([]);
    } else if (category.filterCategory === 'status') {
      this.filteredOptions.update((options) => [...options, category.filterCategory]);
      this.statusFilter.set(MachineStatus.machineStatuses.map((status) => status.value));
    } else {
      this.filteredOptions.update((options) => [...options, category.filterCategory]);
      this.modulesFilter.set(this.modules() ?? []);
    }
  }

  private isFilterApplied(): boolean {
    return (
      this.listFilter() !== '' ||
      this.modulesFilter().length > 0 ||
      this.statusFilter().length > 0 ||
      this.filteredOptions().length > 0
    );
  }

  private csvMapper(machine: Machine): Record<string, unknown> {
    const offsetHours = String(machine.offsetHours);
    const offsetMinutes = String(machine.offsetMinutes);
    const offsetHoursStr = machine.utcOffsetMinutes >= 0 ? `+${offsetHours}` : offsetHours;
    const offsetMinutesStr = offsetMinutes.length === 1 ? `0${offsetMinutes}` : offsetMinutes;
    const licenseStr = machine.license
      ? String(
          this.translateService.instant(
            `MACHINE_MANAGEMENT.MACHINE_LICENSE.${machine.license.toUpperCase()}`,
          ),
        )
      : '--';
    const statusStr = machine.status
      ? String(
          this.translateService.instant(
            `MACHINE_MANAGEMENT.MACHINE_STATUS.${machine.status.toUpperCase()}`,
          ),
        )
      : '--';
    const registrationTimeStr = this.datePipe.transform(machine.registrationTime, 'short');
    const lastOnlineTimeUTCStr = this.datePipe.transform(machine.lastOnlineTimeUTC, 'short');
    const lastDataTimeUTCStr = this.datePipe.transform(machine.lastDataTimeUTC, 'short');

    const modules = this.modules().reduce(
      (acc: Record<string, number>, module) => ({
        ...acc,
        [module]: machine.modules.find((elem) => elem.name === module) ? 1 : 0,
      }),
      {},
    );

    return {
      manufacturer: machine.manufacturer?.name,
      distributor: machine.distributor?.name,
      customers: machine.customers?.map((customer) => customer.name).toString(),
      status: statusStr,
      registrationTime: registrationTimeStr,
      offset: `${offsetHoursStr}:${offsetMinutesStr}`,
      lastOnlineTime: lastOnlineTimeUTCStr,
      lastDataTime: lastDataTimeUTCStr,
      fileType: machine.fileType?.type,
      license: licenseStr,
      region: machine.region.name,
      ...modules,
    };
  }

  private getHeaders(): CSVHeader[] {
    const commonHeaders: CSVHeader[] = [
      { label: 'COMMON.EXTERNAL_ID', key: 'externalId', disabled: true },
      { label: 'COMMON.NAME', key: 'name', disabled: true },
      { label: 'COMMON.TABLE_HEADERS.REGION', key: 'region', disabled: false },
      { label: 'COMMON.TABLE_HEADERS.MANUFACTURER', key: 'manufacturer', disabled: false },
      { label: 'COMMON.TABLE_HEADERS.DISTRIBUTOR', key: 'distributor', disabled: false },
      { label: 'COMMON.TABLE_HEADERS.CUSTOMERS', key: 'customers', disabled: false },
      { label: 'COMMON.TABLE_HEADERS.STATUS', key: 'status', disabled: false },
      {
        label: 'MACHINE_MANAGEMENT.CSV_HEADERS.REGISTRATION_TIME',
        key: 'registrationTime',
        disabled: false,
      },
      { label: 'MACHINE_MANAGEMENT.CSV_HEADERS.OFFSET_TO_UTC', key: 'offset', disabled: false },
      {
        label: 'MACHINE_MANAGEMENT.CSV_HEADERS.LAST_ONLINE_TIME',
        key: 'lastOnlineTime',
        disabled: false,
      },
      {
        label: 'MACHINE_MANAGEMENT.CSV_HEADERS.LAST_DATA_TIME',
        key: 'lastDataTime',
        disabled: false,
      },
      { label: 'MACHINE_MANAGEMENT.CSV_HEADERS.IMPORT_TYPE', key: 'fileType', disabled: false },
    ];

    if (this.showLicense()) {
      commonHeaders.push({
        label: 'COMMON.TABLE_HEADERS.LICENSE',
        key: 'license',
        disabled: false,
      });
    }

    const moduleHeaders: CSVHeader[] = this.modules().map((module) => ({
      label: module,
      key: module,
      disabled: false,
    }));

    return [...commonHeaders, ...moduleHeaders];
  }

  private showLicense() {
    return (
      (this.adminAppUserDetail.view() !== 'customer' &&
        (this.machines() ?? []).some((machine) =>
          MANUFACTURERS_WITH_LICENSES.includes(machine.manufacturer?.name ?? ''),
        )) ??
      false
    );
  }
}
