import { Injectable, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  LanguageService,
  RegionService,
  MachineService,
  WidgetService,
  CompanyService,
  MachineConfigurationService,
  FileTypeService,
  Language,
  MachineConfiguration,
  MachineModule,
  FleetMachineDataEntry,
  Company,
  FileType,
  SimpleCompany,
  Region,
} from '@tmc/fleet-core-api';
import {
  from,
  of,
  Observable,
  map,
  switchMap,
  withLatestFrom,
  tap,
  BehaviorSubject,
  Subject,
  filter,
} from 'rxjs';
import { DialogService } from '@tmc/core-ui';
import { HttpErrorResponse } from '@angular/common/http';
import {
  MachineCategory,
  MachineModuleService,
  MachinePhotographService,
  MachineStatus,
} from '@tmc/fleet-machine-management-api';
import { hasValue, ArrayUtils } from '@tmc/core-utils';
import {
  IFleetMachineFormService,
  MachineFormData,
  MachineFormOperationType,
  MachineValueLabel,
} from '@tmc/fleet-machine-management-ui';
import { AdminAppUserDetailsService } from '@tmc/fleet-core-feature';

// TODO:
// - Implement error handling for network requests, 404
// - Implement view = customer, just machine/XXX should be called and fields different that name, code, description, photo must be hidden (maybe use different form)
// - Implement view = manufacturer, disable functionality between distributor and customer if none selected
// - Implement view = distributor,  check if all data are valid
// - Implement readonly fields for all disabled machines in view !== vendor
// - Check photo not visible is view !== vendor
// - Add comment for autocomplete, distinguish between dynamic options and static options
// - Clean unneeded resources

@Injectable()
export class FleetMachineFormService implements IFleetMachineFormService {
  private readonly configurationService = inject(MachineConfigurationService);
  private readonly dialogService = inject(DialogService);
  private readonly fileTypeService = inject(FileTypeService);
  private readonly companyService = inject(CompanyService);
  private readonly languageService = inject(LanguageService);
  private readonly machineService = inject(MachineService);
  private readonly modulesService = inject(MachineModuleService);
  private readonly regionService = inject(RegionService);
  private readonly widgetService = inject(WidgetService);
  private readonly adminAppUserDetailsService = inject(AdminAppUserDetailsService);
  private readonly machinePhotographService = inject(MachinePhotographService);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  view$ = this.adminAppUserDetailsService.details$.pipe(
    filter(hasValue),
    map((details) => AdminAppUserDetailsService.toView(details.company)),
  );
  manufacturers$ = new Subject<Company[]>();
  distributors$ = new Subject<Company[]>();
  customers$ = new Subject<Company[]>();
  fileTypes$ = new Subject<FileType[]>();
  regions$ = from(this.regionService.getAll());
  modules$ = from(this.modulesService.getAll());
  allManufacturers$ = new BehaviorSubject<Company[]>([]);
  allFileTypes$ = new BehaviorSubject<FileType[]>([]);
  manufacturersSignal = signal<Company[] | null>(null);
  selectedConfigurations$ = new BehaviorSubject<MachineConfiguration[]>([]);
  formView$ = new BehaviorSubject<string>('');

  constructor() {
    this.view$
      .pipe(
        tap((view) => {
          this.fetchData(view);
        }),
      )
      .subscribe();
  }

  fetchData(view: string) {
    if (view !== '') {
      this.formView$.next(view ?? '');
      if (view === 'vendor') {
        from(this.companyService.getAll('manufacturers')).subscribe((manufacturers) => {
          this.manufacturers$.next(manufacturers);
          this.allManufacturers$.next(manufacturers);
          this.manufacturersSignal.set(manufacturers);
        });
        from(this.fileTypeService.getAll()).subscribe((fileTypes) => {
          this.allFileTypes$.next(fileTypes);
          this.fileTypes$.next(fileTypes);
        });
      } else {
        this.manufacturers$.next([]);
        this.fileTypes$.next([]);
      }
      if (view === 'manufacturer') {
        from(this.companyService.getAll('distributors'))
          .pipe(
            switchMap(async (distributors) => {
              this.distributors$.next(distributors);
            }),
          )
          .subscribe();
        from(this.companyService.getAll('customers'))
          .pipe(
            switchMap(async (customers) => {
              this.customers$.next(customers);
            }),
          )
          .subscribe();
      }
      if (view === 'distributor') {
        from(this.companyService.getAll('customers'))
          .pipe(
            switchMap(async (customers) => {
              this.customers$.next(customers);
            }),
          )
          .subscribe();
      }
    }
  }

  getView(): Observable<string> {
    return from(this.formView$);
  }

  getManufacturers(): Observable<MachineValueLabel<SimpleCompany>[]> {
    return this.manufacturers$.pipe(
      map((manufacturers: Company[]) => this.getFormattedCompanyData(manufacturers)),
      map((manufacturers) => ArrayUtils.uniqueBy(manufacturers, (c) => c.label)) ?? [],
    );
  }

  getDistributors(): Observable<MachineValueLabel<SimpleCompany>[]> {
    return this.distributors$.pipe(
      map((distributors: Company[]) => this.getFormattedCompanyData(distributors)),
      map((distributors) => ArrayUtils.uniqueBy(distributors, (c) => c.label)) ?? [],
    );
  }

  getCustomers(): Observable<MachineValueLabel<SimpleCompany>[]> {
    return this.customers$.pipe(
      map((customers: Company[]) => this.getFormattedCompanyData(customers)),
      map((customers) => ArrayUtils.uniqueBy(customers, (c) => c.label)) ?? [],
    );
  }

  getLanguages(): Observable<MachineValueLabel<Language>[]> {
    return from(
      this.languageService.getAll().then(
        (languages: Language[]) =>
          languages?.map((l: Language) => ({
            value: l,
            label: l.name.toUpperCase(),
          })) ?? [],
      ),
    );
  }

  getModules(): Observable<MachineValueLabel<number>[]> {
    return this.modules$.pipe(
      map((modules: MachineModule[]) =>
        modules.map((module: MachineModule) => ({
          value: module.id,
          label: module.name,
          disabled: false,
        })),
      ),
    );
  }

  getRegions() {
    return this.regions$.pipe(map((regions) => regions.map((r) => ({ value: r, label: r.name }))));
  }

  getMachineDataChildren(): Observable<string[]> {
    return this.modules$
      .pipe(
        map((modules) =>
          modules.find((module: MachineModule) => module.name.toUpperCase() === 'MACHINE DATA'),
        ),
      )
      .pipe(map((module) => module?.children.map(({ name }) => name.toUpperCase()) ?? []));
  }

  getWidgets() {
    return from(
      this.widgetService.getAll().then((widgets) =>
        widgets.map((w) => ({
          value: w.id,
          label: `MACHINE_MANAGEMENT.WIDGETS.${w.code?.toUpperCase()}`,
        })),
      ),
    );
  }

  getFileTypes() {
    return from(this.fileTypes$);
  }

  getFileTypesLabels() {
    return from(this.fileTypes$).pipe(
      map((fileTypes) => fileTypes.map((f) => ({ value: f, label: f.type }))),
    );
  }

  getMachineCategories(): MachineCategory[] {
    return MachineCategory.MACHINE_CATEGORIES as MachineCategory[];
  }

  getMachineStatuses(): MachineStatus[] {
    return MachineStatus.machineStatuses;
  }

  getConfigurations(
    manufacturer?: SimpleCompany,
    region?: Region,
    fileType?: FileType,
  ): Observable<MachineValueLabel<MachineConfiguration>[]> {
    return from(
      this.configurationService.getAll(manufacturer?.externalId, fileType?.type, region?.code),
    ).pipe(
      map((configurations) => {
        this.selectedConfigurations$.next(configurations);
        return configurations.map((c) => ({ value: c, label: c.configurationName }));
      }),
      map((configurations) => ArrayUtils.uniqueBy(configurations, (c) => c.label)),
    );
  }

  saveOrUpdate(
    data: MachineFormData,
    regionCode: string,
    action: MachineFormOperationType,
    photographFile?: File,
  ) {
    of(data)
      .pipe(
        map((machineData) => {
          this.sendPhotograph(machineData, regionCode, action, photographFile ?? undefined);
          return machineData;
        }),
        withLatestFrom(this.selectedConfigurations$),
        switchMap(([machineData, configurations]) => {
          const configuration: MachineConfiguration | undefined = configurations.find(
            (item) => item.configurationName === data.configuration,
          );
          const d: MachineFormData = {
            ...machineData,
            configuration: configuration?.id.toString() ?? '',
          };
          return of(this.getOutput(d));
        }),
        switchMap((output) => this.sendMachineData(output, action)),
      )
      .subscribe();
  }

  private sendPhotograph(
    machineData: MachineFormData,
    regionCode: string,
    action: MachineFormOperationType,
    photographFile: File | undefined,
  ) {
    this.machinePhotographService
      .pushPhotograph(
        machineData.externalId,
        regionCode,
        action === 'create',
        photographFile ?? undefined,
      )
      .catch((error: unknown) => {
        if ((error as HttpErrorResponse).message.includes('409')) {
          this.dialogService.openSnackBar({
            message: 'MACHINE_MANAGEMENT.MESSAGES.MACHINE_ID_ALREADY_ASSIGNED',
          });
        } else {
          this.dialogService.openSnackBar({
            message: 'MACHINE_MANAGEMENT.MESSAGES.PHOTOGRAPH_NOT_SAVED',
          });
        }
        return undefined;
      });
  }

  private sendMachineData(output: FleetMachineDataEntry, action: MachineFormOperationType) {
    if (action === 'create') {
      return from(
        this.machineService
          .create(output)
          .catch((error: unknown) => this.handleError(error))
          .then((response) => this.goBack(response, action)),
      );
    }
    if (action === 'update') {
      return from(
        this.machineService
          .update(output.external_id, output)
          .catch((error: unknown) => this.handleError(error))
          .then((response) => this.goBack(response, action)),
      );
    }
    return of(undefined);
  }

  private handleError(err: unknown): null {
    if ((err as HttpErrorResponse).message.includes('409')) {
      this.dialogService.openSnackBar({
        message: 'MACHINE_MANAGEMENT.MESSAGES.MACHINE_ID_ALREADY_ASSIGNED',
      });
    } else {
      this.dialogService.openSnackBar({
        message: 'MACHINE_MANAGEMENT.MESSAGES.MACHINE_NOT_CREATED',
      });
    }
    return null;
  }

  private getOutput(machineData: MachineFormData): FleetMachineDataEntry {
    const utcTotalMinutes: number =
      Number(machineData.utcHours ?? 0) * 60 + Number(machineData.utcMinutes);
    return {
      code: machineData.code,
      utc_offset_minutes: utcTotalMinutes,
      external_id: machineData.externalId,
      name: machineData.name,
      description: machineData.description,
      commissioned_time: machineData.manufactured?.getTime() ?? undefined,
      manufacturer_id: machineData.manufacturerId,
      file_type_id: machineData.fileType.id,
      region_id: machineData.regionId,
      type: machineData.machineType,
      status: machineData.status,
      category: machineData.category,
      language_id: machineData.languageId,
      license: machineData.license,
      photograph: machineData.photograph,
      config_id: Number(machineData.configuration),
      machine_name: machineData.machineFileName,
      modules_ids: Object.keys(machineData.modulesIds).map((key) => Number(key)),
      widgets_ids: Object.keys(machineData.widgetsIds).map((key) => Number(key)),
    };
  }

  private async goBack(response: unknown, action: string) {
    if (response !== null) {
      this.dialogService.openSnackBar({
        message:
          action === 'create'
            ? 'MACHINE_MANAGEMENT.MESSAGES.MACHINE_CREATED'
            : 'MACHINE_MANAGEMENT.MESSAGES.MACHINE_UPDATED',
      });
      await this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  private getFormattedCompanyData(companies: Company[]): MachineValueLabel<SimpleCompany>[] {
    return companies?.map((m: Company) => ({
      value: {
        name: m.name,
        id: m.id,
        externalId: m.externalId,
      } as SimpleCompany,
      label: m.name,
    }));
  }
}
