<div class="max-h-table flex flex-col">
  <div class="relative my-5 grow overflow-y-auto">
    <mat-table matSort multiTemplateDataRows [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse whitespace-nowrap p-2 px-6 py-3 text-center leading-5"
          mat-sort-header="name"
        >
          <span>{{ 'COMMON.TABLE_HEADERS.NAME' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let profile" class="truncate p-2 px-6 text-left">
          <span tmcTruncateTooltip>{{ profile.name }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="isVendor">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse justify-center whitespace-nowrap p-2 px-6 py-3 text-center leading-5"
        >
          <span>{{ 'USER_MANAGEMENT.VPN_PROFILES.VENDOR_ACCESS' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let profile" class="justify-center text-left">
          <mat-checkbox disabled [checked]="profile.accessLevels[0].selected"> </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="isManufacturer">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse justify-center whitespace-nowrap p-2 px-6 py-3 text-center leading-5"
        >
          <span>{{ 'USER_MANAGEMENT.VPN_PROFILES.MANUFACTURER_ACCESS' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let profile" class="justify-center text-left">
          <mat-checkbox disabled [checked]="profile.accessLevels[1].selected"> </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="isDistributor">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse justify-center whitespace-nowrap p-2 px-6 py-3 text-center"
        >
          <span>{{ 'USER_MANAGEMENT.VPN_PROFILES.DISTRIBUTOR_ACCESS' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let profile" class="justify-center text-left">
          <mat-checkbox disabled [checked]="profile.accessLevels[2].selected"> </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="limitations">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse justify-center whitespace-nowrap p-2 px-6 py-3 text-center leading-5"
        >
          <span>{{ 'USER_MANAGEMENT.VPN_PROFILES.IP_RANGES' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let profile" class="justify-center text-left">
          <button mat-icon-button (click)="showLimitations(profile)">
            <mat-icon color="grey-800">vpn_lock</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ACTIONS">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse justify-center whitespace-nowrap p-2 px-6 py-3 text-center leading-5"
        >
          <span>{{ 'COMMON.TABLE_HEADERS.ACTIONS' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let profile" class="justify-center whitespace-nowrap text-center">
          <button mat-icon-button [routerLink]="[profile.id]">
            <mat-icon
              [matTooltipShowDelay]="delayValues.SHORT_DELAY"
              [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
              matTooltip="{{ 'COMMON.ACTIONS.EDIT' | translate }}"
            >
              edit
            </mat-icon>
          </button>
          <button mat-icon-button (click)="deleteProfile(profile)">
            <mat-icon
              class="text-red-700"
              [matTooltip]="'COMMON.ACTIONS.DELETE' | translate"
              [matTooltipShowDelay]="delayValues.SHORT_DELAY"
              [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
              >delete</mat-icon
            >
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <mat-paginator [pageSize]="25" [pageSizeOptions]="pageSizeOptions"> </mat-paginator>
</div>
