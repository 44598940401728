import { Injectable, inject } from '@angular/core';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PaginatedResult } from '@tmc/fleet-core-api';
import { Group } from './group.model';
import { FleetPermissionService } from '../../fleet-permissions-management-api/fleet-permission/fleet-permission.service';
import { GroupStandardPermissionSync } from '../../fleet-permissions-management-api/fleet-permission/group/group-standard-permission.model';
import { GroupPermission } from '../../fleet-permissions-management-api/fleet-permission/group/group-fleet-permission.model';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  private readonly http = inject(HttpClient);
  private readonly fleetPermissionService = inject(FleetPermissionService);

  transform = (group: Group): Group => ({
    ...group,
    permissions: this.fleetPermissionService.transformToGroupStandardFleetPermissions(
      group.permissions as GroupStandardPermissionSync[],
    ),
    groupPermissions: this.fleetPermissionService.transformToGroupFleetPermissions(
      group.groupPermissions as GroupPermission[],
      group.permissions as GroupStandardPermissionSync[],
    ),
  });

  getAll(): Promise<Group[]> {
    return lastValueFrom(
      this.http
        .get<PaginatedResult<Group>>(`/fleet-admin/groups?`)
        .pipe(
          map((list: PaginatedResult<Group>) =>
            list.items.map((group: Group) => this.transform(group)),
          ),
        ),
    );
  }

  getOne(id: number): Promise<Group> {
    return firstValueFrom(
      this.http
        .get<Group>(`/fleet-admin/groups/${id.toString()}?`)
        .pipe(map((group: Group) => this.transform(group))),
    );
  }

  create(name: string, isRemoteFeatureGroup: boolean): Promise<Group> {
    return firstValueFrom(
      this.http
        .post<Group>(`/fleet-admin/groups?`, {
          name,
          is_remote_feature_group: isRemoteFeatureGroup,
        })
        .pipe(map((group: Group) => this.transform(group))),
    );
  }

  delete(id: number): Promise<void> {
    return firstValueFrom(
      this.http.delete(`/fleet-admin/groups/${id.toString()}?`).pipe(map(() => undefined)),
    );
  }
}
