export class SignalKeys {
  static readonly MDC_CONNECTED = 'mdc_connected__bool';
  static readonly LAST_DATA_UPLOAD_TIME_UTC = 'lastDataUploadTimeUTC';
  static readonly LAST_DATA_UPDATE_TIME = 'lastDataUpdateTime';
  static readonly LAST_DATA_UPDATE_TIME_UTC = 'lastDataUpdateTimeUTC';
  static readonly MACHINE_PARKING_ON = 'x_machineparking_on__bool';
  static readonly TOWING_DRIVE_READY = 'x_towingdrive_ready__bool';
  static readonly MACHINE_PARKING_PANTOGRAPH = 'x_machineparking_panto__bool';
  static readonly MACHINE_PARKING_WORKSHOP = 'x_machineparking_ceesocket__bool';
  static readonly MACHINE_PARKING_BATTERY = 'x_machineparking_tractionbatt__bool';
  static readonly MDC_BATTERY_VOLTAGE = 'mdc_battery_voltage__real__ky';
  static readonly MDC_TEMPERATURE = 'mdc_temperature__real__mnswy43jovzq';
  static readonly GSM_NETWORKTYPE = 'gsm_networktype__int';
  static readonly GSM_SIGNALSTRENGTH = 'gsm_signalstrength__int';
}
