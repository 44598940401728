import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormlyModule } from '@ngx-formly/core';
import {
  FormlyAutocompleteTypeComponent,
  FormlyMultiselectionTypeComponent,
  UtcTimeSelectorComponent,
} from '@tmc/fleet-core-ui';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FleetMachineManagementLandingComponent } from './fleet-machine-management-landing/fleet-machine-management-landing.component';
import { FleetMachineManagementListComponent } from './fleet-machine-management-list/fleet-machine-management-list.component';
import { MachineFormComponent } from './fleet-machine-management-form/machine-form.component';

const fleetMachineManagementRoutes: Routes = [
  {
    path: '',
    component: FleetMachineManagementLandingComponent,
    data: { title: 'Machine Landing ', subtitle: 'Machine Landing ' },
  },
  {
    path: 'machines',
    component: FleetMachineManagementListComponent,
    data: { title: 'Machine Listing Title', subtitle: 'Machine Listing Subtitle' },
  },
  {
    path: 'machines/new',
    component: MachineFormComponent,
  },
  {
    path: 'machines/:externalId',
    component: MachineFormComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(fleetMachineManagementRoutes),
    FormlyModule.forRoot({
      types: [
        {
          name: 'multiselection',
          component: FormlyMultiselectionTypeComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'autocomplete',
          component: FormlyAutocompleteTypeComponent,
          wrappers: ['form-field'],
        },
      ],
      wrappers: [{ name: 'tmc-formly-utc-time-selector', component: UtcTimeSelectorComponent }],
    }),
    FormlyMaterialModule,
  ],
  exports: [RouterModule],
  providers: [],
})
export class FleetMachineManagementModule {}
