<div class="max-h-table flex flex-col">
  <div class="relative my-5 grow overflow-y-auto">
    <mat-table matSort multiTemplateDataRows aria-label="List of users" [dataSource]="dataSource">
      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse p-2 px-6 py-3 text-left leading-5"
          mat-sort-header="email"
          >{{ 'COMMON.EMAIL' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let user" class="truncate p-2 px-6 text-left"
          ><span tmcTruncateTooltip>{{ user.email }}</span></mat-cell
        >
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse p-2 px-6 py-3 text-left leading-5"
          mat-sort-header="status"
          >{{ 'USER_MANAGEMENT.STATUS.STATUS' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let user" class="p-2 px-6 text-left"
          ><span tmcTruncateTooltip>{{ user.status }}</span></mat-cell
        >
      </ng-container>

      <!-- Groups Column -->
      <ng-container matColumnDef="groups">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse p-2 px-6 py-3 text-left leading-5"
          fxLayoutAlign="start center"
        >
          <span>{{ 'BREADCRUMB.GROUPS' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let user" class="truncate p-2 px-6 text-left">
          <mat-chip-listbox class="w-full space-x-2">
            @if (user.groups.length > 2) {
              <mat-chip
                [removable]="true"
                (removed)="openRemoveGroupFromUser(user, user.groups[0])"
              >
                <span tmcTruncateTooltip> {{ user.groups[0].name }} </span>
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <mat-chip
                class="relative grid items-center truncate"
                [matTooltip]="'COMMON.DIALOGS.OPEN_DETAIL' | translate"
                (click)="openAllGroupsDialog(user)"
              >
                <span> ... </span>
              </mat-chip>
            } @else {
              @for (userGroup of user.groups; track userGroup) {
                <mat-chip [removable]="true" (removed)="openRemoveGroupFromUser(user, userGroup)">
                  <span> {{ userGroup.name }} </span>
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              } @empty {
                <span>-</span>
              }
            }
          </mat-chip-listbox>
        </mat-cell>
      </ng-container>

      <!-- Generated Time Column -->
      <ng-container matColumnDef="generatedTime">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse p-2 px-6 py-3 text-left leading-5"
          mat-sort-header="generatedTime"
          >{{ 'USER_MANAGEMENT.CREATION_DATE' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let user" class="p-2 px-6 text-left"
          ><span tmcTruncateTooltip>{{
            (user.generatedTime | date: 'short') || ''
          }}</span></mat-cell
        >
      </ng-container>

      <!-- Modified Time Column -->
      <ng-container matColumnDef="modifiedTime">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse p-2 px-6 py-3 text-left leading-5"
          mat-sort-header="modifiedTime"
          >{{ 'USER_MANAGEMENT.LAST_UPDATE' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let user" class="p-2 px-6 text-left"
          ><span tmcTruncateTooltip>{{ (user.modifiedTime | date: 'short') || '' }}</span></mat-cell
        >
      </ng-container>

      <!-- Last Logged Time Column -->
      <ng-container matColumnDef="lastLoggedTime">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse p-2 px-6 py-3 text-left leading-5"
          mat-sort-header="lastLoggedTime"
          >{{ 'USER_MANAGEMENT.LAST_LOGIN' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let user" class="p-2 px-6 text-left"
          ><span tmcTruncateTooltip>{{
            (user.lastLoggedTime | date: 'short') || ''
          }}</span></mat-cell
        >
      </ng-container>

      <!-- Registry Admin Column -->
      <ng-container matColumnDef="registryAdmin">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse justify-center whitespace-nowrap p-2 px-6 py-3 text-center leading-5"
          mat-sort-header="registryAdmin"
          >{{ 'USER_MANAGEMENT.REGISTRY_ADMIN' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let user" class="justify-center"
          ><mat-checkbox
            color="primary"
            [checked]="isAdmin(user)"
            (click)="updateUserAdmin(user)"
          ></mat-checkbox
        ></mat-cell>
      </ng-container>

      <!-- Company Name Column -->
      <ng-container matColumnDef="companyName">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse p-2 px-6 py-3 text-left leading-5"
          mat-sort-header="companyName"
          >{{ 'USER_MANAGEMENT.COMPANY' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let user" class="p-2 px-6 text-left"
          ><span tmcTruncateTooltip>{{ user.company.name }}</span></mat-cell
        >
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="ACTIONS">
        <mat-header-cell
          *matHeaderCellDef
          class="border-collapse justify-center whitespace-nowrap p-2 px-6 py-3 text-center leading-5"
          >{{ 'COMMON.TABLE_HEADERS.ACTIONS' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let user" class="justify-center whitespace-nowrap text-center">
          <button class="pr-2" mat-icon-button (click)="addGroupToUser(user)">
            <mat-icon
              [matTooltip]="'USER_MANAGEMENT.ADD_GROUP_TO_USER' | translate"
              [matTooltipShowDelay]="delayValues.SHORT_DELAY"
              [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
              >group_add</mat-icon
            >
          </button>

          <button
            class="pr-2"
            mat-icon-button
            [routerLink]="['./' + user.email]"
            id="perm_{{ user.email }}"
          >
            <mat-icon
              [matTooltip]="'COMMON.ACTIONS.PERMISSIONS' | translate"
              [matTooltipShowDelay]="delayValues.SHORT_DELAY"
              [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
              >manage_accounts</mat-icon
            >
          </button>

          <button class="pr-2" mat-icon-button (click)="deleteUser(user)">
            <mat-icon
              class="text-red-700"
              [matTooltip]="'COMMON.ACTIONS.DELETE' | translate"
              [matTooltipShowDelay]="delayValues.SHORT_DELAY"
              [matTooltipHideDelay]="delayValues.FASTEST_DELAY"
              >delete</mat-icon
            >
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let user; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <mat-paginator [pageSize]="25" [pageSizeOptions]="pageSizeOptions"> </mat-paginator>
</div>
