export function toCamelCase(str: string): string {
  return str.replace(/_([a-zA-Z])/g, ([, l]) => l.toUpperCase());
}

// Deactivating any restricting rules as JSON.parse has only any types
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export function parseJsonCamelCaseKeys(json: string): string {
  // Lambda function not possible because this binding needs to be used
  // eslint-disable-next-line func-names
  return JSON.parse(json, function (key, value) {
    const newKey = toCamelCase(key);
    if (key === '' || newKey === key) return value;
    this[toCamelCase(key)] = value;
    return undefined;
  });
}
