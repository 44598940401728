import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from '@tmc/mco-user-management-api';
import { LandingPageComponent } from '@tmc/fleet-core-ui';
import { Tile } from '@tmc/fleet-core-api';
import { AdminAppUserDetailsService } from '@tmc/fleet-core-feature';
import { toSignal } from '@angular/core/rxjs-interop';
import { hasValue } from '@tmc/core-utils';
import { filter, map } from 'rxjs';

@Component({
  selector: 'tmc-fleet-user-management-landing',
  standalone: true,
  imports: [CommonModule, LandingPageComponent],
  providers: [UserService],
  templateUrl: './fleet-user-management-landing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetUserManagementLandingComponent {
  private readonly adminAppUserDetail = inject(AdminAppUserDetailsService);
  private readonly usersTile: Tile = {
    heading: 'USER_MANAGEMENT.LANDING.TILE.HEADING.USERS',
    description: 'USER_MANAGEMENT.LANDING.TILE.DESCRIPTION',
    actionText: 'USER_MANAGEMENT.LANDING.TILE.ACTION_TEXT.CREATE_EDIT_USER',
    alternativeActionText: 'USER_MANAGEMENT.LANDING.TILE.ACTION_TEXT.SEE_USERS',
    route: 'users',
    logo: 'manage_accounts',
    views: [
      { name: 'vendor', canCreate: true },
      { name: 'manufacturer', canCreate: true },
      { name: 'distributor', canCreate: false },
      { name: 'customer', canCreate: false },
    ],
  };
  private readonly groupsTile: Tile = {
    heading: 'USER_MANAGEMENT.LANDING.TILE.HEADING.GROUPS',
    description: 'USER_MANAGEMENT.LANDING.TILE.DESCRIPTION',
    actionText: 'USER_MANAGEMENT.LANDING.TILE.ACTION_TEXT.CREATE_EDIT_GROUPS',
    alternativeActionText: 'USER_MANAGEMENT.LANDING.TILE.ACTION_TEXT.SEE_GROUPS',
    route: 'groups',
    logo: 'people_alt',
    views: [
      { name: 'vendor', canCreate: true },
      { name: 'manufacturer', canCreate: true },
      { name: 'distributor', canCreate: false },
      { name: 'customer', canCreate: false },
    ],
  };
  private readonly remoteFeaturesGroupsTile: Tile = {
    heading: 'USER_MANAGEMENT.LANDING.TILE.HEADING.REMOTE_FEATURES_GROUPS',
    description: 'USER_MANAGEMENT.LANDING.TILE.DESCRIPTION',
    actionText: 'USER_MANAGEMENT.LANDING.TILE.ACTION_TEXT.CREATE_EDIT_REMOTE_FEATURES_GROUPS',
    alternativeActionText: 'USER_MANAGEMENT.LANDING.TILE.ACTION_TEXT.SEE_GROUPS',
    route: 'remote-features-groups',
    logo: 'contact_page',
    views: [
      { name: 'vendor', canCreate: true },
      { name: 'manufacturer', canCreate: true },
      { name: 'distributor', canCreate: false },
      { name: 'customer', canCreate: false },
    ],
  };
  private readonly vpnProfilesTile: Tile = {
    heading: 'USER_MANAGEMENT.LANDING.TILE.HEADING.VPN_PROFILES',
    description: 'USER_MANAGEMENT.LANDING.TILE.DESCRIPTION',
    actionText: 'USER_MANAGEMENT.LANDING.TILE.ACTION_TEXT.CREATE_EDIT_VPN_PROFILES',
    alternativeActionText: 'USER_MANAGEMENT.LANDING.TILE.ACTION_TEXT.SEE_VPN_PROFILES',
    route: 'vpn-profiles',
    logo: 'private_connectivity',
    views: [
      { name: 'vendor', canCreate: true },
      { name: 'manufacturer', canCreate: false },
    ],
  };
  tilesData: Tile[] = [
    this.usersTile,
    this.groupsTile,
    this.remoteFeaturesGroupsTile,
    this.vpnProfilesTile,
  ];
  view = toSignal(
    this.adminAppUserDetail.details$.pipe(
      filter(hasValue),
      map((details) => AdminAppUserDetailsService.toView(details.company)),
    ),
  );
}
