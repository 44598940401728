import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';

import { Observable, first, switchMap } from 'rxjs';
import { UserCompany } from '@tmc/fleet-core-api';
import { AdminAppUserDetailsService } from './admin-app-user-details.service';

const URLS = '/fleet-admin/';
const COMPANY_REGEX = /\/fleet-admin\/users\/(.*)\/company/;

export function adminAppHttpInterceptor(): HttpInterceptorFn {
  return (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const adminAppUserDetail = inject(AdminAppUserDetailsService);

    if (!request.url.startsWith(URLS) || COMPANY_REGEX.test(request.url)) return next(request);
    return adminAppUserDetail.details$.pipe(
      first(),
      switchMap((details) =>
        details === null
          ? next(request)
          : next(request.clone({ params: toParams(details.company) })),
      ),
    );
  };
}

function toParams(details: UserCompany) {
  return new HttpParams({
    fromObject: {
      acting_company: details.externalId,
      view: AdminAppUserDetailsService.toView(details),
      _page_size: '0',
    },
  });
}
