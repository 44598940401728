import { Component, ChangeDetectionStrategy, signal, output, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FilterOption } from '@tmc/fleet-ui';
import { MachineStatus } from '@tmc/fleet-machine-management-api';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmc-fleet-machine-sidebar',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule, MatCheckboxModule],
  templateUrl: './fleet-machine-sidebar.component.html',
})
export class FleetMachineSidebarComponent {
  categoryChanged = output<{ filterCategory: string; categoryChecked: boolean }>();
  allModules = input<string[]>();

  filterOptions = [
    {
      key: 'modules',
      filter: [],
    },
    {
      key: 'status',
      filter: MachineStatus.machineStatuses.map((status) => status.value),
    },
  ];

  showFilter = signal<boolean>(false);
  filterChanged = output<FilterOption>();
  onInitChecked = true;

  toggleFilterOptions() {
    this.showFilter.update((val) => !val);
  }

  onFilterChange(filterCategory: string, filter: string, filterChecked: boolean) {
    // options
    this.filterChanged.emit({ filterCategory, filter, filterChecked });
  }

  onCategoryChecked(filterCategory: string, categoryChecked: boolean) {
    // modules, status
    this.filterOptions[0].filter = this.allModules() ?? [];
    this.categoryChanged.emit({ filterCategory, categoryChecked });
  }

  formatTranslationText(text: string, option: string) {
    if (!text) return '';
    // Convert to uppercase and replace spaces with underscores
    return `MACHINE_MANAGEMENT.MACHINE_${option.toUpperCase()}.${text
      .toUpperCase()
      .replace(/\s+/g, '_')}`;
  }
}
